import { createReducer, on } from '@ngrx/store';
import * as StandardDefActionTypes from '../actions/standard-definition.action';
import { StandardDefinition } from '../../domain/standard-definition.model';
import { StandardDefinitionOption } from '../../domain/standard-definition-option.model';

export interface StandardDefinitionState {
  standardDefinition: StandardDefinition[];
  standardDefOptions: StandardDefinitionOption[];
}

export const initialStandardDefinitionState: StandardDefinitionState = {
  standardDefinition: null,
  standardDefOptions: null,
};

export const standardDefinitionReducer = createReducer(
  initialStandardDefinitionState,
  on(
    StandardDefActionTypes.fetchStandardDefOptSuccess,
    (state: StandardDefinitionState, { standardDefOptions }): StandardDefinitionState => ({
      ...state,
      standardDefOptions: standardDefOptions,
    }),
  ),
  on(
    StandardDefActionTypes.fetchStandardDefinitionSuccess,
    (state: StandardDefinitionState, { standardDefinition }): StandardDefinitionState => ({
      ...state,
      standardDefinition: standardDefinition,
    }),
  ),
);

export const getStandardDefinitions = (state: StandardDefinitionState): StandardDefinition[] =>
  state.standardDefinition;
