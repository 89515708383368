import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AbstractVersionedEntity } from '../../../../../../core/domain/abstract-versioned-entity.model';

@Component({
  selector: 'ncs-table-crud-report-button',
  templateUrl: './ncs-table-crud-report-button.component.html',
})
export class NcsTableCrudReportButtonComponent {
  @Input() dataItem: AbstractVersionedEntity;
  @Input() icon: string = 'pi pi-file';
  @Input() title: string = 'common.labels.report';
  @Output() onClick: EventEmitter<number> = new EventEmitter<number>();
}
