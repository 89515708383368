import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../core/utils/app.constants';
import { TimeService } from '../../core/ngrx/services/time-service';

@Pipe({
  name: 'date',
})
export class DatePipeProxy extends DatePipe implements PipeTransform {
  constructor(
    _translateService: TranslateService,
    private readonly timeService: TimeService,
  ) {
    super(_translateService.currentLang); // default locale
  }

  public transform(value: any, pattern: string = Constants.getDateFormatShort()): any {
    // CR-1215: use only the standard format dd.mm.yyyy
    const appTimezone = this.timeService.toLocaleString();
    return super.transform(value, pattern, appTimezone || 'UTC');
  }
}
