import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  authNotificationEmitter,
  dddGuestEvaluator,
  dddGuestEvaluators,
  errorResponse,
  fetchCuppingSessionByYearMonth,
  fetchCuppingSessions,
  fetchCuppingSessionStatus,
  fetchCuppingSessionStatusSuccess,
  fetchCuppingSessionSuccess,
  getCuppingSessionByIdSession,
  guestEvaluatorsCreatedSuccessful,
  saveGuestEvaluatorsQueue,
  saveMasterData,
  selectCuppingSession,
  sendCupInvitation,
  setAppErrorMessages,
  setResponsePending,
  storeInvitationEmailConfirmation,
} from '../actions';
import { QueryService } from '../services/query.service';
import { CuppingSessionService } from '../services/cupping-sesion.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { ToastSeverityEnum } from '../utils/notification-utils';
import { CUPPING_SESSION_DEFAULT_INCLUDE } from '../../../modules/master/modules/cupping-session/modules/cupping-session/components/cupping-session.component';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { CuppingSessionStatus } from '../../domain/cupping-session-status.model';
import { CuppingSession } from '../../domain/cupping-session.model';

@Injectable()
export class CuppingSessionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly queryService: QueryService,
    private readonly cuppingSessionService: CuppingSessionService,
  ) {}

  public readonly fetchCuppingSession$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCuppingSessions),
      tap(() => setResponsePending({ isResponsePending: true })),
      switchMap(() => this.queryService.querySearch<CuppingSession[]>(CuppingSession)),
      switchMap((cuppingSessions: CuppingSession[]) => [
        fetchCuppingSessionSuccess({ cuppingSessions: cuppingSessions }),
        setResponsePending({ isResponsePending: false }),
      ]),
    ),
  );

  public readonly fetchCuppingSessionYearMonth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCuppingSessionByYearMonth),
      switchMap(action => this.cuppingSessionService.loadCuppingSessions(action.year, action.month)),
      switchMap(cuppingSessions => [
        fetchCuppingSessionSuccess({ cuppingSessions }),
        setResponsePending({ isResponsePending: false }),
      ]),
    ),
  );

  public readonly fetchCuppingSessionById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getCuppingSessionByIdSession),
      switchMap(action => this.cuppingSessionService.getCuppingSessionById(action.cuppingSessionId, action._include)),
      switchMap(cuppingSession => {
        return [
          selectCuppingSession({ cuppingSession: cuppingSession }),
          setResponsePending({ isResponsePending: false }),
        ];
      }),
    ),
  );

  public readonly cuppingSessionStatusType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCuppingSessionStatus),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'session,ASC';
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(() => this.queryService.querySearch<CuppingSessionStatus[]>(CuppingSessionStatus)),
      map(cuppingSessionStatus => fetchCuppingSessionStatusSuccess({ cuppingSessionStatus: cuppingSessionStatus })),
    ),
  );

  public readonly cuppingSessionInvitation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sendCupInvitation),
      map(payload => payload.cuppingInvitationId),
      switchMap(id => this.cuppingSessionService.sendCuppingSessionInvitation(id)),
      switchMap(resp => [
        storeInvitationEmailConfirmation({ confirmation: resp }),
        setResponsePending({ isResponsePending: false }),
      ]),
    ),
  );

  public readonly cuppingSessionInvitationSent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(storeInvitationEmailConfirmation),
      map(() =>
        authNotificationEmitter({
          summary: 'masterdata.label.successfulAlert',
          severity: ToastSeverityEnum.SUCCESS,
        }),
      ),
    ),
  );

  public readonly addGuestEvaluators$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(dddGuestEvaluators),
      switchMap(paylaod =>
        this.cuppingSessionService
          .addGuestEvaluatorsTo(paylaod.cuppingSessionId, paylaod.evaluators)
          .then(resp => guestEvaluatorsCreatedSuccessful({ evaluators: resp })),
      ),
    ),
  );

  public readonly addGuestCupEvaluator$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(dddGuestEvaluator),
      switchMap(payload =>
        this.cuppingSessionService
          .addGuestEvaluatorTo(payload.cuppingSessionId, payload.evaluator)
          .then(resp => guestEvaluatorsCreatedSuccessful({ evaluators: [resp] })),
      ),
    ),
  );

  public readonly saveGuestEvaluators$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveGuestEvaluatorsQueue),
        switchMap(payload => {
          return this.cuppingSessionService
            .addGuestEvaluatorsTo(payload.cuppingSessionId, payload.evaluators)
            .then(() => {
              return this.cuppingSessionService
                .getCuppingSessionById(payload.cuppingSessionId, CUPPING_SESSION_DEFAULT_INCLUDE)
                .then(cs => {
                  return saveMasterData({
                    typeFunction: payload.metadata.typeFunction,
                    data: cs,
                    redirectUrl: payload.metadata.redirectUrl,
                    paginationRequestParams: payload.metadata.paginationRequestParams,
                    isoCode: payload.metadata.isoCode,
                  });
                })
                .catch(() => setAppErrorMessages({ errorMessages: ['notification.error_occurred'] }));
            })
            .catch(err => errorResponse({ _httpErrorResponse: err }));
        }),
      ),
    { dispatch: true },
  );
}
