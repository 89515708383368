import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from './app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { AuthorizationService } from './authorization.service';
import { TimeService } from './time-service';
import { QueryService } from './query.service';
import { GridView } from '../../domain/grid-view.model';

@Injectable()
export class ViewCustomizationOverlayService extends AbstractCeisService {
  private readonly actionUrl: string;
  constructor(
    http: HttpClient,
    configuration: Constants,
    private readonly authService: AuthorizationService,
    timeService: TimeService,
    private readonly queryService: QueryService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = `${configuration.SERVER_WITH_AUTH_API_URL}user/gridviews/`;
  }

  public async loadGridView(name: string, customGridView?: GridView): Promise<any> {
    const res = await this.getWithParams(this.actionUrl + name, { _include: '*' }, true, () =>
      this.authService.renewToken(),
    );
    return {
      gridViews: this.queryService.fromServer(res),
      initialGrid: customGridView,
      reference: name,
    };
  }
  public async saveGridView(
    gridView: GridView,
    gridViewReference: string,
    forceRefresh: boolean = false,
  ): Promise<GriViewResponse> {
    gridView = this.queryService.toServer(gridView);
    const url: string = this.actionUrl;
    const tokenRenew = (): Promise<boolean> => this.authService.renewToken();
    const resp = await (gridView.id > 0
      ? this.putWithParams(url, {}, gridView, true, tokenRenew)
      : this.postWithParams(url, {}, gridView, true));
    return {
      res: this.queryService.fromServer(resp),
      viewReference: gridViewReference,
      refresh: forceRefresh,
    };
  }
}

export interface GriViewResponse {
  res: any;
  viewReference: string;
  refresh: boolean;
}
