import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { SelectItem } from 'primeng/api';
import { NkgGenericGridComponent } from '../../grid/generic-grid/components/nkg-generic-grid.component';
import { NkgGridFactory } from '../../grid/services/grid.factory';
import { isNullOrUndefined, nullsafe } from '../../../modules/utils/object-utils';
import { PaginationMetadata } from '../../../core/domain/pagination-metadata.model';
import { LookupParams } from '../../models/lookup-params.model';
import { ColDefMap } from '../../models/col-def-map.model';
import { ColumnsType } from '../../models/columns-type.model';
import { GridSelectionMode } from '../../models/grid-selection-mode.model';

@Component({
  selector: 'ncs-magnifier',
  templateUrl: './magnifier-basic.component.html',
})
export class NcsMagnifierComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedRows: any[];
  @Input() optionsData: SelectItem[]; // get value property for to set rowData
  /** Grid Tittle when display dialog */
  @Input() titleGrid: string = '';
  @Input() selectionMode: string = GridSelectionMode.SINGLE;
  @Input() gridColumnType: any = ColumnsType.COL_DEF;
  /** colDef properties for build ColDef[] data for Grid */
  @Input() colDefArray: string[];
  /** colDef properties for build ColDef[] data for Grid */
  @Input() colDefMap: ColDefMap[];
  /** Active grid dialog */
  @Input() visibleGrid: boolean = false;
  /** Enabled auto first fetch */
  @Input() preload: boolean = false;
  /** Activate filter on grid */
  @Input() filterEnable: boolean = false; // active filter or not
  @Input() paginationResponse: PaginationMetadata; // paginationRequestParams data type for paginationRequestParams into Grid
  @Input() appendTo: any = 'body';

  @Output() onchange: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();
  @Output() onSetQuerySearch: EventEmitter<LookupParams> = new EventEmitter<LookupParams>(); // retrieve filter and paginationRequestParams values
  @Output() onChangePage: EventEmitter<number> = new EventEmitter(); // emit paginationRequestParams valus changes

  @ViewChild('genericGrid') nkgGenericGrid: NkgGenericGridComponent;

  gridColumnDefinition: ColDef[];

  displayGrid: boolean = false;
  currentSelections: any[] = [];
  gridOptions: GridOptions;
  gridRowData: any[];

  constructor(private readonly gf: NkgGridFactory) {
    this.gridOptions = this.gf.getDefaultGridOptions(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionsData) {
      this.gridRowData = [...nullsafe(this.optionsData).map(row => row?.value || row)];
    }
  }

  ngOnDestroy(): void {
    this.gridRowData = [];
    this.selectedRows = undefined;
  }

  ngOnInit(): void {
    if (nullsafe(this.colDefArray).length > 0) {
      this.gridColumnDefinition = this.getMultiselectColDefs(this.colDefArray);
      setTimeout(() => this.forceSelectionsLookup(true));
      if (this.preload) this.onSetQuerySearch.emit({ query: '' });
    } else if ((this.colDefMap || []).length) {
      // Maybe can remove and set colDef as type [ColDef] from AgGrid
      this.gridColumnDefinition = this.onGetColDefs(this.colDefMap);
      setTimeout(() => this.forceSelectionsLookup(true));
      if (this.preload) this.onSetQuerySearch.emit({ query: '' });
    }
  }

  /** Build column definition
   * @param colDef array properties
   * @return ColDef
   */
  private getMultiselectColDefs(colDef: string[]): ColDef[] {
    const gridColDefs: ColDef[] = [];
    const cellClassRules = {
      allBorders: function (): boolean {
        return true;
      },
    };
    colDef.forEach(col => {
      const dataToCamelCase: string = col.replace(/_\w/g, m => m[1].toUpperCase());
      const camelToSnakeCase = dataToCamelCase
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();
      if (camelToSnakeCase == 'id') {
        gridColDefs.push(
          this.gf
            .getColDefBuilder()
            .getTextColDef(`common.labels.${camelToSnakeCase}`, `data.${dataToCamelCase}`)
            .colId(dataToCamelCase)
            .addAdditionalParams({
              cellClassRules: cellClassRules,
              cellClass: ['allBorders'],
              lockPosition: true,
              pinned: true,
              minWidth: 80,
            })
            .build(),
        );
      } else if (camelToSnakeCase == 'date') {
        gridColDefs.push(
          this.gf
            .getColDefBuilder()
            .getDateColDef(`common.labels.${camelToSnakeCase}`, `data.${dataToCamelCase}`)
            .colId(dataToCamelCase)
            .addAdditionalParams({
              cellClassRules: cellClassRules,
              cellClass: ['allBorders'],
              lockPosition: true,
              pinned: true,
              minWidth: 100,
            })
            .build(),
        );
      } else {
        gridColDefs.push(
          this.gf
            .getColDefBuilder()
            .getTextColDef(`common.labels.${camelToSnakeCase}`, `data.${dataToCamelCase}`)
            .colId(dataToCamelCase)
            .addAdditionalParams({
              cellClassRules: cellClassRules,
              cellClass: ['allBorders'],
              lockPosition: true,
              minWidth: 200,
            })
            .build(),
        );
      }
    });
    return gridColDefs;
  }

  /**
   * setting currentSelections when select any grid item
   * @param event
   */
  public getSelectedRowsGridEvent(event: any): void {
    if (!isNullOrUndefined(event)) {
      this.currentSelections = event;
    }
  }

  /**
   * retrieve data from grid modal with 'ok' btn
   */
  public selectedRowsOk(): void {
    this.displayGrid = !this.displayGrid;
    if (nullsafe(this.currentSelections).length > 0) {
      this.selectedRows =
        this.selectionMode === GridSelectionMode.MULTIPLE ? this.currentSelections : this.currentSelections[0];
      this.onchange.emit(this.selectedRows);
    }
    this.deactivate.emit();
  }

  // Modify current grid options for selections row
  // CAUTION: the JS Object comparison it's never equal. So, comparison convert it to JSON format for success results
  public forceSelectionsLookup(visible: boolean): void {
    this.selectedRows = this.selectedRows || []; // force null selection on the grid
    if (nullsafe(this.selectedRows).length > 0 && !isNullOrUndefined(this.nkgGenericGrid)) {
      if (this.selectionMode === GridSelectionMode.MULTIPLE) {
        if (!isNullOrUndefined(this.nkgGenericGrid?.gridOptions?.api)) {
          this.gridOptions.api.forEachNode(node => {
            node.setSelected(
              this.selectedRows.some(row => {
                return row === node.data;
              }),
            );
          });
        }
      } else {
        const selectedRow = this.selectedRows;
        this.nkgGenericGrid.gridOptions.api.forEachNode(node => {
          node.setSelected(node.data === selectedRow);
        });
      }
    }
    this.visibleGrid = visible;
    if (!visible) this.deactivate.emit();
  }

  /**
   * emit input values for new searches
   * @param event
   */
  public onFilterSearch(event: string): void {
    // assume the user needs new search
    this.currentSelections = [];
    this.onSetQuerySearch.emit({ query: event });
  }

  private onGetColDefs(colDefMap: ColDefMap[]): ColDef[] {
    return colDefMap.map(col => {
      const value = <string>col.value;
      if (col.label == '#') {
        return this.gf
          .getColDefBuilder()
          .getTextColDef(col.label, col.value)
          .colId(col.colId || value)
          .addAdditionalParams({
            cellClass: ['allBorders'],
            lockPosition: true,
            pinned: true,
            minWidth: 80,
            tooltipValueGetter: params => params.value,
          })
          .build();
      }
      if (col.label == 'common.labels.date') {
        return this.gf
          .getColDefBuilder()
          .getDateColDef(col.label, col.value)
          .colId(col.colId || value)
          .addAdditionalParams({
            cellClass: ['allBorders'],
            lockPosition: true,
            pinned: true,
            minWidth: 100,
            tooltipValueGetter: params => params.value,
          })
          .build();
      }
      return this.gf
        .getColDefBuilder()
        .getTextColDef(col.label, col.value)
        .colId(col.colId || value)
        .addAdditionalParams({
          sort: col.sort || '',
          hide: col.hide,
          cellClass: ['allBorders'],
          lockPosition: true,
          minWidth: 250,
          tooltipValueGetter: params => params.value,
        })
        .build();
    });
  }
}
