import { createAction, props } from '@ngrx/store';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { ApprovalStatus } from '../../domain/approval-status.model';
import { SampleDispatchStatus } from '../../domain/sample-dispatch-status.model';

export enum DispatchAction {
  FETCH_APPROVAL_STATUS = '[Approval Status] Fetch Approval Status',
  FETCH_APPROVAL_STATUS_SUCCESS = '[Approval Status] Fetch Approval Status Success',
  FETCH_DISPATCH_STATUS = '[Dispatch Status] Fetch Dispatch Status',
  FETCH_DISPATCH_STATUS_SUCCESS = '[Dispatch Status] Fetch Dispatch Status Success',
}

export const fetchApprovalStatus = createAction(DispatchAction.FETCH_APPROVAL_STATUS);

export const fetchApprovalStatusSuccess = createAction(
  DispatchAction.FETCH_APPROVAL_STATUS_SUCCESS,
  props<{ approvalStatuses: ApprovalStatus[] }>(),
);

export const fetchDispatchStatus = createAction(
  DispatchAction.FETCH_DISPATCH_STATUS,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchDispatchStatusSuccess = createAction(
  DispatchAction.FETCH_DISPATCH_STATUS_SUCCESS,
  props<{ sampleDispatchStatuses: SampleDispatchStatus[] }>(),
);
