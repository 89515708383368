import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { lastValueFrom } from 'rxjs';
import { TimeService } from './time-service';
import { msalClientConfig } from '../../../modules/authorization/login/msal/environment';
import { END_SESSION_URL } from '../../../modules/authorization/login/msal/msal-environment';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { AbstractCeisService } from './app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { State } from '../reducers';
import { logout } from '../actions';
import { ToastSeverityEnum } from '../utils/notification-utils';
import { AuthCredentials } from '../../domain/auth-credentials.model';

@Injectable()
export class AuthorizationService extends AbstractCeisService {
  public loginUrl: string;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    http: HttpClient,
    configuration: Constants,
    timeService: TimeService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    private readonly store: Store<State>,
    private readonly msalService: MsalService,
  ) {
    super(http, configuration, timeService);
    this.loginUrl = `${configuration.SERVER_WITH_AUTH_API_URL}login`;
  }

  public async authorize(credentials: AuthCredentials): Promise<string> {
    const response = await this.post(this.loginUrl, credentials, false);
    const { token } = response;
    this.webStorage.setItem(AbstractCeisService.STORAGE_KEY_USERNAME, credentials.username);
    this.tokenRenewalSucceeded(token, response.refreshToken, response.expiresIn);
    return token;
  }

  public msalLoginRedirect(requestScopes?: string[]): void {
    requestScopes = requestScopes || msalClientConfig.apiConfig.scopes;
    this.webStorage.setItem(AuthorizationService.STORAGE_MSAL_ON_REDIRECT, 'Y');
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect({
        redirectUri: msalClientConfig.apiConfig.redirectUri,
        scopes: requestScopes,
      });
    }
  }

  /**
   * Request for a valid AccessToken for the user whom provided tokenId belongs to.
   * @param tokenId - tokenId retrieved from a popup or redirect authentication
   * @param scopes - custom scopes if required
   */
  public async requestMsAccessToken(tokenId: string, scopes?: string[]): Promise<any> {
    if (tokenId) {
      const requestScopes = scopes || msalClientConfig.apiConfig.scopes;
      const silent = await lastValueFrom(
        this.msalService.acquireTokenSilent({
          scopes: requestScopes,
          redirectUri: msalClientConfig.apiConfig.redirectUri,
        }),
      );
      this.webStorage.setItem(AuthorizationService.STORAGE_MSAL_TOKEN, silent.accessToken);
      return silent;
    }
    const accountInfo = this.msalService.instance.getActiveAccount();
    if (accountInfo?.idToken) {
      const { idToken } = accountInfo;
      if (!isNullOrUndefined(idToken)) {
        return this.requestMsAccessToken(idToken, scopes);
      }
    }
    throw Error("Could not retrieve silent token. There is no 'tokenId' provided.");
  }

  /**
   * Calls to MS url to end the session for the provided current logged account. The url to be called will be opened
   * in a new tab.
   * At the date, couldn't find a way to end the user session without an user interaction. Always has to be an
   * interaction with the user, even if there is no click or key pressed, just needs the tab to be opened. That's why
   * 'ms_logged_out=1' parameter is inserted into the 'post_logout_redirect_uri' to handle this parameter into the
   * login.component and auth-close the tab if comes from the Microsoft's logout page.
   */
  public msalLogout(): Promise<any> {
    const accessToken = window.localStorage.getItem(AbstractCeisService.STORAGE_MSAL_TOKEN);
    if (!isNullOrUndefined(accessToken)) {
      const postLogoutUri: string = `${msalClientConfig.apiConfig.redirectUri}?ms_logged_out=1`;
      const logoutUrl = `${END_SESSION_URL}?post_logout_redirect_uri=${encodeURIComponent(postLogoutUri)}`;
      window.open(logoutUrl, '_blank');
      this.msalService.logoutPopup();
    }
    return Promise.resolve(true);
  }

  public async refreshToken(refreshToken: string): Promise<string> {
    const response = await this.post(this.loginUrl, { refreshToken: refreshToken }, false);
    const { token } = response;
    this.webStorage.setItem(AbstractCeisService.STORAGE_KEY_USERNAME, this.getLoggedOnUser());
    this.tokenRenewalSucceeded(token, response.refreshToken, response.expiresIn);
    return token;
  }

  public getLoggedOnUser(): string {
    // no need 4 flux, it's on webstorage w/ different lifecycle
    return this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_USERNAME);
  }

  public loginLogoutChecker(): void {
    if (!this.isLoggedIn() && !isNullOrUndefined(this.getLoggedOnUser())) {
      this.store.dispatch(logout());
    }
  }

  public isSessionChecker(): void {
    if (this.isLoggedIn()) {
      const keySession = this.webStorage.getItem(AbstractCeisService.STORAGE_KEY_MESSAGE_SESSION) || '0';
      if (parseInt(keySession, 10) < this.timeService.obtainNow().getTime()) {
        this.messageService.clear();
        this.messageService.add({
          key: 'session_to_expire',
          sticky: true,
          severity: ToastSeverityEnum.WARNING,
          summary: this.translateService.instant('notification.session_expired'),
          detail: this.translateService.instant('notification.confirm_to_proceed'),
        });
      }
    }
  }
}
