import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { MetricsDefinitionType } from './metrics-definition-type.model';

@Entity('masterdata', 'ItemDefinition', 'qc')
export class ItemDefinition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized description: string;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
}
