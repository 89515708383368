import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from './app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { AuthorizationService } from './authorization.service';
import { TimeService } from './time-service';
import { nullsafe } from '../../../modules/utils/object-utils';
import { Permissions } from '../../domain/permissions.model';
import { Role } from '../../domain/role.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';

@Injectable()
export class RoleService extends AbstractCeisService {
  private readonly actionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private readonly authService: AuthorizationService,
    timeService: TimeService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = configuration.SERVER_WITH_AUTH_API_URL;
  }

  public async loadRoles(): Promise<Role[]> {
    const res: Role[] = await this.getWithParams(
      `${this.actionUrl}administration/roles`,
      { deleted: false },
      true,
      () => this.authService.renewToken(),
    );
    return nullsafe(res);
  }

  public async loadPermissions(): Promise<Permissions[]> {
    const paginationFinal = new PaginationRequestParams();
    paginationFinal.size = 200;
    const res = await this.getWithParams<PaginatedResponse>(`${this.actionUrl}permissions`, paginationFinal, true, () =>
      this.authService.renewToken(),
    );
    return nullsafe(res.items) as Permissions[];
  }
}
