import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../../core/ngrx/reducers';

import { Permission } from '../../../../core/domain/permission.model';

@Component({
  selector: 'ncs-form-edit',
  templateUrl: './form-edit.component.html',
})
export class NcsFormEditComponent implements OnDestroy {
  @Input() editMode: boolean = false;
  @Input() disabled: boolean = true;
  @Input() showToolbar: boolean = true;
  @Input() permissions: Permission[];

  @Output() ctrlS: EventEmitter<any> = new EventEmitter<any>();
  @Output() esc: EventEmitter<any> = new EventEmitter<any>();

  private readonly modifySub$: Subscription;

  constructor(private readonly store: Store<fromRoot.State>) {
    this.modifySub$ = this.store
      .select(fromRoot.getModifyMasterModeActive)
      .subscribe(modify => (this.disabled = !modify));
  }

  ngOnDestroy(): void {
    this.modifySub$.unsubscribe();
  }

  public onCtrlS(): void {
    this.ctrlS.emit();
  }

  public onEsc(): void {
    // WARNING: esc event closes entire page application
    this.esc.emit();
  }
}
