import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { NcsTextBasicComponent } from './text-basic/components/ncs-text-basic.component';
import { NcsTextSearchComponent } from './text-search/components/ncs-text-search.component';
import { NcsTextTranslationComponent } from './text-translation/components/ncs-text-translation.component';
import { NcsTableCrudSharedModule } from '../table-crud/table-crud/table-crud-shared.module';
import { NcsTooltipSharedModule } from '../tooltip/tooltip-shared.module';
import { NcsDialogBasicSharedModule } from '../dialog-basic/dialog-basic-shared.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NcsTableCrudSharedModule,
    NcsTooltipSharedModule,
    InputTextModule,
    NcsDialogBasicSharedModule,
  ],
  declarations: [NcsTextBasicComponent, NcsTextSearchComponent, NcsTextTranslationComponent],
  exports: [NcsTextBasicComponent, NcsTextSearchComponent, NcsTextTranslationComponent],
})
export class NcsTextSharedModule {}
