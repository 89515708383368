import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { ThirdPartyType } from './third-party-type.model';
import { ThirdPartyContact } from './third-party-contact.model';
import { ThirdPartyAddress } from './third-party-address.model';
import { ThirdPartyThirdPartyType } from './third-party-third-party-type.model';

@Entity('masterdata', 'ThirdParty', 'qc')
export class ThirdParty extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized taxId?: string;
  @Serialized tenant?: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized countryId: number;
  @Serialized nkgGroup?: boolean;
  @Serialized uuid?: string;
  @Serialized approvalPassword: string;
  @Serialized defaultThirdPartyContact?: ThirdPartyContact;
  @Serialized defaultThirdPartyAddress?: ThirdPartyAddress;
  @Serialized({ elementType: ThirdPartyContact, composite: true })
  thirdPartyContacts?: ThirdPartyContact[] = [];
  @Serialized({ elementType: ThirdPartyAddress, composite: true })
  thirdPartyAddresses?: ThirdPartyAddress[] = [];
  @Serialized({
    elementType: ThirdPartyThirdPartyType,
    composite: true,
  })
  thirdPartyThirdPartyTypes?: ThirdPartyThirdPartyType[] = [];
}
