import { Component, Input } from '@angular/core';
import { FieldValidationResult, Message } from '../../../../../../modules/master/services/master-validation';
import { nullsafe } from '../../../../../../modules/utils/object-utils';

@Component({
  selector: 'ncs-error-tooltip',
  templateUrl: './error-tooltip.component.html',
})
export class NcsErrorTooltipComponent {
  // make conversion from existing code as simple as poss.

  @Input() validationResult: FieldValidationResult;
  @Input() checkbox: boolean = false; // for formatting
  @Input() ngStyle: any;
  @Input() enableCleanValidation: boolean = false;

  isFieldInvalid(): boolean {
    if (this.validationResult) {
      return this.validationResult.invalid;
    }
    return false;
  }

  isOnlyWarnings(): boolean {
    return nullsafe(this.validationResult?.errors).length == 0 && nullsafe(this.validationResult?.warnings).length > 0;
  }

  getValidationMessages(): Message[] {
    return [].concat(nullsafe(this.validationResult?.errors), nullsafe(this.validationResult?.warnings));
  }

  public cleanValidationResult(): void {
    if (this.enableCleanValidation) {
      this.validationResult = undefined;
    }
  }

  public isOnlyErrors(): boolean {
    return nullsafe(this.validationResult?.errors).length > 0 && nullsafe(this.validationResult?.warnings).length == 0;
  }
}
