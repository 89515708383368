import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MessageService } from 'primeng/api';
import { appReducers, metaReducers } from './reducers';
import { environment } from '../../../environments/environment';
import { ApprovalEffects, AuthEffects, effectsArr, MasterEffects, UserEffects } from './effects';
import { AppSelector, AuthSelector } from './selectors';
import { AuthStoreService } from './store/auth.store';
import { AppStoreService } from './store/app.store';
import { RoleService } from './services/role.service';
import { TenantService } from './services/tenant.service';
import { AppMetaDataService } from './services/app-meta-data.service';
import { ApprovalService } from './services/approval.service';
import { UserProfileService } from './services/user-profile.service';
import { ViewCustomizationOverlayService } from './services/customize-table-overlay.service';
import { TimeService } from './services/time-service';
import { AuthorizationService } from './services/authorization.service';
import { NotificationService } from './services/notification.service';
import { QueryService } from './services/query.service';
import { CuppingProcessService } from './services/cupping-process.service';
import { CuppingSessionService } from './services/cupping-sesion.service';

@NgModule({
  imports: [
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'QCS app',
          // maxAge: 25, // Retains last 25 states
          logOnly: environment.production, // Restrict extension to log-only mode
        })
      : [],

    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot(effectsArr),
  ],
  providers: [
    MessageService,
    AuthorizationService,
    NotificationService,
    QueryService,
    CuppingProcessService,
    TimeService,
    RoleService,
    TenantService,
    AppMetaDataService,
    ApprovalService,
    UserProfileService,
    ViewCustomizationOverlayService,
    CuppingSessionService,

    AuthEffects,
    AuthSelector,
    AuthStoreService,
    AppStoreService,
    AppSelector,
    UserEffects,
    MasterEffects,
    ApprovalEffects,
  ],
})
export class NgrxModule {}
