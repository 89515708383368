import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import {
  approvalSuccess,
  approveDispatch,
  approvingFailed,
  sessionValidated,
  sessionValidateFailed,
  validateSession,
} from '../actions';
import { ApprovalService } from '../services/approval.service';
import { DispatchApprovalCredentials } from '../../domain/dispatch-approval-credentials.model';

@Injectable()
export class ApprovalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly approvalService: ApprovalService,
  ) {}

  public readonly approveSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(approveDispatch),
      switchMap(payload =>
        this.approvalService
          .approve(payload.credentials)
          .then((resp: DispatchApprovalCredentials) => {
            return approvalSuccess({ response: resp });
          })
          .catch(err => approvingFailed({ errorResponse: err })),
      ),
    ),
  );

  public readonly validateSession: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(validateSession),
      switchMap(payload =>
        this.approvalService
          .validateSession(payload.session)
          .then((resp: DispatchApprovalCredentials) => {
            return sessionValidated({ credential: resp });
          })
          .catch(err => sessionValidateFailed({ errorResponse: err })),
      ),
    ),
  );
}
