import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { MetricsDefinitionGroup } from './metrics-definition-group.model';
import { MetricsDefinitionOptionImage } from './metrics-definition-option-image.model';

@Entity('masterdata', 'MetricsDefinitionOption', 'qc')
export class MetricsDefinitionOption extends AbstractVersionedEntity {
  @Serialized optionName: string;
  @Serialized optionCode: string;
  @Serialized optionDescription: string;
  @Serialized optionCheck: boolean;
  @Serialized optionNumeric: boolean;
  @Serialized optionNumericMax?: number;
  @Serialized optionNumericMin?: number;
  @Serialized optionNumericMinLabel?: string;
  @Serialized optionNumericMaxLabel?: string;
  @Serialized optionDate: boolean;
  @Serialized optionVarchar: boolean;
  @Serialized metricsDefinitionGroup: MetricsDefinitionGroup;
  @Serialized metricsDefinition?: number;
  @Serialized optionColorValue?: string;
  @Serialized({
    elementType: MetricsDefinitionOptionImage,
    composite: true,
  })
  metricsDefinitionOptionImages?: MetricsDefinitionOptionImage[] = [];
}
