export enum Permission {
  COFFEE_TASTING = 'COFFEE_TASTING',
  AUTH_SERVICE = 'AUTH_SERVICE',
  VIEW_ROLE = 'VIEW_ROLE',
  EDIT_ROLE = 'EDIT_ROLE',
  VIEW_SAMPLE_TYPE = 'VIEW_SAMPLE_TYPE',
  EDIT_SAMPLE_TYPE = 'EDIT_SAMPLE_TYPE',
  EDIT_LANGUAGE = 'EDIT_LANGUAGE',
  VIEW_LANGUAGE = 'VIEW_LANGUAGE',
  EDIT_COUNTRY = 'EDIT_COUNTRY',
  VIEW_COUNTRY = 'VIEW_COUNTRY',
  EDIT_REGION = 'EDIT_REGION',
  VIEW_REGION = 'VIEW_REGION',
  EDIT_STATE = 'EDIT_STATE',
  VIEW_STATE = 'VIEW_STATE',
  EDIT_COFFEE_SPECIE = 'EDIT_COFFEE_SPECIE',
  VIEW_COFFEE_SPECIE = 'COFFEE_COFFEE_SPECIE',
  EDIT_COFFEE_VARIETY = 'EDIT_COFFEE_VARIETY',
  VIEW_COFFEE_VARIETY = 'VIEW_COFFEE_VARIETY',
  VIEW_USER = 'VIEW_USER',
  EDIT_USER = 'EDIT_USER',
  VIEW_TENANT = 'VIEW_TENANT',
  EDIT_TENANT = 'EDIT_TENANT',
  VIEW_AGENCY = 'VIEW_AGENCY',
  EDIT_AGENCY = 'EDIT_AGENCY',
  VIEW_WAREHOUSE = 'VIEW_WAREHOUSE',
  EDIT_WAREHOUSE = 'EDIT_WAREHOUSE',
  EDIT_HARVEST_METHOD = 'EDIT_HARVEST_METHOD',
  VIEW_HARVEST_METHOD = 'VIEW_HARVEST_METHOD',
  EDIT_SOIL_TYPE = 'EDIT_SOIL_TYPE',
  VIEW_SOIL_TYPE = 'VIEW_SOIL_TYPE',
  EDIT_SAMPLE_STATUS = 'EDIT_SAMPLE_STATUS',
  EDIT_COURIER_COMPANY = 'EDIT_COURIER_COMPANY',
  EDIT_CONTACT_TYPE = 'EDIT_CONTACT_TYPE',
  VIEW_SAMPLE_STATUS = 'VIEW_SAMPLE_STATUS',
  VIEW_COURIER_COMPANY = 'VIEW_COURIER_COMPANY',
  VIEW_CONTACT_TYPE = 'VIEW_CONTACT_TYPE',
  EDIT_THIRD_PARTY_TYPE = 'EDIT_THIRD_PARTY_TYPE',
  VIEW_THIRD_PARTY_TYPE = 'VIEW_THIRD_PARTY_TYPE',
  VIEW_DRYING_PROCESS = 'VIEW_DRYING_PROCESS',
  EDIT_DRYING_PROCESS = 'EDIT_DRYING_PROCESS',
  VIEW_MILLING_PROCESS = 'VIEW_MILLING_PROCESS',
  EDIT_MILLING_PROCESS = 'EDIT_MILLING_PROCESS',
  VIEW_STAGE_PROCESSING = 'VIEW_STAGE_PROCESSING',
  EDIT_STAGE_PROCESSING = 'EDIT_STAGE_PROCESSING',
  VIEW_CUP_EVALUATOR = 'VIEW_CUP_EVALUATOR',
  EDIT_CUP_EVALUATOR = 'EDIT_CUP_EVALUATOR',
  VIEW_COFFEE_PROGRAM = 'VIEW_COFFEE_PROGRAM',
  EDIT_COFFEE_PROGRAM = 'EDIT_COFFEE_PROGRAM',
  VIEW_METRIC_TYPE = 'VIEW_METRIC_TYPE',
  EDIT_METRIC_TYPE = 'EDIT_METRIC_TYPE',
  VIEW_METRIC_TYPE_RESULTS = 'VIEW_METRIC_TYPE_RESULTS',
  EDIT_METRIC_TYPE_RESULTS = 'EDIT_METRIC_TYPE_RESULTS',
  VIEW_METRIC_GROUP = 'VIEW_METRIC_GROUP',
  EDIT_METRIC_GROUP = 'EDIT_METRIC_GROUP',
  VIEW_METRIC_DEFINITION = 'VIEW_METRIC_DEFINITION',
  EDIT_METRIC_DEFINITION = 'EDIT_METRIC_DEFINITION',
  VIEW_STANDARD_DEFINITION = 'VIEW_STANDARD_DEFINITION',
  EDIT_STANDARD_DEFINITION = 'EDIT_STANDARD_DEFINITION',
  VIEW_QUALITY = 'VIEW_QUALITY',
  EDIT_QUALITY = 'EDIT_QUALITY',
  VIEW_FACT_SHEET = 'VIEW_FACT_SHEET',
  EDIT_FACT_SHEET = 'EDIT_FACT_SHEET',
  VIEW_TASTING_NOTE = 'VIEW_TASTING_NOTE',
  EDIT_TASTING_NOTE = 'EDIT_TASTING_NOTE',
  VIEW_QUALITY_TYPE = 'VIEW_QUALITY_TYPE',
  EDIT_QUALITY_TYPE = 'EDIT_QUALITY_TYPE',
  VIEW_ITEM_DEFINITION = 'VIEW_ITEM_DEFINITION',
  EDIT_ITEM_DEFINITION = 'EDIT_ITEM_DEFINITION',
  VIEW_CHECK_LIST_DEFINITION = 'VIEW_CHECK_LIST_DEFINITION',
  EDIT_CHECK_LIST_DEFINITION = 'EDIT_CHECK_LIST_DEFINITION',
  VIEW_THIRD_PARTY = 'VIEW_THIRD_PARTY',
  EDIT_THIRD_PARTY = 'EDIT_THIRD_PARTY',
  VIEW_ADDRESS_TYPE = 'VIEW_ADDRESS_TYPE',
  EDIT_ADDRESS_TYPE = 'EDIT_ADDRESS_TYPE',
  VIEW_SAMPLE = 'VIEW_SAMPLE',
  EDIT_SAMPLE = 'EDIT_SAMPLE',
  VIEW_PACKAGE_TYPE = 'VIEW_PACKAGE_TYPE',
  EDIT_PACKAGE_TYPE = 'EDIT_PACKAGE_TYPE',
  VIEW_COFFEE_CERTIFICATION = 'VIEW_COFFEE_CERTIFICATION',
  EDIT_COFFEE_CERTIFICATION = 'EDIT_COFFEE_CERTIFICATION',
  VIEW_LABORATORY = 'VIEW_LABORATORY',
  EDIT_LABORATORY = 'EDIT_LABORATORY',
  VIEW_CUPPING_SESSION = 'VIEW_CUPPING_SESSION',
  EDIT_CUPPING_SESSION = 'EDIT_CUPPING_SESSION',
  VIEW_CUPPING_SESSION_STATUS = 'VIEW_CUPPING_SESSION_STATUS',
  EDIT_CUPPING_SESSION_STATUS = 'EDIT_CUPPING_SESSION_STATUS',
  VIEW_SAMPLE_DISPATCH_STATUS = 'VIEW_SAMPLE_DISPATCH_STATUS',
  EDIT_SAMPLE_DISPATCH_STATUS = 'EDIT_SAMPLE_DISPATCH_STATUS',
  VIEW_CUPPING_PROCESS = 'VIEW_CUPPING_PROCESS',
  EDIT_CUPPING_PROCESS = 'EDIT_CUPPING_PROCESS',
  VIEW_CUPPING_PROCESS_SEARCH = 'VIEW_CUPPING_PROCESS_SEARCH',
  EDIT_CUPPING_PROCESS_SEARCH = 'EDIT_CUPPING_PROCESS_SEARCH',
  EDIT_CUP_EVALUATOR_CUPPING_PROCESS = 'EDIT_CUP_EVALUATOR_CUPPING_PROCESS',
  VIEW_ALL_CUPPING_PROCESS = 'VIEW_ALL_CUPPING_PROCESS',
  EDIT_ALL_CUPPING_PROCESS = 'EDIT_ALL_CUPPING_PROCESS',
  VIEW_SAMPLE_APPROVAL_INTERNAL_CONDITION = 'VIEW_SAMPLE_APPROVAL_INTERNAL_CONDITION',
  EDIT_SAMPLE_APPROVAL_INTERNAL_CONDITION = 'EDIT_SAMPLE_APPROVAL_INTERNAL_CONDITION',
  LOCK_DISPATCH = 'LOCK_DISPATCH',
  UNLOCK_DISPATCH = 'UNLOCK_DISPATCH',
  VIEW_SAMPLE_FILE = 'VIEW_SAMPLE_FILE',
  EDIT_SAMPLE_FILE = 'EDIT_SAMPLE_FILE',
  VIEW_STORAGE = 'VIEW_STORAGE',
  EDIT_STORAGE = 'EDIT_STORAGE',
  VIEW_STORAGE_TYPE = 'VIEW_STORAGE_TYPE',
  EDIT_STORAGE_TYPE = 'EDIT_STORAGE_TYPE',
  EDIT_QUALITY_ITEM = 'EDIT_QUALITY_ITEM',
  VIEW_QUALITY_ITEM = 'VIEW_QUALITY_ITEM',
  EDIT_QUALITY_MAIN = 'EDIT_QUALITY_MAIN',
  VIEW_QUALITY_MAIN = 'VIEW_QUALITY_MAIN',
  EDIT_QUALITY_ADDITIONAL_BRANDING = 'EDIT_QUALITY_ADDITIONAL_BRANDING',
  VIEW_QUALITY_ADDITIONAL_BRANDING = 'VIEW_QUALITY_ADDITIONAL_BRANDING',
}
