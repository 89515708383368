import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { ThirdPartyType } from './third-party-type.model';

@Entity('masterdata', 'ThirdPartyThirdPartyType', 'qc')
export class ThirdPartyThirdPartyType extends AbstractVersionedEntity {
  @Serialized account: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized comtrasReference: string;
}
