import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../../../../../../core/ngrx/reducers';
import { MasterdataValidationService } from '../../../../../../services/masterdata-validation.service';
import { CuppingSessionService } from '../../../../../../../../core/ngrx/services/cupping-sesion.service';
import { isNullOrUndefined, mergeImmutable, nullsafe, Path } from '../../../../../../../utils/object-utils';
import { extractEvent } from '../../../../../../../utils/extract-event';
import { ValidationResult } from '../../../../../../services/master-validation';
import { NkgGridFactory } from '../../../../../../../../shared/grid/services/grid.factory';
import { cupEvaluatorRequestParams } from '../../../../../../utils/master-request-params-utils';
import { setMasterDataSelectedItem, setMasterModifyMode } from '../../../../../../../../core/ngrx/actions';
import { SampleService } from '../../../../../sample/services/sample.service';
import { User } from '../../../../../../../../core/domain/user.model';
import { PaginationRequestParams } from '../../../../../../../../core/domain/pagination-request-params.model';
import { CupEvaluator } from '../../../../../../../../core/domain/cup-evaluator.model';
import { CuppingSessionCupEvaluator } from '../../../../../../../../core/domain/cupping-session-cup-evaluator.model';
import { CuppingSession } from '../../../../../../../../core/domain/cupping-session.model';

@Component({
  selector: 'app-cupping-session-evaluators',
  templateUrl: './cupping-session-evaluators.component.html',
})
export class CuppingSessionEvaluatorsComponent implements OnDestroy {
  @Input() validationResult: ValidationResult;

  @Output() onDisplayGuestDialog: EventEmitter<any> = new EventEmitter<any>();

  @Output() onblur: EventEmitter<Path> = new EventEmitter<Path>();

  selectedCupEvaluators: CupEvaluator[];

  cuppingSessionCupEvaluatorRowData: CuppingSessionCupEvaluator[];

  gridCuppingSessionEvaluators: GridOptions;

  columnDefsCupEvaluators: ColDef[];

  cuppingSessionSelected$: Observable<CuppingSession>; // Contain all data nested (CupEvaluators, samples, cuppingProcess, etc)

  currentUser: User;

  currentItem: CuppingSession;

  editMode: boolean;

  cupEvaluatorRequestParams: PaginationRequestParams;

  cupEvaluatorEntity: Function = CupEvaluator;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected translationService: TranslateService,
    protected store: Store<fromRoot.State>,
    protected gf: NkgGridFactory,
    protected router: Router,
    protected route: ActivatedRoute,
    protected validationService: MasterdataValidationService,
    protected cuppingSessionService: CuppingSessionService,
    private readonly messageService: MessageService,
    protected sampleService: SampleService,
  ) {
    this.gridCuppingSessionEvaluators = this.gf.getDefaultGridOptions(false, false, this);
    this.columnDefsCupEvaluators = this.getColDefsCupEvaluators();
    this.cuppingSessionSelected$ = this.store.select(fromRoot.getCuppingSession);
    this.cupEvaluatorRequestParams = cupEvaluatorRequestParams();
    this.cuppingSessionEvaluatorSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected getColDefsCupEvaluators(): ColDef[] {
    return this.cuppingSessionService.loadCupEvaluatorColDefs();
  }

  protected getCurrentItem(): CuppingSession {
    return this.currentItem;
  }

  onChangeUseCupEvaluators(useAllCupEvaluators: boolean): void {
    this.onItemFieldChange(useAllCupEvaluators, 'useAllCupEvaluators');
  }

  /** Remove cup Evaluators from cupping session
   * @param {CupEvaluator} cupEvaluator - cupEvaluator model */
  onRemoveCupEvaluator(cupEvaluator: CupEvaluator): void {
    let newCuppingSessionCupEvaluators: CuppingSessionCupEvaluator[];
    const rmCupEvaluator = nullsafe(this.getCurrentItem().cuppingSessionCupEvaluators).find(
      sessionEvaluator => sessionEvaluator.cupEvaluator.id === cupEvaluator.id,
    );
    if (rmCupEvaluator.id < 0) {
      // CASE 1: If item is new only removed from cuppingSessionCupEvaluators array list
      newCuppingSessionCupEvaluators = nullsafe(this.getCurrentItem().cuppingSessionCupEvaluators).filter(
        sessionEvaluator => sessionEvaluator.id !== rmCupEvaluator.id,
      );
    } else {
      // CASE 2: If cupEvaluator is saved then modify deleted property to true id it doesn't make any cupping process into this cuppingSession
      let evaluatorsIntoCupping: Set<any>;
      this.cuppingSessionSelected$.subscribe(cupping => {
        evaluatorsIntoCupping = nullsafe(cupping.cuppingProcesses).reduce(
          (a, c) => a.add(c.cupEvaluator.id),
          new Set(),
        );
      });
      if (!evaluatorsIntoCupping.has(cupEvaluator.id)) {
        newCuppingSessionCupEvaluators = nullsafe(this.getCurrentItem().cuppingSessionCupEvaluators).map(
          sessionEvaluator => {
            if (sessionEvaluator.id === rmCupEvaluator.id) {
              return mergeImmutable(sessionEvaluator, {
                deleted: true,
              });
            }
            return sessionEvaluator;
          },
        );
      } else {
        return this.messageService.add({
          severity: 'warn',
          summary: this.translationService.instant('common.labels.warnings'),
          detail: this.translationService.instant('notification.cupping_session_remove_cup_evaluators'),
        });
      }
    }
    this.onItemFieldChange(newCuppingSessionCupEvaluators, 'cuppingSessionCupEvaluators');
  }

  onItemFieldChange(event: any, fld?: string): void {
    const upd = {};
    // [QC-39] validate if not empty event
    upd[fld] = event && !Object.keys(event).length ? event : extractEvent(event);
    const newItem: CuppingSession = mergeImmutable(<any>this.getCurrentItem(), upd);
    this.store.dispatch(setMasterDataSelectedItem({ selectedItem: newItem }));
    this.store.dispatch(setMasterModifyMode({ isModifyMode: true }));
  }

  /** Add cup Evaluators to cupping session
   * @param {CupEvaluator} cupEvaluators - cup evaluators array */
  onSelectCupEvaluator(cupEvaluators: CupEvaluator[]): void {
    let sessionCupEvaluators: CuppingSessionCupEvaluator[];
    if (nullsafe(this.getCurrentItem().cuppingSessionCupEvaluators).length > 0) {
      // avoid to creat or add duplicate cuppingSessionCupEvaluators
      sessionCupEvaluators = nullsafe(this.getCurrentItem().cuppingSessionCupEvaluators).filter(
        sessionEvaluator => sessionEvaluator.cupEvaluator,
      );
      const removeDuplicate = (sessionCupEvaluators || []).reduce((a, c) => a.add(c.cupEvaluator.id), new Set());
      const newCupEvaluators: CupEvaluator[] = cupEvaluators.filter(value => !removeDuplicate.has(value.id)); // samples[only new item]
      // when remove items and don't save.
      // CASE 1: To add same items again,this item should be change deleted property to false
      if (!newCupEvaluators.length) {
        sessionCupEvaluators = sessionCupEvaluators.map(sessionEvaluator => {
          const isDeleted = cupEvaluators.some(evaluator => sessionEvaluator.cupEvaluator.id == evaluator.id);
          // Assume any cupEvaluator is added again and didn't save, needs recovery these item
          if (isDeleted) {
            return mergeImmutable(sessionEvaluator, { deleted: false });
          }
          return sessionEvaluator;
        });
      } else {
        // Unify samples saved with new samples
        sessionCupEvaluators = sessionCupEvaluators.concat(
          newCupEvaluators.map(cupEvaluator => {
            return mergeImmutable(new CuppingSessionCupEvaluator(), {
              cupEvaluator: cupEvaluator,
            });
          }),
        );
      }
    } else {
      // initial load
      sessionCupEvaluators = (cupEvaluators || []).map(cupEvaluator => {
        return mergeImmutable(new CuppingSessionCupEvaluator(), {
          cupEvaluator: cupEvaluator,
        });
      });
    }
    this.onItemFieldChange(sessionCupEvaluators, 'cuppingSessionCupEvaluators');
    this.onItemFieldBlur('cuppingSessionCupEvaluators');
  }

  onEmitGuestDialog(): void {
    this.onDisplayGuestDialog.emit(true);
  }
  /** Retrieve cup Evaluators don't deleted
   * @param {CuppingSessionCupEvaluator} sessionCupEvaluators[]
   * @return {CuppingSessionCupEvaluator} - Cupping session's evaluators */
  onFilteringCupEvaluators(sessionCupEvaluators: CuppingSessionCupEvaluator[]): CuppingSessionCupEvaluator[] {
    return (sessionCupEvaluators || []).filter(sessionEvaluator => !sessionEvaluator.deleted);
  }

  private cuppingSessionEvaluatorSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCurrentUser),
      this.store.select(fromRoot.getMasterCurrentItem),
      this.store.select(fromRoot.getEditMasterModeActive),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentUser, currentItem, editMode]) => {
        this.currentUser = currentUser;
        this.currentItem = currentItem;
        this.editMode = editMode;
        if (!isNullOrUndefined(currentItem)) {
          this.onLoadCuppingEvaluators();
        }
      });
  }

  private onLoadCuppingEvaluators(): void {
    this.cuppingSessionCupEvaluatorRowData = this.onFilteringCupEvaluators(
      this.getCurrentItem().cuppingSessionCupEvaluators,
    );
    this.selectedCupEvaluators = (this.cuppingSessionCupEvaluatorRowData || []).map(
      sessionEvaluator => sessionEvaluator.cupEvaluator,
    );
  }

  /** Used by agGrid on context params */
  getEditMode(): boolean {
    return this.editMode;
  }

  /** Update cupping session cup evaluators by agGrid event */
  public onUpdateCuppingSessionEvaluatorsByAgGrid(updateCuppingSessionCupEvaluator: CuppingSessionCupEvaluator): void {
    const newItems: CuppingSessionCupEvaluator[] = this.getCurrentItem().cuppingSessionCupEvaluators.map(
      cuppingSessionCupEvaluator => {
        if (cuppingSessionCupEvaluator.id === updateCuppingSessionCupEvaluator.id) {
          return mergeImmutable(cuppingSessionCupEvaluator, updateCuppingSessionCupEvaluator);
        }
        return cuppingSessionCupEvaluator;
      },
    );
    this.onItemFieldChange(newItems, 'cuppingSessionCupEvaluators');
  }

  public onItemFieldBlur(fld: Path): void {
    this.onblur.emit(fld);
  }

  public onGetUrlService(): string {
    return !this.currentItem?.useAllCupEvaluators
      ? `cup_evaluator/${this.currentUser?.defaultTenant?.id}/by_tenant`
      : '';
  }
}
