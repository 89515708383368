<p-dialog
  header="{{ 'topbar.labels.version_info' | translate }}"
  [(visible)]="visible"
  modal="modal"
  (onHide)="onClose()">
  <div style="display: table">
    <div style="display: table-row">
      <div style="display: table-cell; min-width: 6em">Application</div>
      <div style="display: table-cell">
        {{ appVersionInfo?.application ? appVersionInfo.application : ('common.labels.unknown' | translate) }}
      </div>
    </div>
    <div style="display: table-row">
      <div style="display: table-cell; min-width: 6em">Backend</div>
      <div style="display: table-cell">
        {{ appVersionInfo?.backend ? appVersionInfo.backend : ('common.labels.unknown' | translate) }}
      </div>
    </div>
    <div style="display: table-row">
      <div style="display: table-cell">Frontend</div>
      <div style="display: table-cell">
        {{ appVersionInfo?.frontend ? appVersionInfo.frontend : ('common.labels.unknown' | translate) }}
      </div>
    </div>
    <div style="display: table-row">
      <div style="display: table-cell">Server</div>
      <div style="display: table-cell">
        {{ appVersionInfo?.edgeServer ? appVersionInfo.edgeServer : ('common.labels.unknown' | translate) }}
      </div>
    </div>
    <div style="display: table-row">
      <div style="display: table-cell">Database</div>
      <div style="display: table-cell">
        {{ appVersionInfo?.database ? appVersionInfo.database : ('common.labels.unknown' | translate) }}
      </div>
    </div>
    <div style="display: table-row">
      <div style="display: table-cell">Browser</div>
      <div style="display: table-cell">
        {{ wndw?.navigator?.userAgent ? wndw?.navigator?.userAgent : ('common.labels.unknown' | translate) }}
      </div>
    </div>
    <div *ngIf="appVersionInfo?.deployTimestamp" style="display: table-row">
      <div style="display: table-cell">Deployed</div>
      <div style="display: table-cell">
        {{ appVersionInfo?.deployTimestamp | date: 'M/d/yy, hh:mm zzzz' }}
      </div>
    </div>
  </div>
  <p-footer>
    <ncs-button [label]="'common.buttons.close'" (onClicked)="onClose()"></ncs-button>
  </p-footer>
</p-dialog>
