import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { ReferenceType } from './reference-type.model';
import { PackageType } from './package-type.model';
import { SampleType } from './sample-type.model';
import { ThirdParty } from './third-party.model';
import { Quality } from './quality.model';
import { StageProcessing } from './stage-processing.model';
import { StandardDefinition } from './standard-definition.model';
import { SampleStatus } from './sample-status.model';
import { SampleThirdPartyType } from './sample-third-party-type.model';
import { SampleQualityFinal } from './sample-quality-final.model';
import { SampleApprovalInternalCondition } from './sample-approval-internal-condition.model';
import { ThirdPartyContact } from './third-party-contact.model';
import { CoffeeCertification } from './coffee-certification.model';
import { Country } from './country.model';

@Entity('sample', 'Sample', 'qc')
export class Sample extends AbstractVersionedEntity {
  @Serialized sample: string;
  @Serialized tenant?: string;
  @Serialized sampleSource: Sample;
  @Serialized date: Date;
  @Serialized sampleReference: string;
  @Serialized referenceType: ReferenceType;
  @Serialized ourReference: string;
  @Serialized contractReference: string;
  @Serialized agencyId: number;
  @Serialized quantityPackage: number;
  @Serialized quantity: number;
  @Serialized sampleBalance: number;
  @Serialized description: string;
  @Serialized qualityDescription: string;
  @Serialized countryId: number;
  @Serialized location: string;
  @Serialized packageType: PackageType;
  @Serialized sampleType: SampleType;
  @Serialized thirdParty: ThirdParty;
  @Serialized quality: Quality;
  @Serialized qualityFinal: Quality;
  @Serialized stageProcessing: StageProcessing;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized sensoryCuppingProcessId: number;
  @Serialized physicalCuppingProcessId: number;
  @Serialized sampleStatus: SampleStatus;
  @Serialized sampleStatusCommentary: string;
  @Serialized cropSample: string;
  @Serialized monthShipment: Date;
  @Serialized({
    elementType: SampleThirdPartyType,
    composite: true,
  })
  sampleThirdPartyTypes?: SampleThirdPartyType[] = [];
  @Serialized({ elementType: SampleQualityFinal, composite: true })
  sampleQualityFinals?: SampleQualityFinal[] = [];
  @Serialized approvalInternalSampleStatus: SampleStatus;
  @Serialized sampleApprovalInternalCondition: SampleApprovalInternalCondition;
  @Serialized approvalInternalThirdParty: ThirdParty;
  @Serialized approvalInternalThirdPartyContact: ThirdPartyContact;
  @Serialized approvalInternalContactName: string;
  @Serialized approvalInternalContactEmail: string;
  @Serialized approvalInternalComment: string;
  @Internal isValid: boolean = true;
  @Serialized storageTypeId: number;
  @Serialized storageId: number;
  @Serialized({ elementType: CoffeeCertification })
  coffeeCertifications: CoffeeCertification[] = [];
  @Internal country: Country;
}
