import { createAction, props } from '@ngrx/store';
import { AuthenticationResult } from '@azure/msal-browser';
import { NotificationHandler } from '../utils/notification-utils';
import { AuthCredentials } from '../../domain/auth-credentials.model';
import { User } from '../../domain/user.model';
import { OptionMenu } from '../../domain/option-menu.model';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  FETCH_MENU = '[Auth] Fetch Menus',
  FETCH_MENU_SUCCESS = '[Auth] Fetch Success',
  FETCH_CURRENT_USER = '[Auth] Fetch Current User',
  USER_PROFILE_SAVE = '[Auth] User Profile Save',
  SET_APP_USER = '[Auth] Set App User',
  LOGIN_LOGOUT_CHECKER = '[Auth] Login LogOut checker',
  NOTIFICATION_EMITTER = '[Auth] Notification emitter',
  NOTIFICATION_EMITTER_CLEAR = '[Auth] Clear Notification emitter',
  RESET_AUTH_STATE = '[Auth] Reset Auth State',
  MSAL_LOGOUT = '[Auth] Logout from Office 365',
  MSAL_LOGIN_SUCCESS = '[Auth] Login vía Office 365 succeeded',
  MSAL_LOGIN_FAILED = '[Auth] Login via Office 365 failed',
  MSAL_LOGOUT_SUCCESS = '[Auth] Logout via Office 365 succeeded',
  GET_USER_BY_USERNAME = '[Auth] Get a user by its username',
  GET_USER_BY_EMAIL = '[Auth] Get a user by its email',
  USER_RETRIEVED_BY_USERNAME = '[Auth] User retrieved by username',
  USER_RETRIEVED_BY_EMAIL = '[Auth] User retrieved by email',
  DISPLAY_CONTENT_ONLY = '[Auth] Display content only',
}

export const resetAuthState = createAction(AuthActionTypes.RESET_AUTH_STATE);

export const appLogin = createAction(AuthActionTypes.LOGIN, props<{ credentials: AuthCredentials }>());

export const msalLoginSuccess = createAction(
  AuthActionTypes.MSAL_LOGIN_SUCCESS,
  props<{ response: AuthenticationResult }>(),
);

export const msalLoginFailed = createAction(AuthActionTypes.MSAL_LOGIN_FAILED, props<{ response: any }>());

export const loginSuccess = createAction(AuthActionTypes.LOGIN_SUCCESS, props<{ username: string }>());

export const msalLogoutSuccess = createAction(AuthActionTypes.MSAL_LOGOUT_SUCCESS);

export const fetchCurrentUser = createAction(AuthActionTypes.FETCH_CURRENT_USER);

export const saveAppUser = createAction(AuthActionTypes.USER_PROFILE_SAVE, props<{ user: User }>());

/** @param forcedAuthentication is used when call fetchCurrentUser.
 * If the request is successful, then it sets forceAuthentication property to true */
export const setAppUser = createAction(
  AuthActionTypes.SET_APP_USER,
  props<{ user: User; forceAuthentication?: boolean }>(),
);

export const loginFailure = createAction(AuthActionTypes.LOGIN_FAILURE, props<{ errorMessage: any }>());

export const loginLogoutChecker = createAction(AuthActionTypes.LOGIN_LOGOUT_CHECKER);

export const logout = createAction(AuthActionTypes.LOGOUT);

export const msalLogout = createAction(AuthActionTypes.MSAL_LOGOUT);

export const getAppMenu = createAction(AuthActionTypes.FETCH_MENU);

export const getAppMenuSuccess = createAction(AuthActionTypes.FETCH_MENU_SUCCESS, props<{ menus: OptionMenu[] }>());

export const getAppUserByUsername = createAction(AuthActionTypes.GET_USER_BY_USERNAME, props<{ username: string }>());

export const getAppUserByMail = createAction(AuthActionTypes.GET_USER_BY_EMAIL, props<{ email: string }>());

export const getAppUserByUsernameSuccess = createAction(
  AuthActionTypes.USER_RETRIEVED_BY_USERNAME,
  props<{ user: User }>(),
);

export const getAppUserByEmailSuccess = createAction(AuthActionTypes.USER_RETRIEVED_BY_EMAIL, props<{ user: User }>());

export const authNotificationEmitter = createAction(
  AuthActionTypes.NOTIFICATION_EMITTER,
  props<{ [P in keyof NotificationHandler]: NotificationHandler[P] }>(),
);

export const clearAuthNotification = createAction(AuthActionTypes.NOTIFICATION_EMITTER_CLEAR);

export const authDisplayContentOnly = createAction(
  AuthActionTypes.DISPLAY_CONTENT_ONLY,
  props<{ onlyContent: boolean }>(),
);
