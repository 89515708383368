import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { StandardDefinition } from './standard-definition.model';
import { Sample } from './sample.model';
import { Laboratory } from './laboratory.model';
import { MetricsDefinitionType } from './metrics-definition-type.model';
import { CupEvaluator } from './cup-evaluator.model';
import { Quality } from './quality.model';
import { StageProcessing } from './stage-processing.model';
import { CuppingProcessMetric } from './cupping-process-metric.model';
import { CupIllustration } from './cup-illustration.model';
import { CuppingProcessQualityAllocation } from './cupping-process-quality-allocation.model';

@Entity('cupping', 'CuppingProcess', 'qc')
export class CuppingProcess extends AbstractVersionedEntity {
  @Serialized cuppingSessionId: number;
  @Serialized session: string;
  @Serialized cuppingDate: Date;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized sample: Sample;
  @Serialized laboratory: Laboratory;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
  @Serialized cupEvaluator: CupEvaluator;
  @Serialized sampleWeight: number;
  @Serialized totalCups: number;
  @Serialized comment: string;
  @Serialized value: number; // score final
  @Serialized closed: boolean;
  @Serialized toAverage: boolean = false;
  @Serialized averageResult: boolean;
  @Serialized quality: Quality;
  @Serialized stageProcessing: StageProcessing;
  @Serialized applied: boolean;
  @Serialized({ elementType: CuppingProcessMetric, composite: true })
  cuppingProcessMetrics?: CuppingProcessMetric[];
  @Serialized() cupIllustration: CupIllustration;
  @Serialized({ elementType: CuppingProcess, composite: true })
  cuppingProcesses?: CuppingProcess[];
  @Serialized({ elementType: CuppingProcessQualityAllocation, composite: true })
  cuppingProcessQualityAllocations?: CuppingProcessQualityAllocation[] = [];
  @Internal isReady: boolean = false;
}
