import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { merge } from 'lodash';
import { isEmpty } from '../../../../../../../utils/string-utils';
import { MasterdataValidationService } from '../../../../../../services/masterdata-validation.service';
import * as fromRoot from '../../../../../../../../core/ngrx/reducers';
import { MasterChildComponent } from '../../../../../../components/master-child.component';
import { isNullOrUndefined, mergeImmutable, nullsafe } from '../../../../../../../utils/object-utils';
import { Rule, Validation } from '../../../../../../services/master-validation';
import { CupIllustrationService } from '../../../../../metric-definition/modules/cup-illustration/services/cup-illustration.service';
import {
  getAppUserByEmailSuccess,
  getAppUserByMail,
  getAppUserByUsername,
  getAppUserByUsernameSuccess,
} from '../../../../../../../../core/ngrx/actions';
import { GuestEvaluatorDto } from '../../../../../../../../core/domain/guest-evaluator-dto.model';

@Component({
  selector: 'guest-evaluator-form',
  templateUrl: './guest-evaluator.component.html',
  providers: [CupIllustrationService],
})
export class GuestEvaluatorComponent extends MasterChildComponent<GuestEvaluatorDto> implements OnInit, OnDestroy {
  protected guestEvaluatorsQueue: GuestEvaluatorDto[];
  private readonly guestEvaluatorSub$: Subscription;
  emailAvailable: boolean = true;
  usernameAvailable: boolean = true;

  @Output() onConfirmed: EventEmitter<GuestEvaluatorDto> = new EventEmitter<GuestEvaluatorDto>();

  constructor(
    protected store: Store<fromRoot.State>,
    protected validationService: MasterdataValidationService,
  ) {
    super(validationService, store);
    this.guestEvaluatorSub$ = this.guestEvalSubscription();
  }

  ngOnDestroy(): void {
    this.guestEvaluatorSub$.unsubscribe();
    this.store.dispatch(getAppUserByEmailSuccess({ user: null }));
    this.store.dispatch(getAppUserByUsernameSuccess({ user: null }));
  }

  ngOnInit(): void {
    this.onNew();
    this.onCleanValidations();
  }

  guestEvalSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getRetrievedUserByEmail),
      this.store.select(fromRoot.getRetrievedUserByUsername),
      this.store.select(fromRoot.getGuestEvaluatorsQueue),
    ]).subscribe(([byEmail, byUsername, guestEvaluatorQueue]) => {
      this.guestEvaluatorsQueue = guestEvaluatorQueue;
      // must be no stored and create local service for get info about guestEvaluators
      this.emailAvailable = isNullOrUndefined(byEmail) || byEmail.id <= 0;
      this.usernameAvailable = isNullOrUndefined(byUsername) || byUsername.id <= 0;
    });
  }

  public onUsernameChanged(username: string): void {
    // validate if username is already used
    const newUsername = username?.toLowerCase();
    if (!isEmpty(newUsername)) {
      this.usernameAvailable = !nullsafe(this.guestEvaluatorsQueue).some(x => x.username.toLowerCase() === newUsername);
      if (this.usernameAvailable) this.store.dispatch(getAppUserByUsername({ username: newUsername }));
    }
    this.onItemFieldChange(newUsername, 'username');
  }

  public onEmailChanged(email: string): void {
    // validate if email is already used
    const newEmail = email?.toLowerCase();
    if (!isEmpty(newEmail)) {
      // try to find registered emails and avoid duplicates
      this.emailAvailable = !nullsafe(this.guestEvaluatorsQueue).some(x => x.email.toLowerCase() === newEmail);
      if (this.emailAvailable) {
        this.store.dispatch(getAppUserByMail({ email: newEmail }));
      }
    }
    this.onItemFieldChange(newEmail, 'email');
  }

  public onCleanValidations(): void {
    this.emailAvailable = true;
    this.usernameAvailable = true;
  }

  public isValid(): boolean {
    let valid: boolean = this.usernameAvailable && this.emailAvailable;
    valid =
      valid &&
      !isNullOrUndefined(this.onGetCurrentChildItem().email) &&
      !isNullOrUndefined(this.onGetCurrentChildItem().username);
    this.validationResult = merge(
      this.validationResult,
      this.validationService.validateItem(this.onGetCurrentChildItem(), this.onGetRuleChildValidation()),
    );
    return valid && this.validationResult.valid;
  }

  public onGetCurrentGuestEvaluator(): GuestEvaluatorDto {
    return this.isValid() ? this.onGetCurrentChildItem() : null;
  }

  public onCancel(): void {
    return this.ngOnInit();
  }

  protected onNewChildItem(): GuestEvaluatorDto {
    return new GuestEvaluatorDto();
  }
  protected onGetRuleChildValidation(): Rule {
    return {
      name: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      lastName: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      code: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      email: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      username: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
      password: (v: Validation): void => {
        if (isEmpty(v.ctx)) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
    };
  }
  protected onHideParentToolbar(): void {}
  protected onAfterItemFieldChange(): void {
    if (isEmpty(this.onGetCurrentChildItem()?.password) && this.emailAvailable && this.usernameAvailable) {
      // create pass suggest
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const randomPwd: string = array[0]?.toString(36).slice(-8);
      const newChildItem = mergeImmutable(this.onGetCurrentChildItem(), {
        password: randomPwd,
      });
      this.onSetChildItem(newChildItem);
    }
  }
}
