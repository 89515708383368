<div [ngClass]="{ card: wrappedInCard, grid: toExpand }" class="ncs-grid">
  <div *ngIf="quickFilterEnabled" class="col-12 ncs-grid-filter">
    <label>
      <span class="ncs-searchbox">
        <i class="material-icons">search</i>
      </span>
      <input pInputText placeholder="{{ 'common.placeholder.search' | translate }}" (keyup)="onSearchKeyup($event)" />
    </label>
    <span
      class="tooltip hvr"
      *ngIf="tooltipSearchLabel != ''"
      style="display: flex; align-items: center; padding-left: 10px">
      <span class="fa fa-question-circle" style="vertical-align: baseline"></span>
      <span class="tooltiptext right info">{{ tooltipSearchLabel | translate }}</span>
    </span>
    <ng-content select="[generic-grid=search-btn]"></ng-content>
  </div>
  <div class="col-12">
    <ncs-grid [gridOptions]="gridOptions" [rowDataEmpty]="isRowDataEmpty">
      <ag-grid-angular
        class="ag-theme-fresh"
        [paginationPageSize]="25"
        [rowMultiSelectWithClick]="selectionMode === 'multiple'"
        [ngStyle]="agGridStyle"
        [frameworkComponents]="frameworkComponents"
        [columnDefs]="colDef"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [quickFilterText]="quickFilter"
        [rowSelection]="selectionMode"
        [getContextMenuItems]="getContextMenuItems"
        [allowContextMenuWithControlKey]="true"
        [autoGroupColumnDef]="autoGroupColumnDef"
        (selectionChanged)="onSelectionRow($event)"
        (rowClicked)="onRowClick($event)"
        (gridReady)="onGridReady($event)"
        (modelUpdated)="modelUpdated()"
        (rowDoubleClicked)="onRowDoubleClick($event)"
        (rowDragEnd)="onRowDragEnd($event)"
        (rowDragLeave)="onRowLeave()"
        (rowDragEnter)="onRowDragEnter($event)"
        (filterModified)="onSortOrFilterChanges()"
        (sortChanged)="onSortOrFilterChanges()">
      </ag-grid-angular>
    </ncs-grid>
  </div>
  <ng-content select="[generic-grid=footer]"></ng-content>
  <div
    class="col-12 flex justify-content-end"
    *ngIf="enabledFooter || paginationResponse"
    style="line-height: 23px; font-size: 14px">
    <ncs-pagination
      class="grid-pagination"
      [pagination]="paginationResponse"
      (onChangePage)="changePage($event)"></ncs-pagination>
  </div>
</div>
