import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';

/**
 * Generic application notification.
 *
 * This is used to display information about any user-relevant events occurring in the system.
 */
@Entity('notification', 'AppNotification')
export class AppNotification extends AbstractEntity {
  /**
   * The type of the notification, if someone wants to select/switch on those.
   */
  @Serialized type: string;

  /**
   * WARN or ERROR or INFO.
   */
  @Serialized level: string;

  /**
   * The date/time at which the notification was created on the server due to
   * an action someone performend (such as modifying a trade).
   */
  @Serialized createdAt: Date;

  /**
   * This is the I18N contractKey of the message, not the _actual_ message!
   * You need to use the isoCode contractKey 'notification.${message}' to get the actual parameterized message
   * and interpolate with param0, param1, param2 and/or param3, based on your message.
   */
  @Serialized message: string;

  /**
   * Generic parameters, described in the respective NotificationType enum for each notification type.
   * You need to supply those as parameters when translating the #message via the TranslateService.
   */
  @Serialized param0?: string;
  @Serialized param1?: string;
  @Serialized param2?: string;
  @Serialized param3?: string;
  @Serialized param4?: string;
  @Serialized param5?: string;

  /**
   * A unique notification identifier.
   */
  @Serialized uuid?: string;
}
