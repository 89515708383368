import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { NcsSelectBoxComponent } from './components/ncs-select-box.component';
import { NcsTooltipSharedModule } from '../tooltip/tooltip-shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, DropdownModule, FormsModule, NcsTooltipSharedModule],
  declarations: [NcsSelectBoxComponent],
  exports: [NcsSelectBoxComponent],
})
export class NcsSelectBoxSharedModule {}
