import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NcsBaseBasicComponent } from '../../../base-basic/base-basic.component';
import * as fromRoot from '../../../../../../core/ngrx/reducers';
import { isNullOrUndefined } from '../../../../../../modules/utils/object-utils';

import { TranslationIsoCode } from '../../../../models/translation-iso-code.model';
import { NcsTextTranslationComponent } from '../../text-translation/components/ncs-text-translation.component';

@Component({
  selector: 'ncs-text-basic',
  templateUrl: './ncs-text-basic.component.html',
})
export class NcsTextBasicComponent extends NcsBaseBasicComponent implements OnInit, OnDestroy {
  /**
   * Maximal number of characters that can be assigned to the field. Default is
   * chrome's max-len default value (2^19 = 512kb) - which should be high enough
   * @type {number}
   */
  @Input() maxCharacterLength: number = 200; // 524288
  @Input() showClearButton: boolean = true;
  /** maybe necessary on mobile version
   * When focus/active enable, value is selected */
  @Input() focusSelection: boolean = true;
  @Input() type: string;
  @Input() labelTranslation: string = null;
  @Input() currentItemForTranslation: any = null; // currentChildItem used if is child
  @Input() useCurrentItem: boolean = true; // valid if use input currentChildItem
  @Input() dialogOnModal: boolean = true; // Disable dialog displayed on modal in this component. Issue with two dialog modals displayed.
  @Output() onFocusIn: EventEmitter<any> = new EventEmitter();
  @Output() isSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() onSaveTranslation: EventEmitter<TranslationIsoCode> = new EventEmitter<TranslationIsoCode>();
  @Output() onSaveSuccessTranslation: EventEmitter<any> = new EventEmitter();
  @Output() onClickTranslate: EventEmitter<any> = new EventEmitter();

  @ViewChild('selectionTrigger') inputText: ElementRef;
  @ViewChild(NcsTextTranslationComponent)
  translationLanguageComponent: NcsTextTranslationComponent;

  visibleDialog: boolean = false;
  focusin: boolean = false; // input-text focus trigger
  disabledTranslation: boolean = false;

  private textBasicSubscription$: Subscription;

  constructor(protected store: Store<fromRoot.State>) {
    super();
  }

  ngOnInit(): void {
    if (this.labelTranslation) {
      this.textBasicSubscription$ = this.store
        .pipe(
          select(fromRoot.getMasterCurrentItem),
          filter(currentItem => !isNullOrUndefined(currentItem)),
        )
        .subscribe(currentItem => (this.disabledTranslation = !(currentItem && currentItem.id > 0)));
    }
  }

  ngOnDestroy(): void {
    if (this.labelTranslation) {
      this.textBasicSubscription$.unsubscribe();
    }
  }

  public onFocus(): void {
    this.focusin = true;
    if (this.focusSelection) {
      this.inputText.nativeElement.select();
    }
    this.onFocusIn.emit();
  }

  // dialog translation
  public onHideDialog(): void {
    this.visibleDialog = false;
    this.translationLanguageComponent.getCurrentItemByLanguage();
  }

  public onShowTranslationDialog(): void {
    if (this.value && this.value !== '') {
      this.visibleDialog = true;
      this.onClickTranslate.emit(true);
    }
  }

  public onClearValue(): void {
    this.value = '';
    this.onChanges(this.value);
  }
}
