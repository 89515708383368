export abstract class AbstractData {
  /**
   * Object.assign(this, source), but well typed, so the source must be a Partial of the call receiver (i.e. 'this').
   *
   * @param {Partial<this>} source an object which is compatible with 'this'
   * @returns {this}
   */
  assign(source: Partial<this>): this {
    return Object.assign(this, source);
  }
}
