<span
  class="error-wrapper tooltip"
  [ngClass]="{
    'invalid-input': !checkbox && isFieldInvalid(),
    'invalid-input-checkbox': checkbox && isFieldInvalid(),
    'only-warnings': isOnlyWarnings(),
    'error-icon': isOnlyErrors()
  }"
  [ngStyle]="ngStyle">
  <ng-content></ng-content>
  <span [ngClass]="{ 'only-warnings': isOnlyWarnings() }" (click)="cleanValidationResult()">
    <span class="tooltiptext bottom" *ngFor="let message of getValidationMessages()">
      {{ message.message | translate: message.messageParams }}
    </span>
  </span>
</span>
