import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { Permissions } from './permissions.model';

@Entity('authorization', 'Role', 'auth')
export class Role extends AbstractEntity {
  @Serialized name?: string;
  @Serialized code?: string;
  @Serialized({ elementType: Permissions }) permissions?: Permissions[] = [];
}
