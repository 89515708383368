import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import {
  fetchAddressType,
  fetchAddressTypeSuccess,
  fetchContactType,
  fetchContactTypeSuccess,
  fetchThirdParty,
  fetchThirdPartySuccess,
  fetchThirdPartyType,
  fetchThirdPartyTypeSuccess,
} from '../actions';
import { QueryService } from '../services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { ThirdPartyType } from '../../domain/third-party-type.model';
import { AddressType } from '../../domain/address-type.model';
import { ContactType } from '../../domain/contact-type.model';
import { ThirdParty } from '../../domain/third-party.model';

@Injectable()
export class ThirdPartyEffects {
  constructor(
    private readonly queryService: QueryService,
    private readonly actions$: Actions,
  ) {}

  public readonly fetchContactTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactType),
      switchMap(() => this.queryService.querySearch(ContactType)),
      map((contactTypes: ContactType[]) => fetchContactTypeSuccess({ contactTypes: contactTypes })),
    ),
  );

  public readonly fetchAddressTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAddressType),
      switchMap(() => this.queryService.querySearch(AddressType)),
      map((addressTypes: AddressType[]) => fetchAddressTypeSuccess({ addressTypes: addressTypes })),
    ),
  );

  public readonly fetchThirdPartyType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchThirdPartyType),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'thirdPartyType,ASC';
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.queryService.querySearch<ThirdPartyType[]>(ThirdPartyType, action.paginationRequestParams),
      ),
      map(response => fetchThirdPartyTypeSuccess({ thirdPartyType: response })),
    ),
  );

  public readonly fetchThirdParty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchThirdParty),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequestParams)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequestParams = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch<ThirdParty[]>(ThirdParty, action.paginationRequestParams)),
      map(response => fetchThirdPartySuccess({ thirdParty: response })),
    ),
  );
}
