import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { MetricsDefinitionOption } from './metrics-definition-option.model';
import { MetricsDefinitionType } from './metrics-definition-type.model';
import { MetricsDefinitionTypeResult } from './metrics-definition-type-result.model';

@Entity('masterdata', 'MetricsDefinition', 'qc')
export class MetricDefinition extends AbstractVersionedEntity {
  @Serialized code: string;
  @Serialized name: string;
  @Serialized metricsDefinitionType: MetricsDefinitionType;
  @Serialized metricsDefinitionTypeResult: MetricsDefinitionTypeResult;
  @Serialized optionEquivalence: boolean;
  @Serialized optionShowList: boolean;
  @Serialized optionMulSelect: boolean;
  @Serialized({
    elementType: MetricsDefinitionOption,
    composite: true,
  })
  metricsDefinitionOptions: MetricsDefinitionOption[] = [];
}
