import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { Country } from './country.model';

@Entity('administration', 'State', 'auth')
export class State extends AbstractVersionedEntity {
  @Serialized state?: string;
  @Serialized codeState?: string;
  @Serialized country: Country;
  @Internal __type: 'auth.ncs.administration.entity.State';
}
