<p-dialog
  class="magnifier-basic nkg-dialog-basic"
  [appendTo]="appendTo"
  [closable]="false"
  [closeOnEscape]="false"
  [draggable]="false"
  [visible]="visibleGrid"
  [style]="{ 'min-width': '630px', 'max-width': '820px', 'max-height': '660px' }"
  [blockScroll]="true"
  [modal]="true">
  <p-header *ngIf="!!titleGrid">
    <span class="nkg-dialog-basic-title">{{ titleGrid | translate }}</span>
  </p-header>
  <nkg-generic-grid
    #genericGrid
    class="magnifier-grid"
    [wrappedInCard]="false"
    [quickFilterEnabled]="filterEnable"
    [columnsType]="gridColumnType"
    [agGridStyle]="{ height: '250px' }"
    [gridOptions]="gridOptions"
    [colDef]="gridColumnDefinition"
    [rowData]="gridRowData"
    [tooltipSearchLabel]="'common.labels.search_fields'"
    [selectionMode]="selectionMode"
    [forceMultiselectMode]="true"
    [paginationResponse]="paginationResponse"
    (onChangePage)="onChangePage.emit($event)"
    (onQuickFilterSearch)="onFilterSearch($event)"
    (rowSelections)="getSelectedRowsGridEvent($event)"
    (rowDoubleClicked)="selectedRowsOk()">
  </nkg-generic-grid>

  <p-footer style="display: inline">
    <div class="grid col-12">
      <div class="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6" style="text-align: left">
        <span>{{ currentSelections.length }}&nbsp;{{ 'common.labels.selected' | translate }}</span>
      </div>
      <div class="col-12 xl:col-6 lg:col-6 md:col-6 sm:col-6">
        <ncs-button
          [label]="'common.buttons.cancel'"
          inputClass="nkg-secondary-btn small"
          (onClicked)="forceSelectionsLookup(false)">
        </ncs-button>
        <!-- should change with any changes of grid, no empty selections or not on it -->
        <ncs-button
          [disabled]="!(currentSelections.length > 0)"
          [label]="'common.buttons.ok'"
          inputClass="nkg-primary-btn"
          (onClicked)="selectedRowsOk()">
        </ncs-button>
      </div>
    </div>
  </p-footer>
</p-dialog>
