import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../../../../../../../core/ngrx/services/authorization.service';
import { TimeService } from '../../../../../../../core/ngrx/services/time-service';
import { QueryService } from '../../../../../../../core/ngrx/services/query.service';
import { AbstractCeisService } from '../../../../../../../core/ngrx/services/app.abstract.service';
import { Constants } from '../../../../../../../core/utils/app.constants';
import { CupIllustration } from '../../../../../../../core/domain/cup-illustration.model';

@Injectable()
export class CupIllustrationService extends AbstractCeisService {
  private readonly qcUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private readonly authService: AuthorizationService,
    timeService: TimeService,
    private readonly queryService: QueryService,
  ) {
    super(http, configuration, timeService);
    this.qcUrl = configuration.SERVER_WITH_QC_API_URL;
  }

  /**
   * Search cup images by usedByAll property or tenants assigned
   * @param cup is cup number provided
   */
  async onGetAllCupIllustrationAndTenantsAssigned(cup: number): Promise<CupIllustration[]> {
    const params = { _cup: cup, _include: '*' };
    const res = await this.getWithParams(`${this.qcUrl}cup_illustration/all-tenants/`, params, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  /**
   * Search cup images by cup number and usedByAll property or tenants assigned
   * @param cup is cup number provided
   * @param getAll is usedByAll property defined into CupIllustration
   */
  async onGetWithTenants(cup: number, getAll: boolean = true): Promise<CupIllustration[]> {
    // QC-811: Removed tenants into search queries
    // The new Logic assumes that getAll must always be true
    const params = { _cup: cup, _all: getAll, _include: '*' };
    const res = await this.getWithParams(`${this.qcUrl}cup_illustration/by-tenant/`, params, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }

  async onGetCupIllustrationImageById(cupIllustrationId: number): Promise<CupIllustration> {
    const params = { _include: '*' };
    const res = await this.getWithParams(`${this.qcUrl}cup_illustration/${cupIllustrationId}`, params, true, () =>
      this.authService.renewToken(),
    );
    return this.queryService.fromServer(res);
  }
}
