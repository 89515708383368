import { AbstractData } from './abstract-data.model';
import { Internal, Serialized } from '../schema/decorators';

import { ExclusionInfo } from './exclusion-info.model';

/**
 * Sequence for client-side ids. Will start with -1 and decrement by 1 each time.
 */
let nextClientId: number = -1;

/**
 * Generate a new client-side id.
 */
export function newId(): number {
  return nextClientId--;
}

export abstract class AbstractEntity extends AbstractData {
  @Serialized id?: number;
  @Internal __type?: string;
  @Internal records?: string;
  @Serialized({ elementType: ExclusionInfo })
  __filteredProperties?: ExclusionInfo[];
  @Serialized readonly createdAt?: Date;
  @Serialized readonly createdBy?: string;
  @Serialized readonly lastUpdated?: string;
  @Serialized lang: string;
  @Serialized deleted?: boolean = false;

  /**
   * Create a new entity with a client-generated id.
   */
  constructor();
  /**
   * Create a new entity with the given id.
   *
   * @param {number} id the id of the entity to create
   */
  constructor(id: number);
  /**
   * Create a new entity with the same id as the given entity.
   *
   * @param {AbstractEntity} entity the entity whose id to use for this entity
   */
  constructor(entity: AbstractEntity);
  /**
   * Constructor implementation.
   *
   * @param {number | AbstractEntity} idOrEntity either undefined, or an id or an entity with an id
   */
  constructor(idOrEntity?: number | AbstractEntity) {
    super();
    /*
     * If an id was given as argument, use that. This is the case for entities received from the server, as those
     * will ALWAYS have an id greater than 0. See the createNew() call in query.service.ts.
     *
     * Client-generated instances will never be given an explicit id, and for those we generate a new id using
     * newId(), which just decrements a global sequence.
     */
    if (typeof idOrEntity === 'number') {
      this.id = idOrEntity;
    } else if (idOrEntity !== undefined) {
      this.id = idOrEntity.id;
    } else {
      this.id = newId();
    }
  }
}
