import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ObjectPath } from '../../../modules/utils/object-utils';
import { CustomGridView } from '../../../shared/grid/models/enums';
import { GridView } from '../../domain/grid-view.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { EmailAlert } from '../../domain/email-alert.model';
import { EmailConfirmation } from '../../domain/email-confirmation.model';

export enum MasterActionType {
  FETCH_DATA = '[Master] Fetch Data',
  FETCH_DATA_SUCCESS = '[Master] Fetch Data Success',
  FETCH_TRANSLATIONS = '[Master] Fetch Translations',
  FETCH_TRANSLATIONS_SUCCESS = '[Master] Fetch Translations Success',
  FETCH_DATA_ERROR = '[Master] Fetch Data Error',
  SAVE_DATA = '[Master] Save Data',
  SAVE_DATA_SUCCESS = '[Master] Save Data Success',
  SAVE_DATA_TRANSLATION_SUCCESS = '[Master] Save Data Translation Success',
  CHANGE_ITEM_MASTER = '[Master] Change Item Master',
  CHANGE_PAGINATION_MASTER = '[Master] Change Pagination Master',
  SWITCH_EDIT_MASTER_MODE = '[Master] Switch Edit Master Mode',
  SWITCH_NEW_MASTER_MODE = '[Master] Switch New Master Mode',
  SWITCH_SAVE_MASTER_MODE = '[Master] Switch Save Master Mode',
  SWITCH_DISCARD_MASTER_MODE = '[Master] Switch Discard Master Mode',
  SWITCH_RETIRED_MASTER_MODE = '[Master] Switch Retired Master Mode',
  SELECT_MASTER = '[Master] Select Master',
  SWITCH_MODIFY_MASTER_MODE = '[Master] Modify Current Item Master',
  RESET_MASTER_STATE = '[Master] Reset Master State',
  EMAIL_ALERT = '[Master] Email Alert',
  EMAIL_ALERT_SUCCESS = '[Master] Email Alert Success',
  FETCH_GRID_VIEWS = '[Master] Fetch Grid Views',
  FETCH_GRID_VIEWS_SUCCESS = '[Master] Fetch Grid Views Success',
  SET_CURRENT_GRID_VIEW = '[Master] Set Current Grid View',
  SET_CURRENT_GRID_VIEW_SUCCESS = '[Master] Set Current Grid View Success',
  SAVE_GRID_VIEW = '[Master] Save Grid View',
  GET_VERSIONING_TOAST_NOTIFICATION = '[Master] Show versioning notification error message',
}

export const resetMasterData = createAction(MasterActionType.RESET_MASTER_STATE);

export const fetchDataMaster = createAction(
  MasterActionType.FETCH_DATA,
  props<{
    typeFunction: Function;
    paginationRequestParams: PaginationRequestParams;
    columns?: ObjectPath;
    include?: ObjectPath;
    serviceUrl?: string;
    currentId?: number;
    useTranslations?: boolean;
  }>(),
);

export const fetchDataMasterSuccess = createAction(
  MasterActionType.FETCH_DATA_SUCCESS,
  props<{ paginationResponse: PaginatedResponse }>(),
);

export const fetchTranslations = createAction(
  MasterActionType.FETCH_TRANSLATIONS,
  props<{ typeFunction: Function; currentId: number }>(),
);

export const fetchTranslationsSuccess = createAction(
  MasterActionType.FETCH_TRANSLATIONS_SUCCESS,
  props<{ translations: [] }>(),
);

export const errorResponse = createAction(
  MasterActionType.FETCH_DATA_ERROR,
  props<{ _httpErrorResponse: HttpErrorResponse }>(),
);

export const saveMasterData = createAction(
  MasterActionType.SAVE_DATA,
  props<{
    typeFunction: Function;
    data: any;
    resolve?: any;
    redirectUrl?: string;
    paginationRequestParams?: PaginationRequestParams;
    dynamicUrl?: string;
    isoCode?: string;
  }>(),
);

export const saveMasterDataSuccess = createAction(
  MasterActionType.SAVE_DATA_SUCCESS,
  props<{
    typeFunction: Function;
    data: any;
    redirectUrl: string;
    paginationRequestParams: PaginationRequestParams;
    dynamicUrl?: string;
    isoCode?: string;
  }>(),
);

export const saveMasterDataTranslationSuccess = createAction(
  MasterActionType.SAVE_DATA_TRANSLATION_SUCCESS,
  props<{
    typeFunction: Function;
    data: any;
    redirectUrl: string;
    isoCode?: string;
  }>(),
);

export const setMasterDataSelectedItem = createAction(
  MasterActionType.CHANGE_ITEM_MASTER,
  props<{ selectedItem: any }>(),
);

export const setMasterDataPagination = createAction(
  MasterActionType.CHANGE_PAGINATION_MASTER,
  props<{ paginationRequestParams: PaginationRequestParams }>(),
);

export const setMasterDataReference = createAction(
  MasterActionType.SELECT_MASTER,
  props<{ masterNameReference: string }>(),
);

export const setMasterDataRetiredMode = createAction(
  MasterActionType.SWITCH_RETIRED_MASTER_MODE,
  props<{ showRetiredItems: boolean }>(),
);

export const setMasterDataSaveMode = createAction(
  MasterActionType.SWITCH_SAVE_MASTER_MODE,
  props<{ isSaved: boolean }>(),
);

export const setMasterDataDiscardMode = createAction(
  MasterActionType.SWITCH_DISCARD_MASTER_MODE,
  props<{ isDiscard: any }>(),
);

export const setMasterEditMode = createAction(
  MasterActionType.SWITCH_EDIT_MASTER_MODE,
  props<{ isEditMode: boolean }>(),
);

export const setMasterDataNewMode = createAction(
  MasterActionType.SWITCH_NEW_MASTER_MODE,
  props<{ isNewMode: boolean }>(),
);

export const setMasterModifyMode = createAction(
  MasterActionType.SWITCH_MODIFY_MASTER_MODE,
  props<{ isModifyMode: boolean }>(),
);

export const emailAlert = createAction(
  MasterActionType.EMAIL_ALERT,
  props<{ typeFunction: Function; emailAlert: EmailAlert; redirectUrl: string }>(),
);

export const emailAlertSuccess = createAction(
  MasterActionType.EMAIL_ALERT_SUCCESS,
  props<{ typeFunction: Function; data: EmailConfirmation; redirectUrl: string }>(),
);

export const setGridView = createAction(
  MasterActionType.SET_CURRENT_GRID_VIEW,
  props<{
    gridView: CustomGridView;
    /** used to compare Boolean values of master-grid */
    yesTranslated: string;
    /** Id valid from backend and replace with old id */
    id?: number;
  }>(),
);

export const setGridViewSuccess = createAction(
  MasterActionType.SET_CURRENT_GRID_VIEW_SUCCESS,
  props<{ gridView: CustomGridView }>(),
);

export const fetchGridViews = createAction(
  MasterActionType.FETCH_GRID_VIEWS,
  props<{ gridViewReference: string; initialGridView?: GridView; paginationRequest?: PaginationRequestParams }>(),
);

export const fetchGridViewsSuccess = createAction(
  MasterActionType.FETCH_GRID_VIEWS_SUCCESS,
  props<{ gridViews: GridView[] }>(),
);

export const saveGridView = createAction(
  MasterActionType.SAVE_GRID_VIEW,
  props<{ gridView: GridView; gridViewReference: string; forceRefresh?: boolean }>(),
);

export const errorDataVersioningNotification = createAction(
  MasterActionType.GET_VERSIONING_TOAST_NOTIFICATION,
  props<{ errorDataVersioningVisible: boolean }>(),
);
