import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RowAction } from '../models/enums';

@Component({
  selector: 'action-renderer',
  template: `
    <span *ngFor="let action of actions; let idx = index">
      <i
        *ngIf="!values || values[idx]"
        style="cursor: pointer; "
        [attr.data-action-type]="action.type"
        class="material-icons">
        {{ action.icon }}
      </i>
    </span>
  `,
})
export class ActionRendererComponent implements AgRendererComponent {
  // actions known for the row, defined via cellRendererParams
  actions: RowAction[] = [];
  // currently available actions, defined via valueGetter
  values: boolean[];

  agInit(params: ICellRendererParams): void {
    if (params?.colDef?.cellRendererParams?.actions) {
      this.actions = params.colDef.cellRendererParams.actions;
    }
    this.values = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.values = params.value;
    return true;
  }
}
