import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { MetricsDefinitionOption } from './metrics-definition-option.model';

@Entity('masterdata', 'StandardsDefinitionEquivalences', 'qc')
export class StandardsDefinitionEquivalences extends AbstractVersionedEntity {
  @Serialized metricsDefinitionOption: MetricsDefinitionOption;
  @Serialized equivalence: number;
  @Serialized required: boolean;
  @Serialized position: number;
  @Internal parentId: number;
}
