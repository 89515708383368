import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { getAppState } from '../reducers';
import { AppState } from '../reducers/app.reducer';

import { AppTimezoneInfo } from '../../domain/app-timezone-info.model';

@Injectable()
export class AppSelector {
  private readonly appFeatureSelector: MemoizedSelector<object, AppState>;

  public constructor() {
    this.appFeatureSelector = getAppState;
  }

  public get getTimezone(): MemoizedSelector<object, AppTimezoneInfo> {
    return createSelector(this.appFeatureSelector, state => state.timezone);
  }
}
