import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.action';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { User } from '../../domain/user.model';

export interface AuthState {
  isAuthenticated: boolean;
  // Name is what we passed in "Actions" as payload, thus it can be a model if needed
  username: string;
  // This is used for showing usage when login is failed
  errorMessage: any;
  // current user logged
  currentUser: User;
  // Menu for async petition
  nkgMenu: any;
  // user retrieved by email
  userRetrievedByEmail: User;
  // user retrieved by username
  userRetrievedByUsername: User;
  // Display content only
  displayContentOnly: boolean;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  username: null,
  errorMessage: null,
  currentUser: null,
  nkgMenu: JSON.parse(localStorage.getItem('__menu__')),
  userRetrievedByUsername: null,
  userRetrievedByEmail: null,
  displayContentOnly: false,
};

export const authReducer = createReducer(
  initialAuthState,
  on(
    AuthActions.appLogin,
    (state: AuthState, { credentials }): AuthState => ({
      ...state,
      username: credentials.username,
      errorMessage: null,
    }),
  ),
  on(
    AuthActions.loginSuccess,
    (state: AuthState, { username }): AuthState => ({
      ...state,
      username: username,
      isAuthenticated: true,
      errorMessage: null,
    }),
  ),
  on(
    AuthActions.loginFailure,
    (state: AuthState, { errorMessage }): AuthState => ({
      ...state,
      errorMessage: errorMessage,
      isAuthenticated: false,
    }),
  ),
  on(
    AuthActions.setAppUser,
    (state: AuthState, { user, forceAuthentication }): AuthState => ({
      ...state,
      currentUser: user,
      isAuthenticated: forceAuthentication || (state.isAuthenticated && !isNullOrUndefined(user)),
    }),
  ),
  on(AuthActions.getAppMenuSuccess, (state: AuthState, { menus }): AuthState => {
    const menuItems = [...menus].sort((a, b) => a.position - b.position);
    window.localStorage.setItem('__menu__', JSON.stringify(menuItems));
    return {
      ...state,
      nkgMenu: menuItems,
    };
  }),
  on(
    AuthActions.logout,
    (state: AuthState): AuthState => ({
      ...state,
      isAuthenticated: false,
      username: null,
      nkgMenu: null,
    }),
  ),
  on(
    AuthActions.resetAuthState,
    (): AuthState => ({
      ...initialAuthState,
    }),
  ),
  on(
    AuthActions.getAppUserByUsernameSuccess,
    (state: AuthState, { user }): AuthState => ({
      ...state,
      userRetrievedByUsername: user,
    }),
  ),
  on(
    AuthActions.getAppUserByEmailSuccess,
    (state: AuthState, { user }): AuthState => ({
      ...state,
      userRetrievedByEmail: user,
    }),
  ),
  on(
    AuthActions.authDisplayContentOnly,
    (state: AuthState, { onlyContent }): AuthState => ({
      ...state,
      displayContentOnly: onlyContent,
    }),
  ),
);

export const getIsAuthenticate = (state: AuthState): boolean => state.isAuthenticated;
export const getCurrentUser = (state: AuthState): User => state.currentUser;
export const getMenu = (state: AuthState): any => state.nkgMenu;
export const getUserByUsername = (state: AuthState): User => state.userRetrievedByUsername;
export const getUserByEmail = (state: AuthState): User => state.userRetrievedByEmail;
export const getIsAppContentOnly = (state: AuthState): boolean => state.displayContentOnly;
