import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { CupIllustration } from './cup-illustration.model';
import { StandardDefinitionOption } from './standard-definition-option.model';
import { StandardsDefinitionTenants } from './standards-definition-tenants.model';

@Entity('masterdata', 'StandardDefinition', 'qc')
export class StandardDefinition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized scoreFrom: number;
  @Serialized scoreTo: number;
  @Serialized international?: boolean;
  @Serialized usedByAll?: boolean;
  @Serialized step: number;
  @Serialized totalCups: number;
  @Serialized doAverage: boolean;
  @Serialized({
    elementType: StandardDefinitionOption,
    composite: true,
  })
  standardDefinitionOptions: StandardDefinitionOption[] = [];
  @Serialized({
    elementType: StandardDefinitionOption,
  })
  standardDefinitionOptionsPhysical: StandardDefinitionOption[] = [];
  @Serialized({
    elementType: StandardDefinitionOption,
  })
  standardDefinitionOptionsSensorial: StandardDefinitionOption[] = [];
  @Serialized({
    elementType: StandardsDefinitionTenants,
    composite: true,
  })
  standardUsedBy: StandardsDefinitionTenants[] = [];
  @Serialized cupIllustration: CupIllustration;
}
