import { createReducer, on } from '@ngrx/store';
import * as DispatchStatusActionTypes from '../actions/dispatch.action';
import { ApprovalStatus } from '../../domain/approval-status.model';
import { SampleDispatchStatus } from '../../domain/sample-dispatch-status.model';

export interface DispatchStatusState {
  approvalStatuses: ApprovalStatus[];
  dispatchStatuses: SampleDispatchStatus[];
}

export const initialDispatchStatusState: DispatchStatusState = {
  approvalStatuses: null,
  dispatchStatuses: null,
};

export const dispatchReducer = createReducer(
  initialDispatchStatusState,
  on(
    DispatchStatusActionTypes.fetchApprovalStatusSuccess,
    (state: DispatchStatusState, { approvalStatuses }): DispatchStatusState => ({
      ...state,
      approvalStatuses: approvalStatuses,
    }),
  ),
  on(
    DispatchStatusActionTypes.fetchDispatchStatusSuccess,
    (state: DispatchStatusState, { sampleDispatchStatuses }): DispatchStatusState => ({
      ...state,
      dispatchStatuses: sampleDispatchStatuses,
    }),
  ),
);

export const getApprovalStatuses = (state: DispatchStatusState): ApprovalStatus[] => state.approvalStatuses;
export const getDispatchStatuses = (state: DispatchStatusState): SampleDispatchStatus[] => state.dispatchStatuses;
