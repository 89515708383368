import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeService } from './time-service';
import { AbstractCeisService } from './app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { AppTimezoneInfo } from '../../domain/app-timezone-info.model';

@Injectable()
export class AppMetaDataService extends AbstractCeisService {
  constructor(
    protected http: HttpClient,
    protected configuration: Constants,
    protected timeService: TimeService,
  ) {
    super(http, configuration, timeService);
  }

  /** m
   * Loads the timezone that is configured to be in sync with the server.
   */
  public loadTimezone(): Promise<AppTimezoneInfo> {
    const appTimeZone: AppTimezoneInfo = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return Promise.resolve(appTimeZone);
  }
}
