/**
 * Define grid views name references for all master
 * and master grid details and separate for const MasterGridDetails
 */
export enum GridViewNameReference {
  BASE_MASTER_DATA_FORM = 'ncs.masterdata.grid.base_master',
  LANGUAGE_MASTER_DATA = 'ncs.masterdata.grid.language',
  COUNTRY_MASTER_DATA = 'ncs.masterdata.grid.country',
  REGION_MASTER_DATA = 'ncs.masterdata.grid.region',
  STATE_MASTER_DATA = 'ncs.masterdata.grid.state',
  HARVEST_METHOD_MASTER_DATA = 'ncs.masterdata.grid.harvest_method',
  SOIL_TYPE_MASTER_DATA = 'ncs.masterdata.grid.soil_type',
  CONTACT_TYPE_MASTER_DATA = 'ncs.masterdata.grid.contact_type',
  COURIER_COMPANY_MASTER_DATA = 'ncs.masterdata.grid.courier_company',
  APPROVAL_STATUS_MASTER_DATA = 'ncs.masterdata.grid.approval_status',
  SAMPLE_STATUS_MASTER_DATA = 'ncs.masterdata.grid.sample_status',
  USERS_MASTER_DATA = 'ncs.masterdata.grid.user',
  ROLES_MASTER_DATA = 'ncs.masterdata.grid.role',
  SAMPLE_TYPES_MASTER_DATA = 'ncs.masterdata.grid.sample_type',
  TENANT_MASTER_DATA = 'ncs.masterdata.grid.tenant',
  AGENCY_MASTER_DATA = 'ncs.masterdata.grid.agency',
  WAREHOUSE_MASTER_DATA = 'ncs.masterdata.grid.warehouse',
  COFFEE_SPECIE_MASTER_DATA = 'ncs.masterdata.grid.coffee_specie',
  COFFEE_VARIETY_MASTER_DATA = 'ncs.masterdata.grid.coffee_variety',
  DRYING_PROCESS_MASTER_DATA = 'ncs.masterdata.grid.drying_process',
  MILLING_PROCESS_MASTER_DATA = 'ncs.masterdata.grid.milling_process',
  STAGE_PROCESSING_MASTER_DATA = 'ncs.masterdata.grid.stage_processing',
  THIRD_PARTY_TYPE_MASTER_DATA = 'ncs.masterdata.grid.third_party_type',
  COFFEE_PROGRAM_MASTER_DATA = 'ncs.masterdata.grid.coffee_program',
  CUP_EVALUATOR_MASTER_DATA = 'ncs.masterdata.grid.cup_evaluator',
  STANDARD_DEFINITION_MASTER_DATA = 'ncs.masterdata.grid.standard_definition',
  METRIC_DEFINITION_MASTER_DATA = 'ncs.masterdata.grid.metric_definition',
  METRIC_GROUP_MASTER_DATA = 'ncs.masterdata.grid.metrics_group',
  METRIC_TYPE_MASTER_DATA = 'ncs.masterdata.grid.metrics_type',
  METRIC_TYPE_RESULT_MASTER_DATA = 'ncs.masterdata.grid.metrics_type_result',
  QUALITY_MASTER_DATA = 'ncs.masterdata.grid.quality',
  QUALITY_TYPE_MASTER_DATA = 'ncs.masterdata.grid.quality_type',
  FACT_SHEET_MASTER_DATA = 'ncs.masterdata.grid.fact_sheet',
  ITEM_DEFINITION_MASTER_DATA = 'ncs.masterdata.grid.item_definition',
  CHECK_LIST_DEFINITION_MASTER_DATA = 'ncs.masterdata.grid.check_list_definition',
  THIRD_PARTY_MASTER_DATA = 'ncs.masterdata.grid.third_party',
  ADDRESS_TYPE_MASTER_DATA = 'ncs.masterdata.grid.address_type',
  SAMPLE_MASTER_DATA = 'ncs.masterdata.grid.sample',
  CERTIFICATION_MASTER_DATA = 'masterdata.grid.certification',
  PACKAGE_TYPE_MASTER_DATA = 'masterdata.grid.package_type',
  LABORATORY_MASTER_DATA = 'masterdata.grid.laboratory',
  CUPPING_SESSION_STATUS_MASTER_DATA = 'masterdata.grid.cupping_session_status',
  SAMPLE_DISPATCH_STATUS_MASTER_DATA = 'masterdata.grid.sample_dispatch_status',
  REFERENCE_TYPE_MASTER_DATA = 'masterdata.grid.reference_type',
  CUPPING_SESSION_MASTER_DATA = 'masterdata.grid.cupping_session',
  CUPPING_PROCESS_MASTER_DATA = 'ncs.masterdata.grid.cupping_process',
  DISPATCH_MASTER_DATA = 'ncs.masterdata.grid.dispatch',
  DISPATCH_MASTER_DATA_DETAILS = 'ncs.masterdata.grid.dispatch.details',
  SAMPLE_APPROVAL_INTERNAL_CONDITION_MASTER_DATE = 'ncs.masterdata.grid.sample_approval_internal_condition',
  COFFEE_CUP_ILLUSTRATION = 'ncs.masterdata.grid.coffee_cup_illustration',
  TASTING_NOTE_MASTER_DATA = 'ncs.masterdata.grid.tasting_note',
  STORE_TYPE_MASTER_DATA = 'ncs.masterdata.grid.store_type',
  STORE_MASTER_DATA = 'ncs.masterdata.grid.store',
  QUALITY_ITEM_MASTER_DATA = 'ncs.masterdata.grid.quality_item',
  QUALITY_MAIN_MASTER_DATA = 'ncs.masterdata.grid.quality_main',
  QUALITY_ADDITIONAL_BRANDING_MASTER_DATA = 'ncs.masterdata.grid.quality_additional_branding',
}
