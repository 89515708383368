import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { NcsCheckboxBasicComponent } from './component/checkbox-basic.component';
import { NcsTooltipSharedModule } from '../tooltip/tooltip-shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, CheckboxModule, FormsModule, NcsTooltipSharedModule],
  declarations: [NcsCheckboxBasicComponent],
  exports: [NcsCheckboxBasicComponent],
})
export class NcsCheckboxBasicSharedModule {}
