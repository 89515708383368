<div class="col-12 flex flex-wrap grid-view">
  <div class="flex-grow-1">
    <ncs-selectbox
      #boxComponent
      [inputStyle]="{ color: '#757575', width: '100%' }"
      [dataKey]="'name'"
      [isEmptyAllowed]="false"
      [options]="customGridViewsOptions"
      [fieldReadOnly]="'name'"
      (onSelect)="onGridViewChanged($event)">
    </ncs-selectbox>
  </div>
  <div class="flex-none">
    <i
      title="{{ 'grid.labels.more' | translate }}"
      style="cursor: pointer; vertical-align: middle"
      class="material-icons grid-setting"
      (click)="showCustomizeOverlay = true">
      settings
    </i>
  </div>
  <div class="flex-none">
    <ncs-button
      inputClass="nkg-secondary-btn"
      [label]="'common.buttons.save'"
      [inputStyle]="{
        'font-size': '12px',
        'margin-left': '10px'
      }"
      (onClicked)="openSaveConfirmDialog()"></ncs-button>
  </div>
</div>
<ncs-customize-table-overlay
  [visible]="showCustomizeOverlay"
  [allColumns]="gridOptions?.columnApi?.getAllColumns()"
  [currentColumns]="gridOptions?.columnApi?.getColumnState()"
  [defaultVisibility]="defaultVisibility"
  [disabledColumns]="disabledColumns"
  (cancel)="showCustomizeOverlay = false"
  (apply)="onApplyColumnVisibility($event)">
</ncs-customize-table-overlay>
<p-dialog
  styleClass="layout-wrapper layout-compact grid-view-dialog"
  [modal]="true"
  [(visible)]="showSaveConfirmDialog"
  [closable]="false"
  [style]="{ 'text-align': 'center', 'min-width': '400px' }">
  <p-header>
    <ng-container *ngIf="isViewNameValid(); then saveTitle; else invalidInfo"></ng-container>
    <ng-template #saveTitle>
      <span>{{ 'masterdata.list.labels.save_view' | translate }}</span>
    </ng-template>
    <ng-template #invalidInfo>
      <span style="display: block">
        <span class="fa fa-exclamation-circle" style="color: #ffcc00"></span>
        <span class="tooltiptext info" style="font-style: italic">{{
          'common.labels.invalid_only_character' | translate
        }}</span>
      </span>
    </ng-template>
  </p-header>
  <div class="grid-view-name ncs-basic-wrapper">
    <input
      class="grid-view-input-name"
      #gridViewTextBox
      pInputText
      *ngIf="showSaveConfirmDialog"
      (click)="gridViewTextBox.select()"
      [placeholder]="'common.labels.name' | translate"
      [ngModel]="gridViewName" />
  </div>
  <p-footer style="display: inline-block">
    <ncs-button
      [label]="'common.buttons.cancel'"
      inputClass="nkg-secondary-btn"
      (onClicked)="onCancelSaveView()"></ncs-button>
    <ncs-button
      [disabled]="!isViewNameValid()"
      [label]="'common.buttons.save'"
      inputClass="nkg-primary-btn"
      (onClicked)="saveViewDialogConfirmed()"></ncs-button>
  </p-footer>
</p-dialog>
