import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';

@Entity('masterdata', 'CoffeeCertification', 'qc')
export class CoffeeCertification extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized organic: boolean;
  @Serialized transactionCertificate: boolean;
}
