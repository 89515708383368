import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { StageProcessing } from './stage-processing.model';
import { CoffeeSpecie } from './coffee-specie.model';

@Entity('masterdata', 'QualityItem', 'qc')
export class QualityItem extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized stageProcessing: StageProcessing;
  @Serialized coffeeSpecie: CoffeeSpecie;
}
