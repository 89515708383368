import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { Column } from 'ag-grid-community';
import { TooltipMessage } from '../../basic-shared-module/components/tooltip/models/tooltip-message.model';

export enum KeyboardKeys {
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  HOME = 36,
  END = 35,
  ESC = 27,
}

export interface TooltipAttribute {
  messages: TooltipMessage[] | TooltipMessage | string[] | string;
  severity: string;
}

export interface CustomGridView {
  id: number;
  name: string;
  isCustomView: boolean;
  isSaved?: boolean;
  viewsDefaultColumnState: ColumnState[]; // WARN: conflict with grid rendering content
  filter: any;
  sorting: any;
}

export interface RowAction {
  type: string;
  icon: string;
}

export interface CheckBoxData {
  editable?: boolean;
  value: boolean;
  label: string;
}

export interface ColumnVisibility {
  column: Column;
  visible: boolean;
}

export interface DefaultColumnVisibility {
  [colId: string]: boolean;
}

export interface DisabledGridViewColumns {
  [colId: string]: { disabled: boolean; checked: boolean };
}
