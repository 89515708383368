import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
/**
 * storeFreeze prevents the state from being mutated. When a mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all the exports into a single object.
 */
import * as fromMaster from './master.reducer';
import * as fromApp from './app.reducer';
import * as fromUser from './user.reducer';
import * as fromAuth from './auth.reducer';
import * as fromMetric from './metric.reducer';
import * as fromThirdParty from './third-party.reducer';
import * as fromQuality from './quality.reducer';
import * as fromItemDefinition from './item-definition.reducer';
import * as fromStandardDefinition from './standard-definition.reducer';
import * as fromSample from './sample.reducer';
import * as fromCuppingSession from './cupping-session.reducer';
import * as fromCuppingProcess from './cupping-process.reducer';
import * as fromDispatch from './dispatch.reducer';
import * as fromGuest from './approval.reducer';
import { AuthActionTypes } from '../actions';
import { environment } from '../../../../environments/environment';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */

export interface State {
  app: fromApp.AppState;
  master: fromMaster.MasterState;
  user: fromUser.UserState;
  auth: fromAuth.AuthState;
  router: fromRouter.RouterReducerState;
  metrics: fromMetric.MetricState;
  thirdParty: fromThirdParty.ThirdPartyState;
  quality: fromQuality.QualityState;
  itemDefinition: fromItemDefinition.ItemDefinitionState;
  standardDefinition: fromStandardDefinition.StandardDefinitionState;
  sample: fromSample.SampleState;
  cuppingSession: fromCuppingSession.CuppingSessionState;
  cuppingProcess: fromCuppingProcess.CuppingProcessState;
  dispatch: fromDispatch.DispatchStatusState;
  guest: fromGuest.ApprovalState;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const appReducers: ActionReducerMap<State> = {
  master: fromMaster.masterDataReducer,
  app: fromApp.appStateReducer,
  user: fromUser.userReducer,
  auth: fromAuth.authReducer,
  router: fromRouter.routerReducer,
  metrics: fromMetric.metricDefinitionReducer,
  thirdParty: fromThirdParty.thirdPartyReducer,
  quality: fromQuality.qualityReducer,
  itemDefinition: fromItemDefinition.itemDefinitionReducer,
  standardDefinition: fromStandardDefinition.standardDefinitionReducer,
  sample: fromSample.sampleReducer,
  cuppingSession: fromCuppingSession.cuppingSessionReducer,
  cuppingProcess: fromCuppingProcess.cuppingProcessReducer,
  dispatch: fromDispatch.dispatchReducer,
  guest: fromGuest.approvalReducer,
};

/**
 *
 * When we use ngrx/store in our app we need to clear the store on logout
 *
 */
export function clearState(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: Action) {
    if (action.type === AuthActionTypes.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();
    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger, clearState] : [clearState];

/**
 * Every reducer module exports selector functions, however child appReducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */

export const getMasterState = createFeatureSelector<fromMaster.MasterState>('master');
export const getAppState = createFeatureSelector<fromApp.AppState>('app');
export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getUserState = createFeatureSelector<fromUser.UserState>('user');
export const getMetricState = createFeatureSelector<fromMetric.MetricState>('metrics');
export const getThirdPartyState = createFeatureSelector<fromThirdParty.ThirdPartyState>('thirdParty');
export const getQualityState = createFeatureSelector<fromQuality.QualityState>('quality');
export const getItemDefinitionState = createFeatureSelector<fromItemDefinition.ItemDefinitionState>('itemDefinition');
export const getStandardDefinitionState =
  createFeatureSelector<fromStandardDefinition.StandardDefinitionState>('standardDefinition');
export const getSampleState = createFeatureSelector<fromSample.SampleState>('sample');
export const getCuppingSessionState = createFeatureSelector<fromCuppingSession.CuppingSessionState>('cuppingSession');
export const getCuppingProcessState = createFeatureSelector<fromCuppingProcess.CuppingProcessState>('cuppingProcess');
export const getDispatch = createFeatureSelector<fromDispatch.DispatchStatusState>('dispatch');
export const getApprovalState = createFeatureSelector<fromGuest.ApprovalState>('guest');

export const getCurrentViewNavigation = createSelector(getAppState, fromApp.getCurrentViewNavigation);

export const getErrorText = createSelector(getAppState, fromApp.getErrorText);

export const getSuccessText = createSelector(getAppState, fromApp.getSuccessText);

export const getGenericSearchItems = createSelector(getAppState, fromApp.getGenericSearchItems);

export const getListLanguages = createSelector(getAppState, fromApp.getLanguages);

export const getListCountries = createSelector(getAppState, fromApp.getCountries);

export const getAppTenants = createSelector(getAppState, fromApp.getTenants);

export const getTimezone = createSelector(getAppState, fromApp.getTimezone);

export const getNotifications = createSelector(getAppState, fromApp.getNotifications);

export const getIsResponsePending = createSelector(getAppState, fromApp.getIsResponsePending);

export const getComtrasInstances = createSelector(getAppState, fromApp.getCOMTRASInstances);

export const getTenantByComtrasPrefix = createSelector(getAppState, fromApp.getTenantByCOMTRASPrefix);

export const getCurrentUser = createSelector(getAuthState, fromAuth.getCurrentUser);

export const getMenu = createSelector(getAuthState, fromAuth.getMenu);

export const getIsAuthenticated = createSelector(getAuthState, fromAuth.getIsAuthenticate);

export const getDisplayAppContentOnly = createSelector(getAuthState, fromAuth.getIsAppContentOnly);

export const getAllGridViews = createSelector(getMasterState, fromMaster.getAllGridViews);

export const getGridViewReference = createSelector(getMasterState, fromMaster.getGridViewReference);

export const getCurrentGridView = createSelector(getMasterState, fromMaster.getCurrentGridView);

export const getMasterPaginationRequest = createSelector(getMasterState, fromMaster.getMasterPaginationRequest);

export const getMasterDataItems = createSelector(getMasterState, fromMaster.getMasterDataItems);

export const getMasterTranslations = createSelector(getMasterState, fromMaster.getMasterTranslations);

export const getMasterCurrentItem = createSelector(getMasterState, fromMaster.getMasterSelectedItem);

export const getCurrentItemTranslated = createSelector(getMasterState, fromMaster.getCurrentItemTranslated);

export const getEditMasterModeActive = createSelector(getMasterState, fromMaster.getEditMasterModeActive);

export const getIsDiscardMasterMode = createSelector(getMasterState, fromMaster.getIsDiscard);

export const getModifyMasterModeActive = createSelector(getMasterState, fromMaster.getModifyMasterModeActive);
export const getMasterReference = createSelector(getMasterState, fromMaster.getMasterNameReference);

export const getUserRoles = createSelector(getUserState, fromUser.getUserRoles);

export const getAllPermissions = createSelector(getUserState, fromUser.getAllPermissions);

export const getCoffeeSpecies = createSelector(getQualityState, fromQuality.getCoffeeSpecies);

export const getQualityTypes = createSelector(getQualityState, fromQuality.getQualityType);

export const getStagesProcessing = createSelector(getCuppingProcessState, fromCuppingProcess.getStageProcess);

export const getItemDefinitions = createSelector(getItemDefinitionState, fromItemDefinition.getItemDefinition);

export const getMetricsDefinitionGroups = createSelector(getMetricState, fromMetric.getMetricsDefinitionGroups);

export const getMetricsDefinitionTypes = createSelector(getMetricState, fromMetric.getMetricsDefinitionTypes);

export const getMetricsDefinitionTypesRes = createSelector(getMetricState, fromMetric.getMetricsDefinitionTypesRes);

export const getStandardDefinitions = createSelector(
  getStandardDefinitionState,
  fromStandardDefinition.getStandardDefinitions,
);

export const getLaboratories = createSelector(getCuppingProcessState, fromCuppingProcess.getLaboratories);

export const getCuppingSessionStatus = createSelector(
  getCuppingSessionState,
  fromCuppingSession.getCuppingSessionStatus,
);

export const getGuestEvaluatorsQueue = createSelector(
  getCuppingSessionState,
  fromCuppingSession.getGuestEvaluatorsQueue,
);

export const getInvitationConfirmation = createSelector(
  getCuppingSessionState,
  fromCuppingSession.getInvitationConfirmation,
);

export const getThirdPartyTypes = createSelector(getThirdPartyState, fromThirdParty.getThirdPartyTypes);

export const getContactTypes = createSelector(getThirdPartyState, fromThirdParty.getContactTypes);

export const getAddressTypes = createSelector(getThirdPartyState, fromThirdParty.getAddressTypes);

export const getTenantSampleTypes = createSelector(getSampleState, fromSample.getTenantSampleTypes);

export const getComtrasStatus = createSelector(getSampleState, fromSample.getComtrasStatus);

export const getPackageTypes = createSelector(getSampleState, fromSample.getPackageType);

export const getCoffeeCertifications = createSelector(getSampleState, fromSample.getCoffeeCertifications);

export const getApprovalStatuses = createSelector(getDispatch, fromDispatch.getApprovalStatuses);

export const getReferenceTypes = createSelector(getSampleState, fromSample.getReferenceTypes);

export const getLastSampleStatusLog = createSelector(getSampleState, fromSample.getLastStatusLog);

export const getDispatchStatuses = createSelector(getDispatch, fromDispatch.getDispatchStatuses);

export const getSampleApprovalInternalLogs = createSelector(getSampleState, fromSample.getSampleApprovalInternalLogs);

export const getSampleStatuses = createSelector(getSampleState, fromSample.getSampleStatuses);

export const getCupEvaluators = createSelector(getCuppingProcessState, fromCuppingProcess.getCupEvaluators);

export const getCuppingSessions = createSelector(getCuppingSessionState, fromCuppingSession.getCuppingSessions);

export const getCuppingSession = createSelector(getCuppingSessionState, fromCuppingSession.getCuppingSession);

// CuppingProcess
export const getCuppingProcesses = createSelector(getCuppingProcessState, fromCuppingProcess.getCuppingProcesses);

export const getCuppingProcessCuppingSession = createSelector(
  getCuppingProcessState,
  fromCuppingProcess.getCuppingProcessCuppingSession,
);

export const getCuppingProcessCupEvaluator = createSelector(
  getCuppingProcessState,
  fromCuppingProcess.getCuppingProcessCupEvaluator,
);

export const getCuppingProcessSample = createSelector(
  getCuppingProcessState,
  fromCuppingProcess.getCuppingProcessSample,
);

export const getCuppingProcessLaboratory = createSelector(
  getCuppingProcessState,
  fromCuppingProcess.getCuppingProcessLaboratory,
);

export const getCuppingProcessMetricsDefinitionType = createSelector(
  getCuppingProcessState,
  fromCuppingProcess.getCuppingProcessMetricsDefinitionType,
);

export const getCuppingProcessStandardDefinition = createSelector(
  getCuppingProcessState,
  fromCuppingProcess.getCuppingProcessStandardDefinition,
);

export const getRetrievedUserByEmail = createSelector(getAuthState, fromAuth.getUserByEmail);

export const getRetrievedUserByUsername = createSelector(getAuthState, fromAuth.getUserByUsername);

export const getFullSampleApprovalState = createSelector(getApprovalState, fromGuest.getSampleApprovalState);
