<p-dialog
  [(visible)]="visible"
  [appendTo]="appendTo"
  draggable="false"
  [modal]="showOnModal"
  [closable]="closable"
  [closeOnEscape]="false"
  [style]="styleDialog"
  [styleClass]="'ncs-dialog layout-wrapper layout-compact'"
  [position]="'center'"
  [blockScroll]="false"
  [resizable]="true"
  (onHide)="onDialogHide()"
  (onShow)="onDialogShow()">
  <p-header style="display: flex; justify-content: space-between">
    <div class="confirm-dialog-header nkg-dialog-basic-title">
      {{ headerText | translate }}
    </div>
    <ng-content select="[dialog-form=header]"></ng-content>
  </p-header>
  <ng-content></ng-content>
  <p-footer [ngStyle]="footerStyle">
    <button
      *ngIf="secondaryButtonShow"
      type="button"
      pButton
      #secondaryButton
      [ngStyle]="secondaryButtonStyle"
      label="{{ secondaryButtonText | translate }}"
      class="ngk-secondary-btn"
      (click)="secondaryClicked()"></button>
    <button
      *ngIf="primaryButtonShow"
      type="button"
      pButton
      #primaryButton
      [ngStyle]="primaryButtonStyle"
      label="{{ primaryButtonText | translate }}"
      class="nkg-primary-btn"
      (click)="primaryClicked()"></button>
  </p-footer>
</p-dialog>
