import { createReducer, on } from '@ngrx/store';

import * as MetricDefinitionActionTypes from '../actions/metric.action';
import { MetricDefinition } from '../../domain/metric-definition.model';
import { MetricsDefinitionOption } from '../../domain/metrics-definition-option.model';
import { MetricsDefinitionGroup } from '../../domain/metrics-definition-group.model';
import { MetricsDefinitionType } from '../../domain/metrics-definition-type.model';
import { MetricsDefinitionTypeResult } from '../../domain/metrics-definition-type-result.model';

export interface MetricState {
  metricsDefinitionGroups: MetricsDefinitionGroup[];
  metricsDefinitionTypes: MetricsDefinitionType[];
  metricsDefinitionTypesRes: MetricsDefinitionTypeResult[];
  metricsDefinitionOptions: MetricsDefinitionOption[];
  metricsDefinitions: MetricDefinition[];
}

export const initialMetricState: MetricState = {
  metricsDefinitionGroups: null,
  metricsDefinitionTypes: null,
  metricsDefinitionTypesRes: null,
  metricsDefinitionOptions: null,
  metricsDefinitions: null,
};

export const metricDefinitionReducer = createReducer(
  initialMetricState,
  on(
    MetricDefinitionActionTypes.fetchMetricGroupsSuccess,
    (state: MetricState, { metricDefGroups }): MetricState => ({
      ...state,
      metricsDefinitionGroups: metricDefGroups,
    }),
  ),
  on(
    MetricDefinitionActionTypes.fetchMetricsDefTypesSuccess,
    (state: MetricState, { metricTypes }): MetricState => ({
      ...state,
      metricsDefinitionTypes: metricTypes,
    }),
  ),
  on(
    MetricDefinitionActionTypes.fetchMetricsDefTypesResultSuccess,
    (state: MetricState, { metricsTypeResults }): MetricState => ({
      ...state,
      metricsDefinitionTypesRes: metricsTypeResults,
    }),
  ),
  on(
    MetricDefinitionActionTypes.fetchMetricsDefOptionsSuccess,
    (state: MetricState, { metricOptions }): MetricState => ({
      ...state,
      metricsDefinitionOptions: metricOptions,
    }),
  ),
  on(
    MetricDefinitionActionTypes.fetchMetricsDefSuccess,
    (state: MetricState, { metricDefinitions }): MetricState => ({
      ...state,
      metricsDefinitions: metricDefinitions,
    }),
  ),
);

export const getMetricsDefinitionGroups = (state: MetricState): MetricsDefinitionGroup[] =>
  state.metricsDefinitionGroups;
export const getMetricsDefinitionTypes = (state: MetricState): MetricsDefinitionType[] => state.metricsDefinitionTypes;
export const getMetricsDefinitionTypesRes = (state: MetricState): MetricsDefinitionTypeResult[] =>
  state.metricsDefinitionTypesRes;
