import { createReducer, on } from '@ngrx/store';
import * as QualityActionTypes from '../actions/quality.action';
import { CoffeeSpecie } from '../../domain/coffee-specie.model';
import { QualityType } from '../../domain/quality-type.model';
import { QualityMain } from '../../domain/quality-main.model';
import { QualityItem } from '../../domain/quality-item.model';
import { QualityAdditionalBranding } from '../../domain/quality-additional-branding.model';

export interface QualityState {
  qualityType: QualityType[];
  coffeeSpecies: CoffeeSpecie[];
  qualityItems: QualityItem[];
  qualityMains: QualityMain[];
  qualityBrandings: QualityAdditionalBranding[];
}

export const initialQualityState: QualityState = {
  qualityType: null,
  coffeeSpecies: [],
  qualityItems: [],
  qualityMains: [],
  qualityBrandings: [],
};

export const qualityReducer = createReducer(
  initialQualityState,
  on(
    QualityActionTypes.fetchQualityTypeSuccess,
    (state: QualityState, { qualityTypes }): QualityState => ({
      ...state,
      qualityType: qualityTypes,
    }),
  ),
  on(
    QualityActionTypes.fetchCoffeeSpeciesSuccess,
    (state: QualityState, { coffeeSpecies }): QualityState => ({
      ...state,
      coffeeSpecies: coffeeSpecies,
    }),
  ),
  on(
    QualityActionTypes.fetchQualityItemSuccess,
    (state: QualityState, { qualityItems }): QualityState => ({
      ...state,
      qualityItems: qualityItems,
    }),
  ),
  on(
    QualityActionTypes.fetchQualityMainSuccess,
    (state: QualityState, { qualityMains }): QualityState => ({
      ...state,
      qualityMains: qualityMains,
    }),
  ),
  on(
    QualityActionTypes.fetchQualityBrandingSuccess,
    (state: QualityState, { qualityBrandings }): QualityState => ({
      ...state,
      qualityBrandings: qualityBrandings,
    }),
  ),
);

export const getQualityType = (state: QualityState): QualityType[] => state.qualityType;
export const getCoffeeSpecies = (state: QualityState): CoffeeSpecie[] => state.coffeeSpecies;
export const getQualityItems = (state: QualityState): QualityItem[] => state.qualityItems;
export const getQualityMains = (state: QualityState): QualityMain[] => state.qualityMains;
export const getQualityBrandings = (state: QualityState): QualityAdditionalBranding[] => state.qualityBrandings;
