import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import {
  fetchMetricGroupsSuccess,
  fetchMetricsDefOptionsSuccess,
  fetchMetricsDefSuccess,
  fetchMetricsDefTypes,
  fetchMetricsDefTypesResultSuccess,
  fetchMetricsDefTypesSuccess,
  MetricActionType,
} from '../actions';
import { QueryService } from '../services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { MetricDefinition } from '../../domain/metric-definition.model';
import { MetricsDefinitionOption } from '../../domain/metrics-definition-option.model';
import { MetricsDefinitionGroup } from '../../domain/metrics-definition-group.model';
import { MetricsDefinitionType } from '../../domain/metrics-definition-type.model';
import { MetricsDefinitionTypeResult } from '../../domain/metrics-definition-type-result.model';

@Injectable()
export class MetricEffects {
  constructor(
    protected actions$: Actions,
    protected queryService: QueryService,
  ) {}

  public readonly getMetricsDefinitionGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActionType.FETCH_METRIC_GROUPS),
      switchMap(() => this.queryService.querySearch(MetricsDefinitionGroup)),
      map((metricGroups: MetricsDefinitionGroup[]) => fetchMetricGroupsSuccess({ metricDefGroups: metricGroups })),
    ),
  );

  public readonly fetchMetricsDefinitionTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMetricsDefTypes),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination._include = '*';
          newPagination.size = null;
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.queryService.querySearch<MetricsDefinitionType[]>(MetricsDefinitionType, action.pagination),
      ),
      map(metricTypes => fetchMetricsDefTypesSuccess({ metricTypes: metricTypes })),
    ),
  );

  public readonly getMetricsDefinitionTypesRes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActionType.FETCH_METRIC_TYPES_RES),
      switchMap(() => this.queryService.querySearch(MetricsDefinitionTypeResult)),
      map((metricTypesRes: MetricsDefinitionTypeResult[]) =>
        fetchMetricsDefTypesResultSuccess({ metricsTypeResults: metricTypesRes }),
      ),
    ),
  );

  public readonly getMetricsDefinitionOptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActionType.FETCH_METRIC_OPTIONS),
      switchMap(() => this.queryService.querySearch(MetricsDefinitionOption)),
      map((metricTypesRes: MetricsDefinitionOption[]) =>
        fetchMetricsDefOptionsSuccess({ metricOptions: metricTypesRes }),
      ),
    ),
  );

  public readonly getMetricsDefinitionDef$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MetricActionType.FETCH_METRIC_DEFINITIONS),
      switchMap(() => this.queryService.querySearch(MetricDefinition)),
      map((metricDefinitions: MetricDefinition[]) => fetchMetricsDefSuccess({ metricDefinitions: metricDefinitions })),
    ),
  );
}
