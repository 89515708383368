import { createReducer, on } from '@ngrx/store';
import * as ItemDefinitionActionTypes from '../actions/item-definition.action';
import { ItemDefinition } from '../../domain/item-definition.model';

export interface ItemDefinitionState {
  itemDefinition: ItemDefinition[];
}

export const initialItemDefinitionState: ItemDefinitionState = {
  itemDefinition: null,
};

export const itemDefinitionReducer = createReducer(
  initialItemDefinitionState,
  on(
    ItemDefinitionActionTypes.fetchItemDefinitionSuccess,
    (state: ItemDefinitionState, { itemDefinition }): ItemDefinitionState => ({
      ...state,
      itemDefinition: itemDefinition,
    }),
  ),
);

export const getItemDefinition = (state: ItemDefinitionState): ItemDefinition[] => state.itemDefinition;
