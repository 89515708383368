<div #tooltipTargetContainer class="tooltipTargetContainer" [ngStyle]="targetContainerStyle">
  <!-- show icon on input field -->
  <span *ngIf="tooltipTarget" [ngClass]="iconWrapperClassMap" [ngStyle]="targetStyle">
    <ng-content select="ncs-tooltip-target"></ng-content>
  </span>
  <!-- show icon corresponding to severity if no tooltipTarget is rendered -->
  <span
    *ngIf="!tooltipTarget"
    [class]="'no-target ' + iconClass + ' ' + tooltipTargetIconClass"
    [ngStyle]="iconStyle ? iconStyle : { 'vertical-align': 'middle' }"></span>
</div>
<p-overlayPanel
  #tooltipContainer
  appendTo="body"
  *ngIf="tooltipContent || (myTooltipMessages && myTooltipMessages.length > 0)"
  [styleClass]="overlayPanelStyleClasses"
  [dismissable]="false">
  <div [style.maxWidth]="tooltipMaxWidth">
    <ng-content select="ncs-tooltip-content"></ng-content>
    <span
      *ngIf="!tooltipContent && myTooltipMessages && myTooltipMessages.length === 1"
      innerHtml="{{ myTooltipMessages[0].key | translate: myTooltipMessages[0].params }}"></span>
    <ul *ngIf="!tooltipContent && myTooltipMessages && myTooltipMessages.length > 1">
      <li *ngFor="let tooltipMessage of myTooltipMessages">
        {{ tooltipMessage.key | translate: tooltipMessage.params }}
      </li>
    </ul>
  </div>
</p-overlayPanel>
