import { createReducer, on } from '@ngrx/store';
import * as CuppingProcessActionTypes from '../actions/cupping-process.action';
import { StageProcessing } from '../../domain/stage-processing.model';
import { StandardDefinition } from '../../domain/standard-definition.model';
import { MetricsDefinitionType } from '../../domain/metrics-definition-type.model';
import { Laboratory } from '../../domain/laboratory.model';
import { Sample } from '../../domain/sample.model';
import { CupEvaluator } from '../../domain/cup-evaluator.model';
import { CuppingProcess } from '../../domain/cupping-process.model';
import { CuppingSession } from '../../domain/cupping-session.model';
import { nullsafe } from '../../../modules/utils/object-utils';

export interface CuppingProcessState {
  cuppingSession: CuppingSession;
  cuppingProcesses: CuppingProcess[];
  cuppingProcess: CuppingProcess;
  cupEvaluator: CupEvaluator;
  cupEvaluators: CupEvaluator[];
  sample: Sample;
  laboratory: Laboratory;
  metricsDefinitionType: MetricsDefinitionType;
  standardDefinition: StandardDefinition;
  laboratories: Laboratory[];
  stageProcess: StageProcessing[];
}

export const initialCuppingProcessState: CuppingProcessState = {
  cuppingSession: null,
  cuppingProcesses: null,
  cuppingProcess: null,
  cupEvaluator: null,
  cupEvaluators: [],
  sample: null,
  laboratory: null,
  metricsDefinitionType: null,
  standardDefinition: null,
  laboratories: [],
  stageProcess: [],
};

export const cuppingProcessReducer = createReducer(
  initialCuppingProcessState,
  on(
    CuppingProcessActionTypes.resetCuppingProcessState,
    (): CuppingProcessState => ({
      ...initialCuppingProcessState,
    }),
  ),
  on(
    CuppingProcessActionTypes.fetchCuppingProcessSuccess,
    (state: CuppingProcessState, { cuppingProcesses }): CuppingProcessState => {
      const cuppingProcess = nullsafe(cuppingProcesses).find(x => x.sample.id == state?.sample?.id);

      return {
        ...state,
        cuppingProcesses: cuppingProcesses,
        cuppingProcess: cuppingProcess,
      };
    },
  ),
  on(
    CuppingProcessActionTypes.selectCuppingProcess,
    CuppingProcessActionTypes.getCuppingProcessSuccess,
    (state: CuppingProcessState, { cuppingProcess }): CuppingProcessState => ({
      ...state,
      cuppingProcess: cuppingProcess,
      laboratory: cuppingProcess?.laboratory,
      sample: cuppingProcess?.sample,
      standardDefinition: cuppingProcess?.standardDefinition,
      cupEvaluator: cuppingProcess?.cupEvaluator,
      metricsDefinitionType: cuppingProcess?.metricsDefinitionType,
    }),
  ),
  on(
    CuppingProcessActionTypes.getCuppingSessionByIdSuccess,
    (state: CuppingProcessState, { cuppingSession }): CuppingProcessState => ({
      ...state,
      cuppingSession: cuppingSession,
      laboratory: cuppingSession?.laboratory,
      standardDefinition: cuppingSession?.standardDefinition,
    }),
  ),
  on(
    CuppingProcessActionTypes.getCupEvaluatorByIdSuccess,
    (state: CuppingProcessState, { cupEvaluator }): CuppingProcessState => ({
      ...state,
      cupEvaluator: cupEvaluator,
    }),
  ),
  on(
    CuppingProcessActionTypes.getSampleByIdSuccess,
    (state: CuppingProcessState, { sample }): CuppingProcessState => ({
      ...state,
      sample: sample,
    }),
  ),
  on(
    CuppingProcessActionTypes.getLaboratoryByIdSuccess,
    (state: CuppingProcessState, { laboratory }): CuppingProcessState => ({
      ...state,
      laboratory: laboratory,
    }),
  ),
  on(
    CuppingProcessActionTypes.getMetricDefinitionTypeByIdSuccess,
    (state: CuppingProcessState, { metricsDefinitionType }): CuppingProcessState => ({
      ...state,
      metricsDefinitionType: metricsDefinitionType,
    }),
  ),
  on(
    CuppingProcessActionTypes.getStandardDefinitionByIdSuccess,
    (state: CuppingProcessState, { standardDefinition }): CuppingProcessState => ({
      ...state,
      standardDefinition: standardDefinition,
    }),
  ),
  on(
    CuppingProcessActionTypes.fetchLaboratoriesSuccess,
    (state: CuppingProcessState, { laboratories }): CuppingProcessState => ({
      ...state,
      laboratories: laboratories,
    }),
  ),
  on(
    CuppingProcessActionTypes.getCupEvaluatorByIdSuccess,
    (state: CuppingProcessState, { cupEvaluator }): CuppingProcessState => ({
      ...state,
      cupEvaluator: cupEvaluator,
    }),
  ),
  on(
    CuppingProcessActionTypes.fetchStageProcessSuccess,
    (state: CuppingProcessState, { stageProcess }): CuppingProcessState => ({
      ...state,
      stageProcess: stageProcess,
    }),
  ),
);

export const getCuppingProcesses = (state: CuppingProcessState): CuppingProcess[] => state.cuppingProcesses;
export const getCuppingProcessCupEvaluator = (state: CuppingProcessState): CupEvaluator => state.cupEvaluator;
export const getCuppingProcessSample = (state: CuppingProcessState): Sample => state.sample;
export const getCuppingProcessLaboratory = (state: CuppingProcessState): Laboratory => state.laboratory;
export const getCuppingProcessMetricsDefinitionType = (state: CuppingProcessState): MetricsDefinitionType =>
  state.metricsDefinitionType;
export const getCuppingProcessStandardDefinition = (state: CuppingProcessState): StandardDefinition =>
  state.standardDefinition;
export const getCuppingProcessCuppingSession = (state: CuppingProcessState): CuppingSession => state.cuppingSession;
export const getLaboratories = (state: CuppingProcessState): Laboratory[] => state.laboratories;
export const getCupEvaluators = (state: CuppingProcessState): CupEvaluator[] => state.cupEvaluators;
export const getStageProcess = (state: CuppingProcessState): StageProcessing[] => state.stageProcess;
