import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { ContactType } from './contact-type.model';

@Entity('masterdata', 'ThirdPartyContact', 'qc')
export class ThirdPartyContact extends AbstractVersionedEntity {
  @Serialized firstName: string;
  @Serialized lastName: string;
  @Serialized email?: string;
  @Serialized phoneNumber?: string;
  @Serialized contactType: ContactType;
}
