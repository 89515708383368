<label class="nkg-block">
  <span *ngIf="label" class="mainCaption">{{ label | translate }}</span>
  <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
  <ng-template #primaryContent>
    <ncs-error-tooltip class="tooltip-wrapper" [validationResult]="validationResult">
      <div class="ncs-basic-wrapper" [ngClass]="{ 'ncs-basic-wrapper-disabled': disabled }">
        <textarea
          pInputTextarea
          [rows]="rows"
          [cols]="cols"
          [value]="value || ''"
          placeholder="{{ placeholder | translate }}"
          [maxLength]="maxLengthCharacter"
          [ngStyle]="{
            overflow: 'visible',
            height: '45px',
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            marginBottom: 0
          }"
          [disabled]="disabled"
          (blur)="onBlur($event)"
          (change)="onChanges($event)"
          (focusin)="focusIn = true"
          (focusout)="focusIn = false"></textarea>
      </div>
    </ncs-error-tooltip>
  </ng-template>
  <!-- only content -->
  <ng-template #secondContent>
    <span>{{ value || '' }}</span>
  </ng-template>
</label>
