import { Injectable } from '@angular/core';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState } from '../reducers/auth.reducer';
import { getAuthState, State } from '../reducers';
import { NkgMenuItem } from '../../models/nkg-menu-item.model';
import { User } from '../../domain/user.model';

@Injectable()
export class AuthSelector {
  private readonly authFeatureSelector: MemoizedSelector<State, AuthState>;

  public constructor() {
    this.authFeatureSelector = getAuthState;
  }

  public get currentUser(): MemoizedSelector<object, User> {
    return createSelector(this.authFeatureSelector, state => state.currentUser);
  }

  public get appMenu(): MemoizedSelector<object, NkgMenuItem[]> {
    return createSelector(this.authFeatureSelector, state => state.nkgMenu);
  }
}
