import { Entity, Serialized } from '../schema/decorators';
import { Country } from './country.model';
import { ComtrasInstance } from './comtras-instance.model';
import { Agency } from './agency.model';

import { AbstractVersionedEntity } from './abstract-versioned-entity.model';

@Entity('administration', 'Tenant', 'auth')
export class Tenant extends AbstractVersionedEntity {
  @Serialized uuid?: string;
  @Serialized name?: string;
  @Serialized abbreviation?: string;
  @Serialized acronym?: string;
  @Serialized comtrasReference?: string;
  @Serialized tagetikCode?: string;
  @Serialized deactivated?: boolean;
  @Serialized codeGroupCompany?: string;
  @Serialized country: Country;
  @Serialized comtrasInstance: ComtrasInstance;
  @Serialized({ elementType: Agency }) agencies?: Agency[] = [];
}
