import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppSelector } from '../selectors';
import { addAppNotification } from '../actions';
import * as fromRoot from '../reducers';
import { AppNotification } from '../../domain/app-notification.model';
import { AppTimezoneInfo } from '../../domain/app-timezone-info.model';

@Injectable()
export class AppStoreService {
  private readonly store: Store<fromRoot.State>;
  private readonly selector: AppSelector;

  public constructor(store: Store<fromRoot.State>, selector: AppSelector) {
    this.store = store;
    this.selector = selector;
  }

  public addNotification(notification: AppNotification, triggerGrowl: boolean): void {
    this.store.dispatch(addAppNotification({ notification: notification, triggerGrowl: triggerGrowl }));
  }

  public getTimezone(): Observable<AppTimezoneInfo> {
    return this.store.select(this.selector.getTimezone);
  }
}
