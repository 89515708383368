import { DTO, Internal, Serialized } from '../schema/decorators';
import { CupEvaluator } from './cup-evaluator.model';

@DTO('cupping', 'GuestEvaluatorDto', 'qc')
export class GuestEvaluatorDto {
  @Internal __type?: string;
  @Serialized name: string;
  @Serialized lastName: string;
  @Serialized code: string;
  @Serialized email: string;
  @Serialized username: string;
  @Serialized password: string;
  @Serialized companyInfo: string;
  @Serialized({ elementType: CupEvaluator, composite: true })
  cupEvaluators?: CupEvaluator[] = [];
}
