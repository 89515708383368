import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { Quality } from './quality.model';

@Entity('cupping', 'CuppingProcessQualityAllocation', 'qc')
export class CuppingProcessQualityAllocation extends AbstractEntity {
  @Serialized weight: number;
  @Serialized valueResult: number;
  @Serialized quality: Quality;
}
