import { createReducer, on } from '@ngrx/store';

import * as ThirdPartyActionTypes from '../actions/third-party.action';
import { ThirdPartyType } from '../../domain/third-party-type.model';
import { AddressType } from '../../domain/address-type.model';
import { ContactType } from '../../domain/contact-type.model';
import { ThirdParty } from '../../domain/third-party.model';

export interface ThirdPartyState {
  thirdParty: ThirdParty[];
  thirdPartyTypes: ThirdPartyType[];
  contactTypes: ContactType[];
  addressTypes: AddressType[];
}

export const initialThirdPartyState: ThirdPartyState = {
  thirdParty: null,
  thirdPartyTypes: null,
  contactTypes: null,
  addressTypes: null,
};

export const thirdPartyReducer = createReducer(
  initialThirdPartyState,
  on(
    ThirdPartyActionTypes.fetchThirdPartySuccess,
    (state: ThirdPartyState, { thirdParty }): ThirdPartyState => ({
      ...state,
      thirdParty: thirdParty,
    }),
  ),
  on(
    ThirdPartyActionTypes.fetchThirdPartyTypeSuccess,
    (state: ThirdPartyState, { thirdPartyType }): ThirdPartyState => ({
      ...state,
      thirdPartyTypes: thirdPartyType,
    }),
  ),
  on(
    ThirdPartyActionTypes.fetchContactTypeSuccess,
    (state: ThirdPartyState, { contactTypes }): ThirdPartyState => ({
      ...state,
      contactTypes: contactTypes,
    }),
  ),
  on(
    ThirdPartyActionTypes.fetchAddressTypeSuccess,
    (state: ThirdPartyState, { addressTypes }): ThirdPartyState => ({
      ...state,
      addressTypes: addressTypes,
    }),
  ),
);

export const getThirdPartyTypes = (state: ThirdPartyState): ThirdPartyType[] => state.thirdPartyTypes;
export const getContactTypes = (state: ThirdPartyState): ContactType[] => state.contactTypes;
export const getAddressTypes = (state: ThirdPartyState): AddressType[] => state.addressTypes;
