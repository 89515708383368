import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';

@Entity('cupping', 'CuppingProcessMetricDefect', 'qc')
export class CuppingProcessMetricDefect extends AbstractEntity {
  @Serialized cupNumber: number;
  @Serialized cupDefect: number;
  @Serialized valueResult: number; // For option-per-cup
  @Internal minValue: number;
  @Internal maxValue: number;
  @Internal warningMessage: any;
}
