import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TooltipAttribute } from '../models/enums';

@Component({
  selector: 'number-renderer',
  template: `
    <ncs-tooltip [messages]="tooltip?.messages" [tooltipSeverity]="tooltip?.severity">
      <ncs-tooltip-target>
        <span [ngStyle]="style">{{ isNaN(value) ? value : (value | number: pattern) }}</span>
      </ncs-tooltip-target>
    </ncs-tooltip>
  `,
})
export class NumberRendererComponent implements AgRendererComponent {
  value: any;
  pattern: string = '1.2-2';
  style: any;
  tooltip: TooltipAttribute;

  styleFunction: (params: ICellRendererParams) => any;
  tooltipFunction: (params: ICellRendererParams) => TooltipAttribute;

  isNaN = Number.isNaN;

  agInit(params: ICellRendererParams): void {
    if (params.colDef.cellRendererParams) {
      if (params.colDef.cellRendererParams.pattern) this.pattern = params.colDef.cellRendererParams.pattern;
      if (params.colDef.cellRendererParams.styleFunction)
        this.styleFunction = params.colDef.cellRendererParams.styleFunction;
      if (params.colDef.cellRendererParams.tooltipFunction)
        this.tooltipFunction = params.colDef.cellRendererParams.tooltipFunction;
    }
    this.prepareTemplateDate(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.prepareTemplateDate(params);
    return true;
  }

  private prepareTemplateDate(params: ICellRendererParams): void {
    this.value = params.value;
    this.setStyle(params);
    if (this.tooltipFunction) this.tooltip = this.tooltipFunction(params);
  }

  private setStyle(params: ICellRendererParams): void {
    this.style = {
      float: 'right',
    };
    if (this.styleFunction) {
      this.style = Object.assign(this.style, this.styleFunction(params));
    }
  }
}
