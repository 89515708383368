import { combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as fromRoot from '../../../../../../core/ngrx/reducers';
import { MasterChildComponent } from '../../../../../../modules/master/components/master-child.component';
import { NcsTableCrudComponent } from '../../../table-crud/table-crud/components/ncs-table-crud.component';
import { MasterdataValidationService } from '../../../../../../modules/master/services/masterdata-validation.service';
import { Rule, Validation, ValidationResult } from '../../../../../../modules/master/services/master-validation';
import { isNullOrUndefined, mergeImmutable, nullsafe } from '../../../../../../modules/utils/object-utils';
import { setMasterDataSelectedItem } from '../../../../../../core/ngrx/actions';
import { Language } from '../../../../../../core/domain/language.model';
import { TranslationIsoCode } from '../../../../models/translation-iso-code.model';
import { NcsLanguageTranslation } from '../../../../models/ncs-language-translation.model';

@Component({
  selector: 'ncs-text-translation',
  templateUrl: './ncs-text-translation.component.html',
})
export class NcsTextTranslationComponent
  extends MasterChildComponent<NcsLanguageTranslation>
  implements OnDestroy, OnInit
{
  private languages: Language[] = [];
  private allTranslations: any[] = [];
  private currentLanguage: Language = null;
  private currentItemTranslated: any = null;
  private tableTranslationSubscription$: Subscription;

  public languageTranslations: NcsLanguageTranslation[] = [];
  public tableTranslateColumns: any[];

  @Output() onSaveTranslation: EventEmitter<TranslationIsoCode> = new EventEmitter<TranslationIsoCode>();
  /** When didn't use currentItem needs notify to object that version was changed */
  @Output() onSaveTranslationParent: EventEmitter<any> = new EventEmitter<any>();
  /** Property for translation */
  @Input() fieldToTranslate: string = null;
  /** By default, the currentItem property is translated. Otherwise, you need to specify the object to translate  */
  @Input() useCurrentMasterItem: boolean = true;
  @Input() editable: boolean = false;
  @Input() entityToTranslate: any;

  @ViewChild(NcsTableCrudComponent) crudTable: NcsTableCrudComponent;

  constructor(
    protected store: Store<fromRoot.State>,
    validationService: MasterdataValidationService,
  ) {
    super(validationService, store);
  }

  ngOnInit(): void {
    this.tableTranslateColumns = this.onGetColumnDef();
    this.tableTranslationSubscription$ = this.languageSubscription();
  }

  ngOnDestroy(): void {
    this.tableTranslationSubscription$.unsubscribe();
  }

  protected onAfterItemFieldChange(): void {}

  protected onGetRuleChildValidation(): Rule {
    return {
      translation: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_value_not_allowed');
        }
      },
    };
  }

  protected onHideParentToolbar(): void {}

  protected onNewChildItem(): NcsLanguageTranslation {
    return undefined;
  }

  private onGetColumnDef(): any[] {
    return [
      {
        header: 'common.labels.translation',
        field: 'translation',
      },
      {
        header: 'common.labels.language',
        field: 'language.language',
      },
    ];
  }

  private languageSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getListLanguages),
      this.store.select(fromRoot.getMasterTranslations),
      this.store.select(fromRoot.getCurrentUser),
      this.store.select(fromRoot.getMasterCurrentItem),
      this.store.select(fromRoot.getCurrentItemTranslated),
    ]).subscribe(([languages, translations, currentUser, currentItem, currentItemTranslated]) => {
      this.currentItemTranslated = currentItemTranslated;
      if (this.useCurrentMasterItem) {
        this.entityToTranslate = currentItem;
      }
      this.currentLanguage = currentUser?.language;
      if (!isNullOrUndefined(this.currentLanguage) && (translations || []).length && (languages || []).length) {
        this.loadTranslations(languages, translations);
      }
    });
  }

  private loadTranslations(languages: Language[] = [], translations: any[] = []): void {
    this.allTranslations = translations;
    this.languages = languages.filter(lang => lang.isoCode !== this.currentLanguage.isoCode);
    this.languageTranslations = this.languages.map(
      language =>
        new NcsLanguageTranslation(
          language,
          this.loadTranslationByProperty(this.allTranslations.find(t => t.language.isoCode === language.isoCode)),
        ),
    );
  }

  private loadTranslationByProperty(translation: any /* TranslationIsoCode */): string {
    return translation?.item?.[this.fieldToTranslate];
  }

  // Save Translation and modify version of the parent item
  public getCurrentItemByLanguage(): void {
    if (!isNullOrUndefined(this.currentItemTranslated) && !isNullOrUndefined(this.entityToTranslate)) {
      if (this.entityToTranslate.__type === this.currentItemTranslated.__type) {
        // When translate child Item, the parent item should not be update it version
        const find = this.allTranslations.find(t => t.language.isoCode == this.currentLanguage.isoCode);
        const updateCurrentItem = find && !isNullOrUndefined(find.item) ? find.item : this.entityToTranslate;

        if (this.useCurrentMasterItem && !isNullOrUndefined(this.currentItemTranslated)) {
          this.entityToTranslate = mergeImmutable(this.entityToTranslate, {
            version: this.currentItemTranslated.version,
          });
          this.store.dispatch(setMasterDataSelectedItem({ selectedItem: this.entityToTranslate }));
        } else {
          this.onSaveTranslationParent.emit(updateCurrentItem);
        }
      }
    }
  }

  public onSave(): ValidationResult {
    const fullValidation = this.validationService.validateItem(
      this.onGetCurrentChildItem(),
      this.onGetRuleChildValidation(),
    );
    if (!fullValidation.valid) {
      this.validationResult = fullValidation;
      return fullValidation;
    }
    const { language } = this.onGetCurrentChildItem();
    const upd = {};
    upd[this.fieldToTranslate] = this.onGetCurrentChildItem().translation;
    if (nullsafe(this.allTranslations).length > 0) {
      const currentItemIsoCode = this.allTranslations.find(t => t.language.isoCode == language.isoCode);
      this.entityToTranslate = currentItemIsoCode ? currentItemIsoCode.item : this.entityToTranslate; // When update just one child, currentChildItem get child value item
      if (
        !isNullOrUndefined(this.currentItemTranslated) &&
        this.currentItemTranslated.__type === this.entityToTranslate.__type
      ) {
        this.entityToTranslate = mergeImmutable(this.entityToTranslate, {
          version: this.currentItemTranslated.version,
        });
      }
    }
    const newCurrentItem = mergeImmutable(this.entityToTranslate, upd);
    this.onSaveTranslation.emit({
      isoCode: language.isoCode,
      item: newCurrentItem,
    });
    this.crudTable.hideDialog();
  }
}
