import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { MetricDefinition } from './metric-definition.model';

@Entity('masterdata', 'StandardDefinitionOptionScoreDescription', 'qc')
export class StandardDefinitionOptionScoreDescription extends AbstractEntity {
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized scoreValue: number;
  @Serialized scoreDescription: string;
  @Serialized optionalDescription: string;
}
