import { Entity, Serialized } from '../schema/decorators';

import { AbstractVersionedEntity } from './abstract-versioned-entity.model';

@Entity('administration', 'Agency', 'auth')
export class Agency extends AbstractVersionedEntity {
  @Serialized agency: string;
  @Serialized code: string;
  @Serialized contactFirstName?: string;
  @Serialized contactPrefix?: string;
  @Serialized contactLastName?: string;
  @Serialized contactEmail?: string;
  @Serialized contactPhoneNumber?: string;
  @Serialized addressLine1?: string;
  @Serialized addressLine2?: string;
  @Serialized city?: string;
  @Serialized state?: string;
  @Serialized zipCode?: string;
  @Serialized deactivated?: boolean;
  @Serialized tenantId: number;
}
