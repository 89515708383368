import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NcsTooltipComponent } from './tooltip/components/tooltip.component';
import { NcsTooltipTarget } from './tooltip-target/components/tooltip-target.component';
import { NcsErrorTooltipComponent } from './error-tooltip/components/error-tooltip.component';
import { NcsTooltipContent } from './tooltip-content/components/tooltip-content.component';

@NgModule({
  imports: [CommonModule, TranslateModule, OverlayPanelModule],
  declarations: [NcsTooltipComponent, NcsErrorTooltipComponent, NcsTooltipContent, NcsTooltipTarget],
  exports: [NcsTooltipComponent, NcsErrorTooltipComponent, NcsTooltipContent, NcsTooltipTarget],
})
export class NcsTooltipSharedModule {}
