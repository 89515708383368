import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { fetchItemDefinition, fetchItemDefinitionSuccess } from '../actions';
import { QueryService } from '../services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { ItemDefinition } from '../../domain/item-definition.model';

@Injectable()
export class ItemDefinitionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly queryService: QueryService,
  ) {}

  public readonly itemDefinitionType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchItemDefinition),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(ItemDefinition, action.pagination)),
      map((itemDefinition: PaginatedResponse) => fetchItemDefinitionSuccess({ itemDefinition: itemDefinition.items })),
    ),
  );
}
