import { createAction, props } from '@ngrx/store';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { CoffeeSpecie } from '../../domain/coffee-specie.model';
import { QualityType } from '../../domain/quality-type.model';
import { QualityMain } from '../../domain/quality-main.model';
import { QualityItem } from '../../domain/quality-item.model';
import { QualityAdditionalBranding } from '../../domain/quality-additional-branding.model';

export enum QualityAction {
  FETCH_QUALITY_TYPE = '[Quality] Request Quality Type',
  FETCH_QUALITY_TYPE_SUCCESS = '[Quality] Request Quality Type Success',
  FETCH_COFFEE_SPECIES = '[Quality] Fetch Coffee Species',
  FETCH_COFFEE_SPECIES_SUCCESS = '[Quality] Fetch Coffee Species Success',
  FETCH_QUALITY_ITEM = '[Quality] Fetch Quality Items',
  FETCH_QUALITY_ITEM_SUCCESS = '[Quality] Fetch Quality Items Success',
  FETCH_QUALITY_MAIN = '[Quality] Fetch Quality Mains',
  FETCH_QUALITY_MAIN_SUCCESS = '[Quality] Fetch Quality Mains Success',
  FETCH_QUALITY_ADDITIONAL_BRANDING = '[Quality] Fetch Quality Additional Brandings',
  FETCH_QUALITY_ADDITIONAL_BRANDING_SUCCESS = '[Quality] Fetch Quality Additional Brandings Success',
}

export const fetchQualityType = createAction(
  QualityAction.FETCH_QUALITY_TYPE,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchQualityTypeSuccess = createAction(
  QualityAction.FETCH_QUALITY_TYPE_SUCCESS,
  props<{ qualityTypes: QualityType[] }>(),
);

export const fetchCoffeeSpecies = createAction(
  QualityAction.FETCH_COFFEE_SPECIES,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchCoffeeSpeciesSuccess = createAction(
  QualityAction.FETCH_COFFEE_SPECIES_SUCCESS,
  props<{ coffeeSpecies: CoffeeSpecie[] }>(),
);

export const fetchQualityItems = createAction(
  QualityAction.FETCH_QUALITY_ITEM,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchQualityItemSuccess = createAction(
  QualityAction.FETCH_QUALITY_ITEM_SUCCESS,
  props<{ qualityItems: QualityItem[] }>(),
);

export const fetchQualityMains = createAction(
  QualityAction.FETCH_QUALITY_MAIN,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchQualityMainSuccess = createAction(
  QualityAction.FETCH_QUALITY_MAIN_SUCCESS,
  props<{ qualityMains: QualityMain[] }>(),
);

export const fetchQualityBrandings = createAction(
  QualityAction.FETCH_QUALITY_ADDITIONAL_BRANDING,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchQualityBrandingSuccess = createAction(
  QualityAction.FETCH_QUALITY_ADDITIONAL_BRANDING_SUCCESS,
  props<{ qualityBrandings: QualityAdditionalBranding[] }>(),
);
