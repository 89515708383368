<p-autoComplete
  [autoHighlight]="true"
  [field]="'country'"
  [inputStyle]="{ width: '100%' }"
  [suggestions]="suggestions"
  [ngModel]="countrySelection"
  (ngModelChange)="onSelectCountry($event?.id)"
  (keyup.enter)="onSearch($event?.target?.value)"
  (onSelect)="onSelectCountry($event?.id)">
</p-autoComplete>
