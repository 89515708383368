import 'reflect-metadata';
import { PropertyInfo } from './property-info.model';

/**
 * The property denoting the 'server-type' of an entity, DTO or entity reference transported over JSON.
 */
export const TYPE_KEY: string = '__type';

/**
 * Used to store the schema/properties types in the Reflect metadata of a class.
 */
const METADATA_SCHEMA_KEY: string = 'ncs:schema';

/**
 * Map the gridViewName of a model class to its constructor function.
 */
const nameToConstructor: Map<string, Function> = new Map();

/**
 * Contains all 'known' types. This is basically all values in the above 'nameToConstructor' Map.
 * But since there is no efficient way to index into the values of the Map, we create a separate Set.
 */
const knownTypes: Set<Function> = new Set();

/**
 * Simple sequence used for anonymous classes without a @DTO and @Entity decorator/annotation.
 */
let anonymousNameSequence = 1;

export function getNameToConstructorEntries(): IterableIterator<[string, Function]> {
  return nameToConstructor.entries();
}

export function getConstructorByTypeName(type: string): Function {
  return nameToConstructor.get(type);
}

export function isKnownType(constructor: Function): boolean {
  return knownTypes.has(constructor);
}

export function isKnownTypeByName(type: string): boolean {
  return nameToConstructor.has(type);
}

/**
 * Create a new instance of the class given by its constructor.
 *
 * @param {Function} constructor the constructor function of the class
 * @param id a possible id parameter
 * @returns the expected result type
 */
export function createNew<T>(constructor: Function, id?: number): T {
  if (constructor === undefined) {
    throw new Error(`Cannot construct new instance of it.`);
  }
  return Reflect.construct(constructor, [id]);
}

/**
 * Annotate a class property with this decorator to mark it as an internal property not to be transmitted between
 * server and client.
 */
export function Internal(target: any, propertyKey: string): any {
  Object.defineProperty(target, propertyKey, {
    writable: true,
    enumerable: true,
  });
  Reflect.defineMetadata('ncs:property', <PropertyInfo>{}, target, propertyKey);
}

/**
 * Obtain the schema for the class with the given constructor function.
 *
 * @param constructor the constructor function
 * @returns the schema object
 */
export function schemaFor(constructor: Function): {
  [key: string]: PropertyInfo;
} {
  let schema = Reflect.getOwnMetadata(METADATA_SCHEMA_KEY, constructor);
  if (!schema) {
    schema = {};
    Reflect.defineMetadata(METADATA_SCHEMA_KEY, schema, constructor);
  }
  return schema;
}

/**
 * Obtain the schema for the class hierarchy starting with the given constructor function.
 *
 * @param constructor the constructor function
 * @returns the schema object
 */
export function schemaForPrototypeChain(constructor: Function): {
  [key: string]: PropertyInfo;
} {
  const superschemas = [];
  while (constructor !== null) {
    const schema = schemaFor(constructor);
    if (schema !== undefined) superschemas.push(schema);
    // eslint-disable-next-line no-param-reassign
    constructor = Object.getPrototypeOf(constructor);
  }
  const reversedSchemas = [...superschemas].reverse();
  return Object.assign({}, ...reversedSchemas);
}

/**
 * Annotate a class property with this decorator to make it eligible for automatic DTO mapping.
 *
 * When calling the decorator as a function via @Serialized({...}) then the first parameter is
 * the argument to that function call, which is a PropertyInfo with additional information about
 * the property. When not using the decorator as a function, such as @Serialized, the first
 * parameter is the class constructor prototype function and the second parameter is the gridViewName
 * of the annotated property.
 *
 * @param targetOrPinfo either the class constructor prototype function, or the PropertyInfo
 * @param propertyKey the gridViewName of the annotated property if the decorator is not used as a function
 */
export function Serialized(targetOrPinfo?: any /* PropertyInfo */, propertyKey?: string): any {
  if (propertyKey !== undefined) {
    /* We use this path when @Serialized was used without parentheses. */
    const target = targetOrPinfo;
    /* Check the design:type metadata of the property to see whether it was Array. */
    const designType = Reflect.getMetadata('design:type', target, propertyKey);
    if (designType === Array) {
      /* Configuration error: We NEED the array's element type */
      throw new Error(
        `${target.constructor.name}.${propertyKey}: Must annotate array property with @Serialized({elementType: TYPE})`,
      );
    }
    schemaFor(target)[propertyKey] = { serialized: true };
    return;
  }
  /* In this case, targetOrPinfo is an actual PropertyInfo. */
  const pinfo = targetOrPinfo;
  /* Will be called with 'target' being the prototype function of a class constructor. */
  return function (target: any, property: string) {
    /* Check the design:type metadata of the property to see whether it was Array. */
    const designType = Reflect.getMetadata('design:type', target, property);
    const realPinfo = { serialized: true, ...(pinfo || {}) };
    if (designType === Array && realPinfo.elementType === undefined) {
      /* We NEED the elementType info */
      throw new TypeError(
        `${target.constructor.name}.${property}: Must annotate array property with @Property({elementType: TYPE})`,
      );
    }
    /*
     * If the type of this property is Array, then initialize with empty array.
     * Remember that we are configuring the prototype object of a class and not an actual instance
     * of the class. That means, when instantiating a new object of the class the class constructor
     * can still freely define other values for that property (including undefined).
     */
    if (realPinfo.elementType !== undefined) {
      /* Define a writable and enumerable property for the empty array. */
      Object.defineProperty(target, property, {
        enumerable: true,
        writable: true,
        value: [],
      });
    }
    schemaFor(target)[property] = realPinfo;
  };
}

function decorateClass(className: string, constructor: Function): void {
  /* Define the internal type property used for serialization. */
  /* Once we refactored all places where an entity is loaded from the backend, we can mark it as writable: false,
   * because currently, we do lots of Object.assign(new Something(), json()) in the services.
   */
  Reflect.defineProperty(constructor.prototype, TYPE_KEY, {
    writable: true,
    value: className,
  });
  /* Register the class gridViewName with the new constructor function. Needed for serialization. */
  nameToConstructor.set(className, constructor);
  knownTypes.add(constructor);
}

/**
 * Annotate an entity class with this decorator to register the class as a known entity class.
 * This is necessary when a value of this type is serialized/deserialized by the automatic DTO mapping.
 * <p>
 * When the decorator is used without parameters, the class will be registered with an anonymous gridViewName
 * and cannot be used as a root object when transferring data between frontend and backend.
 * <p>
 * When the type is used as a root object, then the domain and gridViewName of the type must be specified
 * via parameters.
 *
 * @param {string} domainOrConstructor the domain of the entity class as used by the server or the constructor function
 * @param {string} name the simple gridViewName of the entity class
 * @param service
 * @returns {any} the function called when the class declaration is processed
 */
export function Entity(domainOrConstructor: string | Function, name?: string, service?: string): any {
  if (name === undefined) {
    const constructor: Function = <Function>domainOrConstructor;
    const className = `$anonymous${anonymousNameSequence++}`;
    return decorateClass(className, constructor);
  }
  const domain = <string>domainOrConstructor;
  return function (constructor: Function) {
    const className = `${service}.ncs.${domain}.entity.${name}`;
    return decorateClass(className, constructor);
  };
}

/**
 * Annotate a DTO class with this decorator to register the class as a known DTO class.
 * This is necessary when a value of this type is serialized/deserialized by the automatic DTO mapping.
 * <p>
 * When the decorator is used without parameters, the class will be registered with an anonymous gridViewName
 * and cannot be used as a root object when transferring data between frontend and backend.
 * <p>
 * When the type is used as a root object, then the domain and gridViewName of the type must be specified
 * via parameters.
 *
 * @param {string} domainOrConstructor the domain of the DTO class as used by the server or the constructor function
 * @param {string} name the simple gridViewName of the DTO class
 * @param service
 * @returns {any} the function called when the class declaration is processed
 */
export function DTO(domainOrConstructor: string | Function, name?: string, service?: string): any {
  if (name === undefined) {
    const constructor: Function = <Function>domainOrConstructor;
    const className = `$anonymous${anonymousNameSequence++}`;
    return decorateClass(className, constructor);
  }
  const domain = <string>domainOrConstructor;
  return function (constructor: Function) {
    const className = `${service}.ncs.${domain}.dto.${name}`;
    return decorateClass(className, constructor);
  };
}

export function VIEW(domainOrConstructor: string | Function, name?: string, service?: string): any {
  if (name === undefined) {
    const constructor: Function = <Function>domainOrConstructor;
    const className = `$anonymous${anonymousNameSequence++}`;
    return decorateClass(className, constructor);
  }
  const domain = <string>domainOrConstructor;
  return function (constructor: Function) {
    const className = `${service}.ncs.${domain}.entity.view.${name}`;
    return decorateClass(className, constructor);
  };
}

function getTargetDescriptor(desc: any, c: number, target: any, key: any): any {
  let r: any;
  let newDesc: any;
  if (c < 3) {
    r = target;
  } else {
    newDesc = desc || Object.getOwnPropertyDescriptor(target, key);
    r = newDesc;
  }
  return { newDesc, r };
}

function setDecorators(
  r: any,
  decorators: any,
  targetDesc: { desc: any; r: any },
  key: any,
  desc: any,
  c: number,
): any {
  let d: any;
  if (typeof Reflect === 'object' && typeof Reflect.decorate === 'function')
    r = Reflect.decorate(decorators, targetDesc, key, desc);
  else
    for (let i = decorators.length - 1; i >= 0; i--) {
      d = decorators[i];
      if (d)
        if (c > 3) {
          r = d(targetDesc, key, r) || d(targetDesc, key) || r;
        } else {
          r = d(r);
        }
    }
  return r;
}

const setNCSDecorators = function (decorators: any, target: any, key: any, desc: any): any {
  const c = arguments.length;
  const targetDesc = getTargetDescriptor(desc, c, target, key);
  desc = targetDesc.newDesc;
  let { r } = targetDesc;
  r = setDecorators(r, decorators, targetDesc, key, desc, c);
  return c > 3 && r && (Object.defineProperty(targetDesc, key, r), r);
};
const setNCSMetadata = function (k: any, v: any): any {
  if (typeof Reflect === 'object' && typeof Reflect.metadata === 'function') return Reflect.metadata(k, v);
};

/**
 * Programmatically decorate named class properties as @Serialized.
 *
 * This is needed to declare properties whose types have cyclic dependencies.
 *
 * @param ownerPrototype the prototype object of the class declaring the @Serialized property
 * @param props the properties and types to be decorated with @Serialized
 */
export function serialized<T>(ownerPrototype: T, props: { [key in keyof T]?: Function }): void {
  Array.from(Object.keys(props)).forEach(prop => {
    if (Object.hasOwn(props, prop)) {
      setNCSDecorators([Serialized(), setNCSMetadata('design:type', props[prop])], ownerPrototype, prop, null);
    }
  });
}
