import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from './app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { TimeService } from './time-service';
import { QueryService } from './query.service';
import { DispatchApprovalCredentials } from '../../domain/dispatch-approval-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService extends AbstractCeisService {
  private readonly actionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    timeService: TimeService,
    private readonly queryService: QueryService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = configuration.SERVER_WITH_QC_API_URL;
  }

  // rename
  public async approve(credentials: DispatchApprovalCredentials): Promise<DispatchApprovalCredentials> {
    if (credentials !== undefined) {
      credentials = this.queryService.toServer(credentials);
    }

    const response = await this.post(
      `${this.actionUrl}dispatch/approve/${this.langUrl}${this.getLanguage()}`,
      credentials,
      false,
    );
    return <DispatchApprovalCredentials>response;
  }

  public async validateSession(session: string): Promise<DispatchApprovalCredentials> {
    const response = await this.getWithParams<DispatchApprovalCredentials>(
      `${this.actionUrl}dispatch/approve${this.langUrl}${this.getLanguage()}`,
      { session: session },
      false,
      null,
    );
    return response;
  }
}
