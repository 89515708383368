import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NcsBaseBasicComponent } from '../../base-basic/base-basic.component';

/**
 * Template component refactor structure, on discussion for a final version
 */

@Component({
  selector: 'ncs-checkbox-basic',
  templateUrl: './checkbox-basic.component.html',
})
export class NcsCheckboxBasicComponent extends NcsBaseBasicComponent {
  @Input() title: string = '';
  @Input() tooltipInfo: string;
  @Input() tooltipInfoAlign: string = 'right';
  @Input() labelTop: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  onChangeEvent(event: { checked: boolean }): void {
    this.onChange.emit(event.checked);
  }
}
