import { AgGridColType, FilterGridOptionsEnum } from '../services/grid.factory';
import { isNullOrUndefined, nullsafe } from '../../../modules/utils/object-utils';
import { isNotEmpty } from '../../../modules/utils/string-utils';

const AVAILABLE_GRID_FILTER = new FilterGridOptionsEnum();

/**
 * Get filters applied on agGrid according column definition
 * @param filters filter model for agGrid
 * @param {string} yesTranslated is used for boolean columns' comparison.
 * Bolean values are setting with yes o no translated for each language
 */
export function onGetGridFilters(filters: any, yesTranslated: string): string {
  let finalQuery = '';
  Array.from(Object.keys(filters)).forEach(property => {
    const filterSource = <string>filters[property].type;
    const query = filters[property].value;
    let filterType: string;
    switch (filters[property].filterType) {
      case AgGridColType.NCS_FILTER:
        filterType = onCreateSearchFilterQuery(filterSource);
        finalQuery += `${property}${filterType}${query};`;
        break;
      case AgGridColType.DATE: {
        const { dateFrom } = filters[property];
        if (!isNullOrUndefined(dateFrom)) {
          filterType = onCreateSearchFilterQuery(filterSource);
          finalQuery += `${property}${filterType}${dateFrom};`;
        }
        break;
      }
      default: {
        // boolean filters
        const { booleanFilters } = filters[property];
        if (nullsafe(booleanFilters).length > 0) {
          const filterBoolean = booleanFilters[0] == yesTranslated;
          finalQuery += `${property}==${filterBoolean};`;
        }
        break;
      }
    }
  });
  return finalQuery;
}

function onCreateSearchFilterQuery(filterComparison: string): string {
  if (isNotEmpty(filterComparison))
    return AVAILABLE_GRID_FILTER.getAllFilters()
      .find(gridFilter => filterComparison == gridFilter.getFilter())
      .getValue();
  return '';
}
