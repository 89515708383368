export class PaginationRequestParams {
  q: string = '';
  _s: string = '';
  page: number = 0;
  /**
   * WARN: IF need all sampleThirdPartyTypes set to empty size property
   */
  size: number = 20;
  /** Properties used by master search and should be match with each master column definition */
  columns?: string;
  sort: string = 'id,DESC';
  split?: boolean;
  deleted: boolean = false;
  /** Entities included last server response */
  _include: string = '*';
}
