<ncs-tooltip [messages]="validationResult" [fieldWithIcon]="true">
  <ncs-tooltip-target>
    <span class="nkg-block" [title]="title | translate">
      <span *ngIf="labelTop && label" class="mainCaption" [ngClass]="{ disabled: disabled }">{{
        label | translate
      }}</span>
      <p-checkbox
        [disabled]="disabled || (!disabled && !editable)"
        [ngModel]="value"
        [tabindex]="tabIndex"
        [binary]="true"
        (onChange)="onChangeEvent($event)">
      </p-checkbox>
      <span *ngIf="!labelTop && label" class="mainCaption" [ngClass]="inputClass" style="display: inline-block">
        &nbsp;{{ label | translate }}
      </span>
      <span *ngIf="tooltipInfo" class="tooltip hvr" style="display: inline-block; margin-left: 0.5em">
        <span class="fa fa-question-circle" style="font-size: 1.2em"></span>
        <span class="tooltiptext right info" [ngClass]="tooltipInfoAlign">{{ tooltipInfo | translate }}</span>
      </span>
      <ng-content></ng-content>
    </span>
  </ncs-tooltip-target>
</ncs-tooltip>
