import { createAction, props } from '@ngrx/store';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { ItemDefinition } from '../../domain/item-definition.model';

export enum ItemDefinitionAction {
  FETCH_ITEM_DEFINITION = '[ItemDefinition] Request Item Definition',
  FETCH_ITEM_DEFINITION_SUCCESS = '[ItemDefinition] Request Item Definition Success',
}

export const fetchItemDefinition = createAction(
  ItemDefinitionAction.FETCH_ITEM_DEFINITION,
  props<{ pagination?: PaginationRequestParams }>(),
);

export const fetchItemDefinitionSuccess = createAction(
  ItemDefinitionAction.FETCH_ITEM_DEFINITION_SUCCESS,
  props<{ itemDefinition: ItemDefinition[] }>(),
);
