import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { MasterState } from '../reducers/master.reducer';
import { getMasterState } from '../reducers';

import { CustomGridView } from '../../../shared/grid/models/enums';
import { GridView } from '../../domain/grid-view.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';

@Injectable()
export class MasterSelector {
  private readonly masterFeatureSelector: MemoizedSelector<object, MasterState>;

  public constructor() {
    this.masterFeatureSelector = getMasterState;
  }

  public get data(): MemoizedSelector<object, PaginatedResponse> {
    return createSelector(this.masterFeatureSelector, state => state.data);
  }

  public get item(): MemoizedSelector<object, any> {
    return createSelector(this.masterFeatureSelector, state => state.selectedItem);
  }

  public get pagination(): MemoizedSelector<object, any> {
    return createSelector(this.masterFeatureSelector, state => state.paginationRequestParams);
  }

  public get master(): MemoizedSelector<object, string> {
    return createSelector(this.masterFeatureSelector, state => state.masterNameReference);
  }

  public get isNewMasterModeActive(): MemoizedSelector<object, boolean> {
    return createSelector(this.masterFeatureSelector, state => state.isNewMasterModeActive);
  }

  public get isEditMasterModeActive(): MemoizedSelector<object, boolean> {
    return createSelector(this.masterFeatureSelector, state => state.isEditMasterModeActive);
  }

  public get isModifyMasterModeActive(): MemoizedSelector<object, boolean> {
    return createSelector(this.masterFeatureSelector, state => state.isModifyMasterModeActive);
  }

  public get isShowRetiredMastersChecked(): MemoizedSelector<object, boolean> {
    return createSelector(this.masterFeatureSelector, state => state.isShowRetiredMastersChecked);
  }

  public get currentGridView(): MemoizedSelector<object, CustomGridView> {
    return createSelector(this.masterFeatureSelector, state => state.currentGridView);
  }

  public get gridViewNameMaster(): MemoizedSelector<object, string> {
    return createSelector(this.masterFeatureSelector, state => state.gridViewReference);
  }

  public get gridViews(): MemoizedSelector<object, GridView[]> {
    return createSelector(this.masterFeatureSelector, state => state.gridViews);
  }
}
