import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../ngrx/reducers';
import { fetchNotification } from '../../ngrx/actions';
import { Constants } from '../../utils/app.constants';
import { isNullOrUndefined, nullsafe } from '../../../modules/utils/object-utils';
import { AppNotification } from '../../domain/app-notification.model';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnDestroy {
  @Input() rightPanelVisible: boolean;
  @Input() latestReadDate: Date;
  @Output() actionExecuted: EventEmitter<AppNotification> = new EventEmitter();

  protected readonly Constants = Constants;

  /**
   * All notifications. This component has to sort out which are new and which are old based on the timestamp of last
   * read, stored in the application cache.
   */
  public notifications: AppNotification[];
  public newNotifications: AppNotification[];
  public oldNotifications: AppNotification[];
  private readonly storeSubscription: Subscription;

  /* Listeners/timers we need to dispose of when destroying this component. */
  private intervalId: any;

  constructor(
    private readonly translateService: TranslateService,
    private readonly store: Store<fromRoot.State>,
  ) {
    this.store.dispatch(fetchNotification({ triggerGrowl: true })); // <- initially load all messages without growls
    this.storeSubscription = this.storeToProps();
  }

  ngOnDestroy(): void {
    /* Properly cleanup */
    this.storeSubscription.unsubscribe();
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  private filterLists(): void {
    if (isNullOrUndefined(this.latestReadDate) || nullsafe(this.notifications).length == 0) return;
    /*
     * Based on the 'read time' we categorize the messages into two groups: new ones and old ones.
     */
    this.newNotifications = this.notifications.filter(n => n?.createdAt.getTime() > this.latestReadDate.getTime());
    this.oldNotifications = this.notifications.filter(n => n?.createdAt.getTime() <= this.latestReadDate.getTime());
  }

  private storeToProps(): Subscription {
    return this.store.select(fromRoot.getNotifications).subscribe((notifications: AppNotification[]) => {
      this.notifications = nullsafe(notifications);
      this.filterLists();
    });
  }

  buildMessage(n: AppNotification): string {
    return this.translateService.instant(`notification.${n.message}`, {
      param0: !n.param0 ? '' : this.translateParam(n.param0),
      param1: !n.param1 ? '' : this.translateParam(n.param1),
      param2: !n.param2 ? '' : this.translateParam(n.param2),
      param3: !n.param3 ? '' : this.translateParam(n.param3),
      param4: !n.param4 ? '' : this.translateParam(n.param4),
      param5: !n.param5 ? '' : this.translateParam(n.param5),
      uuid: n.uuid,
    });
  }

  private translateParam(param: string): string {
    return !isNullOrUndefined(param) && typeof param === 'string' ? this.translateService.instant(param) : param;
  }

  /**
   * Used by the HTML to know which CSS class to apply for the icon of a notification.
   */
  public notificationClass(notification: AppNotification): string {
    switch (notification.level) {
      case 'SUCCESS':
        return 'success';
      case 'INFO':
        return 'info';
      case 'WARN':
        return 'warning';
      case 'ERROR':
        return 'warning'; // error
      default:
        return 'default';
    }
  }
}
