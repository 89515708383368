import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { Quality } from './quality.model';

@Entity('sample', 'SampleQualityFinal', 'qc')
export class SampleQualityFinal extends AbstractEntity {
  @Serialized weight: number;
  @Serialized valueResult: number;
  @Serialized quality: Quality;
}
