/**
 * Enum to distinguish between the tree states:
 * - Input disabled and grayed out
 * - Input not disabled and fully visible
 * - 'Hard autofill': Input is autofilled and autofill is not supposed to be changable => disabled but NOT grayed out
 *      OR input's value is not manually setable but will NOT be ignored
 * - 'Soft autofill': Value is autofilled, but changable
 */
export enum DisabledType {
  YES,
  NO,
  HARD_AUTOFILL,
  SOFT_AUTOFILL,
}
