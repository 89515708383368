import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthorizationGuard } from './modules/authorization/services/authorization.guard';
import { AppTimezoneGuard } from './modules/master/guards/time-zone.guard';
import { TranslateAvailableGuard } from './modules/master/guards/translation-available.guard';
import { LanguageGuard } from './modules/master/guards/language.guard';
import { CurrentUserGuard } from './modules/master/guards/current-user.guard';
import { MetricTypeGuard } from './modules/master/guards/metric-type.guard';
import { PackageTypeGuard } from './modules/master/guards/package-type.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/authorization/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'guest',
    loadChildren: () => import('./modules/guest/guest.module').then(m => m.GuestModule),
  },
  {
    path: '',
    canActivate: [AuthorizationGuard, AppTimezoneGuard, TranslateAvailableGuard, LanguageGuard, CurrentUserGuard],
    canActivateChild: [AuthorizationGuard],
    children: [
      { path: '', redirectTo: 'default-dashboard', pathMatch: 'full' },
      // avoid MSAL redirection error with 'code' fragment in the URL
      { path: 'code', redirectTo: 'default-dashboard' },
      {
        path: 'default-dashboard',
        loadChildren: () => import('./modules/dashboard/modules/dashboard.module').then(m => m.DashboardModule),
        data: { restrictedTo: null },
        canMatch: [AuthorizationGuard],
      },
      {
        path: 'user-profile',
        canActivate: [MetricTypeGuard, PackageTypeGuard],
        loadChildren: () => import('./modules/user-profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'master',
        loadChildren: () => import('./modules/master/master-data.module').then(m => m.MasterDataModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
