import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractCeisService } from './app.abstract.service';
import { Constants } from '../../utils/app.constants';
import { AuthorizationService } from './authorization.service';
import { TimeService } from './time-service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { Tenant } from '../../domain/tenant.model';

@Injectable()
export class TenantService extends AbstractCeisService {
  private readonly actionUrl: string;
  private readonly tenantActionUrl: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private readonly authService: AuthorizationService,
    timeService: TimeService,
  ) {
    super(http, configuration, timeService);
    this.actionUrl = `${configuration.SERVER_WITH_AUTH_API_URL}administration/tenants`;
    this.tenantActionUrl = `${configuration.SERVER_WITH_AUTH_API_URL}tenant`;
  }

  /**
   * loads tenants unauthorized, so they can be displayed in login screen
   */
  public async loadActiveTenants(): Promise<Tenant[]> {
    const res: any = await this.getWithParams(
      this.actionUrl,
      {
        activeOnly: true,
        size: 50,
        page: 0,
        include: '*',
      },
      true,
      () => this.authService.renewToken(),
    );
    return <Tenant[]>res?.items || [];
  }

  async fetchComtrasInstances(): Promise<any> {
    const url: string = `${this.tenantActionUrl}/comtras/`;
    const params = {};
    return this.getWithParams(url, params, true, () => this.renewToken());
  }

  async getTenantByComtrasPrefix(prefix: string, tenantAbbreviation?: string): Promise<Tenant> {
    const url: string = `${this.tenantActionUrl}/comtras/${prefix}/tenant`;
    let params = {};
    if (!isNullOrUndefined(tenantAbbreviation) && tenantAbbreviation !== '') {
      params = Object.assign(params, { abbr: tenantAbbreviation });
    }
    return this.getWithParams<Tenant>(url, params, true, () => this.renewToken());
  }
}
