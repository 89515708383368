import { keys } from 'lodash';
import { nullsafe, Path } from '../../utils/object-utils';

/**
 * The special property name for the validation function to validate
 * the context object itself when that object also has sub-rules.
 */
export const THIS_RULE = '__this';
/**
 * The special property name for the validation function to validate
 * each array element.
 */
export const ELEMENT_RULE = '__element';

/**
 * Implemented by a validation rule. It is given the Validation object containing
 * the currently validated value, its ancestors, the root value, and a few
 * callback functions to register errors and warnings.
 */
export type ValidationFunction = (v: Validation) => void | Rule;

/**
 * Describes a rule tree, which is an object with an arbitrary number of
 * properties, whose values are either object trees which themselves are rules
 * or validation functions, which, when called with a Validation object, will
 * produce errors and warnings.
 */
export interface Rule {
  [rule: string]: ValidationFunction | Rule;
}

/**
 * A Message object for displaying of errors, that contains the i18n translation key
 * and potentially parameters, e.g:
 * message: 'My name is {{yourName}}'
 * messageParams: {yourName: "Frieda Finck"}
 * will result result in a message
 * 'My name is Frieda Finck'
 * when used on a translate pipe of ng2-translate as follows:
 * message | translate:messageParams
 */
export interface Message {
  message: string;
  messageParams: Object;
}

/**
 * The validation result of a single field which does not itself contain
 * sub-properties with validation results themselves.
 */
export interface FieldValidationResult {
  invalid: boolean;
  errors: Message[];
  warnings: Message[];
}

/**
 * Type for the field validation results in the ValidationResult#fieldResults.
 */
export interface FieldResults {
  /**
   * All properties that have a field validation result; or objects
   * describing the field validation results of sub-properties.
   */
  [prop: string]: FieldValidationResult | FieldResults;
}

/**
 * Returned by validate() to describe the total result of the complete validation.
 */
export interface ValidationResult {
  fieldResults: FieldResults; // <- object containing properties whose values are FieldValidationResults
  valid: boolean;
  allErrors: Message[];
  allWarnings: Message[];
}

/**
 * The type of the parameter in the validation callback function.
 */
export interface Validation {
  /**
   * The current property value to be validated.
   */
  ctx: any;
  /**
   * The value with which validate() was called.
   */
  root: any;
  /**
   * All the ancestor property values of 'ctx'.
   */
  ancestors: any[];
  /**
   * Function to register a validation error.
   */
  error: (message: string) => void;
  /**
   * Function to register a validation error with parameters.
   */
  errorWithParams: (message: string, parameter: Object) => void;
  /**
   * Function to register a validation warning.
   */
  warning: (message: string) => void;
  /**
   * Function to register a validation warning with parameters.
   */
  warningWithParams: (message: string, parameter: Object) => void;
}

/**
 * Validate the given 'value' against the specified 'rules'. This results in an object of type
 * 'ValidationResult' which holds for each validated field in 'value' all the validation messages.
 * <p>
 * If only a subset of all fields should be validated, the given 'path' can be used to only select
 * those specific properties to be validated as a (tree) path object.
 *
 * @param value the value/object to be validated against the given rules
 * @param rules the rules object containing the validation rule functions
 * @param path an optional object whose structure denotes the properties/paths to validate
 * @returns the ValidationResult containing the FieldResults
 */
export function validate(value: any, rules: Rule, path?: Path): ValidationResult {
  /* Check if path is a simple string */
  if (typeof path === 'string' || typeof path === 'number') {
    path = { [path]: true };
  }
  // (see also .spec): allow 4 arrays to be passed in
  // see HandlerObject.apply() in validation.ts
  const ctx = {};
  const vr: ValidationResult = {
    fieldResults: ctx,
    valid: true,
    allErrors: [],
    allWarnings: [],
  }; // <- will receive properties with validation results
  validateThat(value, value, rules, path, [], ctx, vr);
  return vr;
}

function assignProperties(fvr: FieldValidationResult, vr: ValidationResult): void {
  vr.valid = vr.valid && !fvr.invalid;
  vr.allWarnings.push(...fvr.warnings);
  vr.allErrors.push(...fvr.errors);
}

function validateProperty(root: any, ctx: any, ancestors: any[], fvr: FieldValidationResult): Validation {
  return {
    root,
    ctx,
    ancestors,
    error: (message: string): void => {
      const errorMessage: Message = { message, messageParams: undefined };
      fvr.errors.push(errorMessage);
      fvr.invalid = true;
    },
    warning: (message: string): void => {
      const warningMessage: Message = { message, messageParams: undefined };
      fvr.warnings.push(warningMessage);
      fvr.invalid = true;
    },
    errorWithParams: (message: string, parameterObject: Object): void => {
      const messageWithParams: Message = { message, messageParams: parameterObject };
      fvr.errors.push(messageWithParams);
      fvr.invalid = true;
    },
    warningWithParams: (message: string, parameterObject: Object): void => {
      const messageWithParams: Message = { message, messageParams: parameterObject };
      fvr.warnings.push(messageWithParams);
      fvr.invalid = true;
    },
  };
}

function validateThat(
  ctx: any,
  root: any,
  rule: Rule,
  path: any,
  ancestors: any[] /* excl. ctx */,
  vrctx: any,
  vr: ValidationResult,
): void {
  /* When we either want to validate everything or we reached the end of the path tree recursion... */
  if (path === undefined || path === null || path === true) {
    /* ...we see whether we have a validation function to validate that property. */
    const thisValidation = <ValidationFunction>rule[THIS_RULE];
    if (thisValidation) {
      const fvr: FieldValidationResult = {
        invalid: false,
        warnings: [],
        errors: [],
      };
      const v: Validation = validateProperty(root, ctx, ancestors, fvr);
      thisValidation(v);
      vrctx[THIS_RULE] = fvr;
      assignProperties(fvr, vr);
    }
  }
  const ctxIsArray = Array.isArray(ctx);
  if (ctxIsArray) {
    /* We assume it is an array, so now we need to iterate over the ctx */
    nullsafe(ctx).forEach(arrayIndex => {
      const arrIndex = Number(arrayIndex);
      /* We wanted to use a path but not that array index, so continue */
      const isNotApplyRule = path && !path[ELEMENT_RULE] && (path[arrIndex] === undefined || path[arrIndex] === null);
      if (!isNotApplyRule) {
        const cvrctx = <any>{};
        vrctx[arrayIndex] = cvrctx;
        const newPath = path?.[arrayIndex];
        const elementValidation = <ValidationFunction>rule[ELEMENT_RULE];
        if (elementValidation) {
          const fvr: FieldValidationResult = {
            invalid: false,
            warnings: [],
            errors: [],
          };
          const v: Validation = validateProperty(root, ctx[arrayIndex], ancestors, fvr);
          elementValidation(v);
          cvrctx[ELEMENT_RULE] = fvr;
          assignProperties(fvr, vr);
        }
        validateKeys(rule, root, ctx[arrayIndex], newPath, [ctx[arrayIndex]].concat(ancestors), cvrctx, vr);
      }
    });
  } else {
    validateKeys(rule, root, ctx, path, [ctx].concat(ancestors), vrctx, vr);
  }
}

function validateKeys(
  rule: Rule,
  root: any,
  ctx: any,
  path: any,
  ancestors: any[] /* incl. ctx */,
  vrctx: any,
  vr: ValidationResult,
): void {
  if (ctx === undefined || ctx === null) {
    return;
  }
  keys(rule).forEach((key: string) => {
    // this rule was checked before, so
    const omitRuleOrElmentRule = key === THIS_RULE || key === ELEMENT_RULE;
    // omit this rule because we did not request it
    const isNullValue = path && (path[key] === undefined || path[key] === null);
    if (!omitRuleOrElmentRule && !isNullValue) {
      const childRule = rule[key];
      const childCtx = ctx[key];
      const isRuleAFunction = typeof childRule === 'function';
      if (isRuleAFunction) {
        const fvr: FieldValidationResult = {
          invalid: false,
          warnings: [],
          errors: [],
        };
        const v: Validation = validateProperty(root, childCtx, ancestors, fvr);
        const callResult = childRule(v);
        if (callResult !== null && typeof callResult === 'object') {
          /* Expect it to be a path which we should evaluate */
          const cvrctx = {};
          vrctx[key] = cvrctx;
          validateThat(childCtx, root, callResult, path?.[key], ancestors, cvrctx, vr);
        } else {
          vrctx[key] = fvr;
          assignProperties(fvr, vr);
        }
      } else {
        const cvrctx = {};
        vrctx[key] = cvrctx;
        validateThat(childCtx, root, childRule, path?.[key], ancestors, cvrctx, vr);
      }
    }
  });
}
