import { DTO, Serialized } from '../schema/decorators';

@DTO
export class PaginationMetadata {
  @Serialized page: number;
  @Serialized size: number;
  @Serialized totalItems: number;
  @Serialized numberItems: number;
  @Serialized totalPages: number;
}
