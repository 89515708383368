import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { isEqual } from 'lodash';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { NcsBaseBasicComponent } from '../../basic-shared-module/components/base-basic/base-basic.component';
import { PaginationMetadata } from '../../../core/domain/pagination-metadata.model';
import { replaceStringForRegexp } from '../../../modules/utils/string-utils';
import { LookupParams } from '../../models/lookup-params.model';
import { ColDefMap } from '../../models/col-def-map.model';
import { ColumnsType } from '../../models/columns-type.model';
import { GridSelectionMode } from '../../models/grid-selection-mode.model';

@Component({
  selector: 'ncs-lookup-search',
  templateUrl: './lookup-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcsLookupSearch extends NcsBaseBasicComponent implements OnChanges {
  /** onCompleteMethod Output is used for getting suggestions for p-autocomplete component of primeNg
     and should be defined it and apply appropriate logic */
  @Output() onCompleteMethod: EventEmitter<LookupParams> = new EventEmitter();
  /** getting input filter value and used for new searches on server */
  @Output() onSetQuerySearch: EventEmitter<LookupParams> = new EventEmitter();
  /**  Activate grid displayed. For display grid is necessary to define array col properties */
  @Input() btnMagnifier: boolean = false;
  /** Define an autocomplete selection type. False value means single selection */
  @Input() multiple: boolean = false;
  /** Used for access to displaying data */
  @Input() fieldReadOnly: string = 'label';
  /** Array values supply for onCompleteMethod.Should be defined for a root component */
  @Input() suggestions: any;
  /** Setting title property of grid(NkgGenericGrid) */
  @Input() titleGrid: string;
  /** Setting NkgGenericGrid input value */
  @Input() gridColumnType: ColumnsType = ColumnsType.COL_DEF;
  /** Renders columns of a text type only. Consider that the elements must be set to I18 for the translations themselves. */
  @Input() colDefArray: string[];
  /** Define columns for AgGrid */
  @Input() colDefinition: ColDefMap[];
  /** Replace button search icon by label name */
  @Input() searchNameBtn: string;
  /** Allows displaying only the grid details */
  @Input() onlyGrid: boolean = false;
  /** Parameters for REST petitions to backend */
  @Input() paginationResponse: PaginationMetadata;
  /** Allows dropdown features on select input */
  @Input() enableDropDown: boolean = false; // Used for display all data options
  /** Enable or Disable clear button for remove any selected item */
  @Input() showClearBtn: boolean = false;

  @Input() btnIcon = PrimeIcons.SEARCH;

  @Output() onChangePagination: EventEmitter<number> = new EventEmitter(); // when interacting with paginationRequestParams emit us values with this Output
  @Output() onSelected: EventEmitter<any> = new EventEmitter(); // get Selections rows of grid or auto-complete
  @Output() onRemoved: EventEmitter<any> = new EventEmitter(); // get current Selections rows of grid or get removed items from auto-complete
  @Output() onClickedButton: EventEmitter<any> = new EventEmitter();
  // properties used for local logic
  displayGrid: boolean = false;
  lastTargetValue: string = '';
  // Used for local selection validation
  wasSelected: boolean = false;
  pagination: PaginationMetadata;
  fields: any;
  gridSelectionMode = GridSelectionMode;
  placeholder: string;

  constructor(private readonly cd: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.multiple) {
      // WARN: Force detection changes on multi-option
      setTimeout(() => {
        this.cd.markForCheck();
      });
    }
  }

  /**
   * fire up when the value of p-autocomplete is changed
   * @param event
   */
  public onChangeModel(event: any): void {
    this.onChanges(event);
  }

  // for multi and single selection features, the validation it's complicated
  // so, correct validations are doing it through wasSelected variable
  // CAUTION: to validate output event on component root emitter through onSelected
  public onBlurEvent(event: any): void {
    // CAUTION: event target work only for single selection
    if (!this.multiple && this.wasSelected) {
      const { value } = event.target;
      if (!isNullOrUndefined(value)) {
        // go back to the last set when we don't match selection
        if (!isEqual(value, this.value[this.fieldReadOnly])) {
          this.onSelected.emit(undefined);
          this.wasSelected = false;
          event.target.value = ''; // clear invalid selection
        }
      } else {
        // kill existing selection for empty
        this.onSelected.emit(undefined);
      }
      this.wasSelected = false;
    }
    this.onBlur(event);
  }

  /** Emit unSelection item (multi-selection only) */
  public onUnSelection(event: any): void {
    this.onRemoved.emit(event);
  }

  /**
   * Emmit event when any item from p-autocomplete is selected
   * @param event
   */
  public onSelectionEvent(event: any): void {
    this.value = event;
    this.wasSelected = true;
    this.onSelected.emit(this.value);
  }

  /**
   * get selections from lookup's grid and setting internal selection value of p-autocomplete
   * @param event
   */
  public getResultSelections(event: any): void {
    this.onSelected.emit(event);
    this.wasSelected = true;
    if (!this.multiple) {
      this.value = event;
      // force valid selection when any row is selected
      this.onBlurEvent({ target: { value: this.value[this.fieldReadOnly] } });
    }
  }

  /**
   * emit the page changes from grid
   * the paginationRequestParams component is into NkgGenericGrid(by agGrid)
   * @param {LookupParams} query
   */
  public onSearch(query: LookupParams): void {
    this.lastTargetValue = query?.query;
    const { query: queryParams, page } = query;
    this.onCompleteMethod.emit({ query: queryParams, page: page || 0 });
  }

  // stop keyboard ESCAPE event everywhere
  // into masters data, keyboard escape event cause exit edit mode
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.displayGrid) {
      event.stopPropagation();
    }
  }

  /**
   * Clean event and retrieve value removed/cleaned
   * Only working with single select mode
   */
  public onClearSelection(): void {
    if (!this.multiple && !isNullOrUndefined(this.value)) {
      this.onRemoved.emit(this.value);
    }
  }

  public clickedButton(event: any): void {
    this.displayGrid = true;
    this.onClickedButton.emit(event);
  }

  public customResult(obj: any): string {
    let str = obj[this.fieldReadOnly]; // no property is a string array
    if (!this.lastTargetValue || isNullOrUndefined(str)) {
      return str;
    }
    const reg = new RegExp(`(|\\W)(${replaceStringForRegexp(this.lastTargetValue)})`, 'gi');
    str = str.replace(reg, '$1<b>$2</b>');
    // apply a format as SelectItem
    str = this.formatDisplay(str);
    return str;
  }

  /** Clean customizable format
   * @return {string} string cleaning */
  private formatDisplay(str: string): string {
    // CAUTION: the input component will always remove some characters
    // as line breaks from the selected (display) value.
    // So we better remove these characters. Otherwise,
    // the selected value could be different
    // from the internal selection.
    const reg = /\r?\n/g;
    str = str.replace(reg, ' ');
    return str;
  }
}
