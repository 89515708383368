import { Entity, Serialized } from '../schema/decorators';

import { AbstractVersionedEntity } from './abstract-versioned-entity.model';

@Entity('administration', 'Country', 'auth')
export class Country extends AbstractVersionedEntity {
  @Serialized country: string;
  @Serialized alphaCode2: string;
  @Serialized alphaCode3: string;
  @Serialized numericCode: number;
  @Serialized originCountry: boolean;
  @Serialized icoNumber: number;
  @Serialized comtrasReference: string;
}
