import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { NcsDialogBasicComponent } from './components/ncs-dialog-basic.component';

@NgModule({
  imports: [CommonModule, TranslateModule, DialogModule, ButtonModule],
  declarations: [NcsDialogBasicComponent],
  exports: [NcsDialogBasicComponent],
})
export class NcsDialogBasicSharedModule {}
