import { createAction, props } from '@ngrx/store';
import { User } from '../../domain/user.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { EmailConfirmation } from '../../domain/email-confirmation.model';
import { CuppingSessionStatus } from '../../domain/cupping-session-status.model';
import { CupEvaluator } from '../../domain/cup-evaluator.model';
import { GuestEvaluatorDto } from '../../domain/guest-evaluator-dto.model';
import { CuppingSession } from '../../domain/cupping-session.model';

export enum CuppingSessionAction {
  FETCH_CUPPING_SESSIONS = '[CuppingSession] Fetch CuppingSessions',
  SELECT_CUPPING_SESSION = '[CuppingSession] Select CuppingSession',
  FETCH_CUPPING_SESSION_BY_YEAR_MONTH = '[CuppingSession] Fetch CuppingSession by Year and Month',
  GET_CUPPING_SESSION_BY_ID = '[CuppingSession] Get CuppingSession by Id',
  FETCH_CUPPING_SESSION_SUCCESS = '[CuppingSession] Fetch CuppingSession Success',
  FETCH_CUPPING_SESSION_STATUS = '[Cupping Status] Fetch Cupping Session Status',
  FETCH_CUPPING_SESSION_STATUS_SUCCESS = '[Cupping Status] Fetch Cupping Session Status Success',
  SEND_INVITATION = '[CuppingSession] Send invitation to Cup Evaluators',
  INVITATION_SENT = '[CuppingSession] Cupping session invitation sent',
  ADD_GUEST_EVALUATOR = '[CuppingSession] Add guest evaluator to cupping session',
  ADD_GUEST_EVALUATORS = '[CuppingSession] Add guest evaluators to cupping session',
  GUEST_EVALUATOR_SUCCESSFULLY_CREATED = '[CuppingSession] Guest evaluator has successfully created',
  GUEST_EVALUATOR_QUEUE = '[CuppingSession] Add guest evaluator to the queue',
  SAVE_GUEST_EVALUATORS_QUEUE = '[CuppingSession] Save the guest cup evaluators.',
  CUPPING_SESSIONS_RESET = '[CuppingSession] Reset all Cupping Sessions',
}

export const fetchCuppingSessions = createAction(
  CuppingSessionAction.FETCH_CUPPING_SESSIONS,
  props<{ paginationRequest?: PaginationRequestParams }>(),
);

export const fetchCuppingSessionByYearMonth = createAction(
  CuppingSessionAction.FETCH_CUPPING_SESSION_BY_YEAR_MONTH,
  props<{ year: number; month: number }>(),
);

export const getCuppingSessionByIdSession = createAction(
  CuppingSessionAction.GET_CUPPING_SESSION_BY_ID,
  props<{ cuppingSessionId: number; _include?: string }>(),
);

export const selectCuppingSession = createAction(
  CuppingSessionAction.SELECT_CUPPING_SESSION,
  props<{ cuppingSession: CuppingSession }>(),
);

export const fetchCuppingSessionSuccess = createAction(
  CuppingSessionAction.FETCH_CUPPING_SESSION_SUCCESS,
  props<{ cuppingSessions: CuppingSession[] }>(),
);

export const fetchCuppingSessionStatus = createAction(
  CuppingSessionAction.FETCH_CUPPING_SESSION_STATUS,
  props<{ paginationRequest?: PaginationRequestParams }>(),
);

export const fetchCuppingSessionStatusSuccess = createAction(
  CuppingSessionAction.FETCH_CUPPING_SESSION_STATUS_SUCCESS,
  props<{ cuppingSessionStatus: CuppingSessionStatus[] }>(),
);

export const sendCupInvitation = createAction(
  CuppingSessionAction.SEND_INVITATION,
  props<{ cuppingInvitationId: number }>(),
);

export const storeInvitationEmailConfirmation = createAction(
  CuppingSessionAction.INVITATION_SENT,
  props<{ confirmation: EmailConfirmation }>(),
);

export const dddGuestEvaluator = createAction(
  CuppingSessionAction.ADD_GUEST_EVALUATOR,
  props<{ evaluator: GuestEvaluatorDto; cuppingSessionId: number }>(),
);

export const dddGuestEvaluators = createAction(
  CuppingSessionAction.ADD_GUEST_EVALUATORS,
  props<{ evaluators: GuestEvaluatorDto[]; cuppingSessionId: number }>(),
);

export const guestEvaluatorsCreatedSuccessful = createAction(
  CuppingSessionAction.GUEST_EVALUATOR_SUCCESSFULLY_CREATED,
  props<{ evaluators: CupEvaluator[] }>(),
);

export const guestEvaluatorsQueue = createAction(
  CuppingSessionAction.GUEST_EVALUATOR_QUEUE,
  props<{ evaluators: GuestEvaluatorDto[] }>(),
);

export const saveGuestEvaluatorsQueue = createAction(
  CuppingSessionAction.SAVE_GUEST_EVALUATORS_QUEUE,
  props<{ evaluators: GuestEvaluatorDto[]; cuppingSessionId: number; user: User; metadata: CuppingMasterData }>(),
);

export const resetAllSessions = createAction(CuppingSessionAction.CUPPING_SESSIONS_RESET);

export class CuppingMasterData {
  typeFunction: Function;
  redirectUrl: string;
  paginationRequestParams?: PaginationRequestParams;
  isoCode?: string;
}
