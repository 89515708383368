import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { SampleStatus } from './sample-status.model';

@Entity('sample', 'SampleApprovalInternalCondition', 'qc')
export class SampleApprovalInternalCondition extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized sampleStatus: SampleStatus;
}
