import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';

import {
  errorResponse,
  fetchCupEvaluators,
  fetchCuppingProcessBySessionAndEvaluator,
  fetchCuppingProcessSuccess,
  fetchLaboratories,
  fetchLaboratoriesSuccess,
  fetchStageProcess,
  fetchStageProcessSuccess,
  fetCupEvaluatorsSuccess,
  getCupEvaluatorById,
  getCupEvaluatorByIdSuccess,
  getCuppingProcess,
  getCuppingProcessSuccess,
  getCuppingSessionById,
  getCuppingSessionByIdSuccess,
  getLaboratoryById,
  getLaboratoryByIdSuccess,
  getMetricDefinitionTypeById,
  getMetricDefinitionTypeByIdSuccess,
  getSampleById,
  getSampleByIdSuccess,
  getStandardDefinitionById,
  getStandardDefinitionByIdSuccess,
  selectCuppingSession,
  setMasterDataSelectedItem,
  setResponsePending,
} from '../actions';
import { QueryService } from '../services/query.service';
import { isNullOrUndefined, mergeImmutable } from '../../../modules/utils/object-utils';
import { CuppingSessionService } from '../services/cupping-sesion.service';
import { CuppingProcessService } from '../services/cupping-process.service';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { StageProcessing } from '../../domain/stage-processing.model';
import { StandardDefinition } from '../../domain/standard-definition.model';
import { MetricsDefinitionType } from '../../domain/metrics-definition-type.model';
import { Laboratory } from '../../domain/laboratory.model';
import { Sample } from '../../domain/sample.model';
import { CupEvaluator } from '../../domain/cup-evaluator.model';
import { CuppingProcess } from '../../domain/cupping-process.model';
import { CuppingSession } from '../../domain/cupping-session.model';

@Injectable()
export class CuppingProcessEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly queryService: QueryService,
    private readonly cuppingProcessService: CuppingProcessService,
    private readonly cuppingSessionService: CuppingSessionService,
  ) {}

  public readonly fetchCuppingProcesses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCuppingProcessBySessionAndEvaluator),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination._include =
            '*,cuppingProcessMetrics(' +
            'cuppingProcessMetricDefects,cuppingProcessMetricOptions(standardsDefinitionEquivalences.metricsDefinitionOption),' +
            'standardDefinitionOption(metricsDefinition(metricsDefinitionTypeResult))' +
            '),standardDefinition(standardDefinitionOptionScoreDescriptions)';
          newPagination.size = null;
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.cuppingProcessService.getCuppingProcessBySessionAndEvaluator(
          action.cuppingSessionId,
          action.cuppingEvaluatorId,
          action.sampleId,
          action.paginationRequest,
        ),
      ),
      switchMap((cuppingProcesses: CuppingProcess[]) => [
        setResponsePending({ isResponsePending: false }),
        fetchCuppingProcessSuccess({ cuppingProcesses: cuppingProcesses }),
      ]),
    ),
  );

  public readonly getCuppingSession$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getCuppingSessionById),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination._include =
            '*,cuppingSessionSamples(sample(quality,sampleType,packageType)),' +
            'standardDefinition(standardDefinitionOptions(' +
            'metricsDefinition(metricsDefinitionOptions,metricsDefinitionType,metricsDefinitionTypeResult),' +
            'standardsDefinitionEquivalences(metricsDefinitionOption),' +
            'standardDefinitionOptionScoreDescriptions(metricDefinition))),' +
            'cupIllustration.cupIllustrationImage';
          newPagination.size = null;
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.queryService.querySearch<CuppingSession>(
          CuppingSession,
          action.paginationRequest,
          null,
          null,
          null,
          action.cuppingSessionId,
          false,
        ),
      ),
      switchMap(response => [
        getCuppingSessionByIdSuccess({ cuppingSession: response }),
        selectCuppingSession({ cuppingSession: response }),
      ]),
    ),
  );

  public readonly getCuppingProcess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getCuppingProcess),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination._include = '*';
          newPagination.size = null;
          newPagination.sort = 'sample.sample,ASC';
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.queryService.querySearch<CuppingProcess>(
          CuppingProcess,
          action.paginationRequest,
          null,
          null,
          null,
          action.cuppingProcessId,
          false,
        ),
      ),
      switchMap(cuppingProcess => [
        getCuppingProcessSuccess({ cuppingProcess: cuppingProcess }),
        setMasterDataSelectedItem({ selectedItem: cuppingProcess }),
      ]),
    ),
  );

  public readonly getCupEvaluator$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getCupEvaluatorById),
      switchMap(action =>
        this.queryService
          .querySearch(CupEvaluator, null, null, null, null, action.cupEvaluatorId, false)
          .then((cupEvaluator: CupEvaluator) => getCupEvaluatorByIdSuccess({ cupEvaluator: cupEvaluator }))
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly fetchLaboratory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLaboratories),
      map(payload => {
        const newAction = { ...payload };
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newPagination = new PaginationRequestParams();
          newPagination._include = '*';
          newPagination.size = null;
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        newAction.paginationRequest.sort = 'name,ASC';
        return newAction;
      }),
      switchMap(action => this.queryService.querySearch<Laboratory[]>(Laboratory, action.paginationRequest)),
      map(laboratory => fetchLaboratoriesSuccess({ laboratories: laboratory })),
    ),
  );

  public readonly getLaboratory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getLaboratoryById),
      switchMap(action =>
        this.queryService
          .querySearch(Laboratory, null, null, null, null, action.laboratoryId, false)
          .then((laboratory: Laboratory) => getLaboratoryByIdSuccess({ laboratory: laboratory }))
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly getSample$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getSampleById),
      switchMap(action =>
        this.queryService
          .querySearch(Sample, action.paginationRequest, null, null, null, action.sampleId, false)
          .then((sample: Sample) => getSampleByIdSuccess({ sample: sample }))
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly getStandardDefinition$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getStandardDefinitionById),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination._include =
            '*,standardDefinitionOptions(' +
            'metricsDefinition(metricsDefinitionOptions,metricsDefinitionType,metricsDefinitionTypeResult),' +
            'standardsDefinitionEquivalences(metricsDefinitionOption),' +
            'standardDefinitionOptionScoreDescriptions(metricDefinition)' +
            ')';
          newPagination.size = null;
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.queryService
          .querySearch(
            StandardDefinition,
            action.paginationRequest,
            null,
            null,
            null,
            action.standardDefinitionId,
            false,
          )
          .then((standardDefinition: StandardDefinition) =>
            getStandardDefinitionByIdSuccess({ standardDefinition: standardDefinition }),
          )
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly getMetricsDefinitionType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetricDefinitionTypeById),
      switchMap(action =>
        this.queryService
          .querySearch(MetricsDefinitionType, null, null, null, null, action.metricsDefinitionTypeId, false)
          .then((metricsDefinitionType: MetricsDefinitionType) =>
            getMetricDefinitionTypeByIdSuccess({ metricsDefinitionType: metricsDefinitionType }),
          )
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly cupEvaluator$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCupEvaluators),
      map(payload => {
        if (isNullOrUndefined(payload?.paginationRequest)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination._include = '*';
          newAction.paginationRequest = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action =>
        this.cuppingSessionService.loadCupEvaluatorByTenant(action.userDefaultTenantId, action.paginationRequest),
      ),
      map(cupEvaluator => fetCupEvaluatorsSuccess({ cupEvaluators: cupEvaluator })),
    ),
  );

  public readonly fetchStageProcessing$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchStageProcess),
      map(payload => {
        let newAction = { ...payload };
        const newPagination = new PaginationRequestParams();
        newPagination.sort = 'stageProcess,ASC';
        newAction = mergeImmutable(newAction, { paginationRequest: newPagination });
        return newAction;
      }),
      switchMap(action => this.queryService.querySearch(StageProcessing, action.paginationRequest)),
      map((stageProcessing: PaginatedResponse) => fetchStageProcessSuccess({ stageProcess: stageProcessing?.items })),
    ),
  );
}
