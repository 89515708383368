<div class="grid col-12">
  <div class="col-6">
    <ncs-text-basic
      [maxCharacterLength]="100"
      [label]="'common.labels.optionName'"
      [value]="currentChildItem.name"
      [validationResult]="validationResult?.fieldResults?.name"
      (onblur)="onItemFieldBlur('name')"
      (onchange)="onItemFieldChange($event, 'name')">
    </ncs-text-basic>
  </div>
  <div class="col-6">
    <ncs-text-basic
      [maxCharacterLength]="100"
      [label]="'common.labels.last_name'"
      [value]="currentChildItem.lastName"
      [validationResult]="validationResult?.fieldResults?.lastName"
      (onblur)="onItemFieldBlur('lastName')"
      (onchange)="onItemFieldChange($event, 'lastName')">
    </ncs-text-basic>
  </div>
  <div class="col-6">
    <ncs-text-basic
      placeholder="grid.labels.searchOoo"
      [maxCharacterLength]="100"
      [label]="'common.labels.username'"
      [value]="currentChildItem.username"
      [validationResult]="validationResult?.fieldResults?.username"
      (onblur)="onItemFieldBlur('username')"
      (onchange)="onUsernameChanged($event)">
    </ncs-text-basic>
    <span style="display: block" [attr.section-with-errors]="!usernameAvailable">
      <span *ngIf="!usernameAvailable">{{ 'common.validations.username_already_used' | translate }}</span>
    </span>
  </div>
  <div class="col-6">
    <ncs-text-basic
      [label]="'common.labels.email'"
      [value]="currentChildItem.email"
      [validationResult]="validationResult?.fieldResults?.email"
      (onblur)="onItemFieldBlur('email')"
      (onchange)="onEmailChanged($event)">
    </ncs-text-basic>
    <div class="col-6">
      <span [attr.section-with-errors]="!emailAvailable">
        <span *ngIf="!emailAvailable">{{ 'common.validations.email_already_used' | translate }}</span>
      </span>
    </div>
  </div>
  <div class="col-6">
    <ncs-text-basic
      [label]="'common.labels.optionCode'"
      [value]="currentChildItem.code"
      [validationResult]="validationResult?.fieldResults?.code"
      (onblur)="onItemFieldBlur('code')"
      (onchange)="onItemFieldChange($event, 'code')">
    </ncs-text-basic>
  </div>
  <div class="col-6">
    <ncs-text-basic
      [maxCharacterLength]="100"
      [label]="'common.labels.password'"
      [value]="currentChildItem.password"
      [validationResult]="validationResult?.fieldResults?.password"
      (onblur)="onItemFieldBlur('password')"
      (onchange)="onItemFieldChange($event, 'password')">
    </ncs-text-basic>
  </div>
  <div class="col-12">
    <ncs-textarea-basic
      [placeholder]="'common.labels.description'"
      [value]="currentChildItem.companyInfo"
      [maxLengthCharacter]="264"
      (onblur)="onItemFieldBlur('companyInfo')"
      (onchange)="onItemFieldChange($event, 'companyInfo')">
    </ncs-textarea-basic>
  </div>
</div>
