<span title="{{ title | translate }}" class="nkg-block" [ngStyle]="ngStyleLabel">
  <span class="mainCaption" *ngIf="label">{{ label | translate }}</span>
  <ncs-date-input
    [dateFormat]="dateFormat"
    [dateFormatDisplay]="dateFormatDisplay"
    [inputStyleClass]="inputStyleClass"
    [touchUI]="touchUI"
    [editable]="editable"
    [value]="value"
    [toTimezone]="toTimezone"
    [showIcon]="showIcon"
    [showTime]="showTime"
    [minDate]="minDate"
    [disabled]="disabled"
    [formatType]="formatType"
    [maxDate]="maxDate"
    [inline]="inline"
    [monthCompletion]="monthCompletion"
    [tabIndex]="tabIndex"
    [validationResult]="validationResult"
    (onblur)="onBlur($event)"
    (valueChange)="onChanges($event)"></ncs-date-input>
</span>
