export function extractEvent(event: any): any {
  function getEventValue(): any {
    return event?.value != undefined ? event.value : event;
  }

  function getEventTargetValue(): any {
    return event.target.value;
  }

  if (event?.target) {
    return getEventTargetValue();
  }
  return getEventValue();
}
