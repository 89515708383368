import { createReducer, on } from '@ngrx/store';

import * as SampleActionTypes from '../actions/sample.action';
import { ComtrasStatus } from '../../domain/comtras-status.model';
import { SampleStatus } from '../../domain/sample-status.model';
import { SampleType } from '../../domain/sample-type.model';
import { CoffeeCertification } from '../../domain/coffee-certification.model';
import { ReferenceType } from '../../domain/reference-type.model';
import { PackageType } from '../../domain/package-type.model';
import { Sample } from '../../domain/sample.model';
import { SampleApprovalInternalLog } from '../../domain/sample-approval-internal-log.model';
import { ApprovalStatusLog } from '../../domain/approval-status-log.model';

export interface SampleState {
  samples: Sample[];
  lastStatusLog: ApprovalStatusLog;
  sampleApprovalInternalLogs: SampleApprovalInternalLog[];
  sampleStatuses: SampleStatus[];
  referenceTypes: ReferenceType[];
  packageType: PackageType[];
  coffeeCertifications: CoffeeCertification[];
  sampleType: SampleType[];
  tenantSampleTypes: SampleType[];
  comtrasStatus: ComtrasStatus;
}

export const initialSampleState: SampleState = {
  samples: null,
  lastStatusLog: undefined,
  sampleApprovalInternalLogs: [],
  sampleStatuses: [],
  referenceTypes: [],
  packageType: [],
  coffeeCertifications: [],
  sampleType: [],
  tenantSampleTypes: [],
  comtrasStatus: undefined,
};

export const sampleReducer = createReducer(
  initialSampleState,
  on(
    SampleActionTypes.fetchSampleSuccess,
    (state: SampleState, { samples }): SampleState => ({
      ...state,
      samples: samples,
    }),
  ),
  on(
    SampleActionTypes.getLastApprovalStatusLogSuccess,
    (state: SampleState, { statusLog }): SampleState => ({
      ...state,
      lastStatusLog: statusLog,
    }),
  ),
  on(
    SampleActionTypes.getSampleApprovalInternalLogsSuccess,
    (state: SampleState, { sampleApprovalInternalLogs }): SampleState => ({
      ...state,
      sampleApprovalInternalLogs: sampleApprovalInternalLogs,
    }),
  ),
  on(
    SampleActionTypes.fetchSampleStatusSuccess,
    (state: SampleState, { samplesStatuses }): SampleState => ({
      ...state,
      sampleStatuses: samplesStatuses,
    }),
  ),
  on(
    SampleActionTypes.fetchReferenceTypeSuccess,
    (state: SampleState, { referenceTypes }): SampleState => ({
      ...state,
      referenceTypes: referenceTypes,
    }),
  ),
  on(
    SampleActionTypes.fetchPackageTypeSuccess,
    (state: SampleState, { packageType }): SampleState => ({
      ...state,
      packageType: packageType,
    }),
  ),
  on(
    SampleActionTypes.fetchCoffeeCertificationsSuccess,
    (state: SampleState, { coffeeCerts }): SampleState => ({
      ...state,
      coffeeCertifications: coffeeCerts,
    }),
  ),
  on(
    SampleActionTypes.fetchSampleTypeSuccess,
    (state: SampleState, { sampleType }): SampleState => ({
      ...state,
      sampleType: sampleType,
    }),
  ),
  on(
    SampleActionTypes.fetchSampleTypesForTenantSuccess,
    (state: SampleState, { sampleTypes }): SampleState => ({
      ...state,
      tenantSampleTypes: sampleTypes,
    }),
  ),
  on(
    SampleActionTypes.getCOMTRASStatusActionSuccess,
    (state: SampleState, { status }): SampleState => ({
      ...state,
      comtrasStatus: status,
    }),
  ),
);

export const getSample = (state: SampleState): Sample[] => state.samples;
export const getLastStatusLog = (state: SampleState): ApprovalStatusLog => state.lastStatusLog;
export const getSampleApprovalInternalLogs = (state: SampleState): SampleApprovalInternalLog[] =>
  state.sampleApprovalInternalLogs;
export const getSampleStatuses = (state: SampleState): SampleStatus[] => state.sampleStatuses;
export const getReferenceTypes = (state: SampleState): ReferenceType[] => state.referenceTypes;
export const getPackageType = (state: SampleState): PackageType[] => state.packageType;
export const getCoffeeCertifications = (state: SampleState): CoffeeCertification[] => state.coffeeCertifications;
export const getSampleType = (state: SampleState): SampleType[] => state.sampleType;
export const getTenantSampleTypes = (state: SampleState): SampleType[] => state.tenantSampleTypes;
export const getComtrasStatus = (state: SampleState): ComtrasStatus => state.comtrasStatus;
