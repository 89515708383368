import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { NcsNumberInputComponent } from './components/ncs-number-input.component';
import { NcsTooltipSharedModule } from '../tooltip/tooltip-shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, InputTextModule, TooltipModule, FormsModule, NcsTooltipSharedModule],
  declarations: [NcsNumberInputComponent],
  exports: [NcsNumberInputComponent],
})
export class NcsNumberInputSharedModule {}
