import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { MetricDefinition } from './metric-definition.model';
import { StandardDefinitionOptionScoreDescription } from './standard-definition-option-score-description.model';
import { StandardsDefinitionEquivalences } from './standards-definition-equivalences.model';

@Entity('masterdata', 'StandardDefinitionOption', 'qc')
export class StandardDefinitionOption extends AbstractVersionedEntity {
  @Serialized optionAddText: boolean;
  @Serialized optionGraph: boolean;
  @Serialized onlyOption: boolean;
  @Serialized optionValMin: number;
  @Serialized optionValMax: number;
  @Serialized metricsDefinition: MetricDefinition;
  @Serialized({
    elementType: StandardsDefinitionEquivalences,
    composite: true,
  })
  standardsDefinitionEquivalences: StandardsDefinitionEquivalences[] = [];
  @Serialized({
    elementType: StandardDefinitionOptionScoreDescription,
    composite: true,
  })
  standardDefinitionOptionScoreDescriptions: StandardDefinitionOptionScoreDescription[] = [];
  @Serialized position: number;
}
