import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { BagType } from './bag-type.model';

@Entity('sample', 'PackageType', 'qc')
export class PackageType extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized comtrasReference: string;
  @Serialized kgPackage: number;
  @Serialized tara: number;
  @Serialized({ elementType: BagType }) bagTypes?: BagType[] = [];
}
