import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NcsDateComponent } from './components/ncs-date.component';
import { NcsDateInputSharedModule } from '../date-input/date-input-shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, NcsDateInputSharedModule],
  declarations: [NcsDateComponent],
  exports: [NcsDateComponent],
})
export class NcsDateSharedModule {}
