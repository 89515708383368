import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { NcsTableCrudComponent } from './components/ncs-table-crud.component';
import { NcsDialogBasicSharedModule } from '../../dialog-basic/dialog-basic-shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, TableModule, ButtonModule, NcsDialogBasicSharedModule],
  declarations: [NcsTableCrudComponent],
  exports: [NcsTableCrudComponent],
})
export class NcsTableCrudSharedModule {}
