import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '../../../../modules/utils/object-utils';
import { isNumber } from '../../../../modules/utils/number-utils';

@Component({
  templateUrl: './custom-number-filter.component.html',
})
/** *
 *  Custom Number filter
 *  @see https://www.ag-grid.com/documentation/angular/component-filter/
 *  Use ncs SelectBox and textBasic component
 */
export class CustomNumberFilterComponent implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  value: number;
  options: SelectItem[] = [];
  filterTypeSelect: SelectItem;
  filterTypes = {
    equals: (a, b): boolean => Number(a) == Number(b),
    lessThan: (a, b): boolean => Number(a) <= Number(b),
    greaterThan: (a, b): boolean => Number(a) >= Number(b),
  };
  isLocalFilter: boolean = false;
  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.options = [];
    this.options = this.loadNumberFilterOptions();
    this.filterTypeSelect = this.options[0];
    const { context } = params;
    // WARN: context setting when create GridOptions as context params
    if (!isNullOrUndefined(context)) {
      this.isLocalFilter = context.isFilterLocal();
    }
  }
  constructor(private readonly translateService: TranslateService) {}

  // The grid will ask each active filter, in turn, whether each row in the grid passes. If any
  // filter fails, then the row will be excluded from the final set. A params object is supplied
  // containing attributes of node (the rowNode the grid creates that
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    // Disable double filter check because filtering could be applied on server
    // Otherwise, will be applying again current filter on new rowData filtered from server
    return this.isLocalFilter
      ? this.filterTypes[this.filterTypeSelect.value](this.valueGetter(params.node), this.value)
      : true;
  }

  // Gets the filter state. If filter is not active, then should return null/undefined.
  // The grid calls getModel() on all active filters when gridApi.getFilterModel() is called.
  getModel(): any {
    if (isNumber(this.value) && this.filterTypeSelect)
      return {
        value: this.value,
        filterType: 'customTextFilter',
        type: this.filterTypeSelect.value,
      };
    return null;
  }

  isFilterActive(): boolean {
    return isNumber(this.value);
  }

  // Restores the filter state. Called by the grid after gridApi.setFilterModel(model) is called.
  // The grid will pass undefined/null to clear the filter.
  setModel(model: any): void {
    this.value = Number(model?.value);
    if (model) {
      this.filterTypeSelect = this.options.find(filter => filter.value == model.type) || this.options[0];
    }
  }

  public onChange(value: number): void {
    if (isNumber(value) && value !== this.value) {
      this.value = value;
      this.params.filterModifiedCallback();
    }
  }

  public onChangeFilterType(valueSelection: SelectItem): void {
    this.filterTypeSelect = valueSelection || this.options[0];
    if (this.value) {
      // used to apply filters to backend and update rowData (server data)
      this.params.filterModifiedCallback();
      // AgGrid need to know when filter changed and apply new filters (local)
      this.params.filterChangedCallback();
    }
  }

  /** Limit options for number
   * All available options to
   * @class FilterGridOptionsEnum
   */
  loadNumberFilterOptions(): SelectItem[] {
    return [
      {
        value: 'equals',
        label: this.translateService.instant('grid.labels.equals'),
      },
      // Hibernate search taken lessThan like lessThanOrEqual comparison
      {
        value: 'lessThan',
        label: this.translateService.instant('grid.labels.lessThanOrEqual'),
      },
      // Hibernate search taken lessThan like greaterThanOrEqual comparison
      {
        value: 'greaterThan',
        label: this.translateService.instant('grid.labels.greaterThanOrEqual'),
      },
    ];
  }
}
