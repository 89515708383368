import { Component } from '@angular/core';
import { IDateParams } from 'ag-grid-community';
import { IDateAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'custom-date',
  templateUrl: './custom-date.component.html',
})
/**
 * Custom Date filter component
 * @See https://www.ag-grid.com/documentation/javascript/component-date/
 */
export class CustomDateComponent implements IDateAngularComp {
  date: Date;
  private params: IDateParams;

  agInit(params: IDateParams): void {
    this.params = params;
  }

  getDate(): Date {
    return this.date;
  }

  setDate(date: Date): void {
    this.date = date;
  }

  onChange(date: Date): void {
    this.setDate(date);
    this.params.onDateChanged();
    // this.params.filterParams.filterModifiedCallback();
  }
}
