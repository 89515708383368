import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { ThirdPartyType } from './third-party-type.model';
import { StandardDefinition } from './standard-definition.model';
import { SampleTypeTenant } from './sample-type-tenant.model';

@Entity('sample', 'SampleType', 'qc')
export class SampleType extends AbstractVersionedEntity {
  @Serialized sampleType: string;
  @Serialized codeSampleType: string;
  @Serialized referenceType: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized requireShipping?: boolean;
  @Serialized toSplit?: boolean;
  @Serialized requireApproval?: boolean;
  @Serialized openOffer?: boolean;
  @Serialized({
    elementType: SampleTypeTenant,
    composite: true,
  })
  sampleTypeTenants: SampleTypeTenant[];
}
