import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import {
  fetchApprovalStatus,
  fetchApprovalStatusSuccess,
  fetchDispatchStatus,
  fetchDispatchStatusSuccess,
} from '../actions';
import { QueryService } from '../services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { ApprovalStatus } from '../../domain/approval-status.model';
import { SampleDispatchStatus } from '../../domain/sample-dispatch-status.model';

@Injectable()
export class DispatchEffects {
  constructor(
    private readonly queryService: QueryService,
    private readonly actions$: Actions,
  ) {}

  public readonly fetchSampleStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchApprovalStatus),
      switchMap(() => this.queryService.querySearch(ApprovalStatus)),
      map((statuses: ApprovalStatus[]) => fetchApprovalStatusSuccess({ approvalStatuses: statuses })),
    ),
  );

  public readonly fetchSampleDispatchStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDispatchStatus),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'code,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(SampleDispatchStatus, action.pagination)),
      map((response: PaginatedResponse) => fetchDispatchStatusSuccess({ sampleDispatchStatuses: response.items })),
    ),
  );
}
