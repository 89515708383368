import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import {
  fetchCoffeeSpecies,
  fetchCoffeeSpeciesSuccess,
  fetchQualityBrandings,
  fetchQualityBrandingSuccess,
  fetchQualityItems,
  fetchQualityItemSuccess,
  fetchQualityMains,
  fetchQualityMainSuccess,
  fetchQualityType,
  fetchQualityTypeSuccess,
} from '../actions';
import { QueryService } from '../services/query.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { CoffeeSpecie } from '../../domain/coffee-specie.model';
import { QualityType } from '../../domain/quality-type.model';
import { QualityMain } from '../../domain/quality-main.model';
import { QualityItem } from '../../domain/quality-item.model';
import { QualityAdditionalBranding } from '../../domain/quality-additional-branding.model';

@Injectable()
export class QualityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly queryService: QueryService,
  ) {}

  public readonly fetchQualityType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQualityType),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(QualityType, action.pagination)),
      map((qualityType: PaginatedResponse) => fetchQualityTypeSuccess({ qualityTypes: qualityType.items })),
    ),
  );

  public readonly fetchCoffeeSpecies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCoffeeSpecies),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'coffeeSpecie,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(CoffeeSpecie, action.pagination)),
      map((response: PaginatedResponse) => fetchCoffeeSpeciesSuccess({ coffeeSpecies: response.items })),
    ),
  );

  public readonly fetchQualityItems$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQualityItems),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(QualityItem, action.pagination)),
      map((resp: PaginatedResponse) => fetchQualityItemSuccess({ qualityItems: resp.items })),
    ),
  );

  public readonly fetchQualityMain$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQualityMains),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(QualityMain, action.pagination)),
      map((resp: PaginatedResponse) => fetchQualityMainSuccess({ qualityMains: resp.items })),
    ),
  );

  public readonly fetchQualityBranding$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchQualityBrandings),
      map(payload => {
        if (isNullOrUndefined(payload?.pagination)) {
          const newAction = { ...payload };
          const newPagination = new PaginationRequestParams();
          newPagination.sort = 'name,ASC';
          newAction.pagination = newPagination;
          return newAction;
        }
        return payload;
      }),
      switchMap(action => this.queryService.querySearch(QualityAdditionalBranding, action.pagination)),
      map((resp: PaginatedResponse) => fetchQualityBrandingSuccess({ qualityBrandings: resp.items })),
    ),
  );
}
