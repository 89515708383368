import { Injectable } from '@angular/core';
import * as log from 'loglevel';
import * as remoteLog from 'loglevel-plugin-remote';
import * as StackTrace from 'stacktrace-js';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractCeisService } from '../ngrx/services/app.abstract.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogService {
  private readonly logger: any;

  constructor() {
    if (this.useRemoteLogging()) {
      // configure remote logging plugin for loglevel
      remoteLog.apply(log, {
        url: '/logging', // logging url of our backend
        format: remoteLog.json, // use default json output for backend communication
        stacktrace: {
          levels: [], // stacktraces do not get generated in this plugin because we need sourcemap support for prod mode
        },
      });
    }
    this.logger = log;

    // in production we log on info level, in dev mode we go for debug
    this.logger.setLevel(environment.production ? 'info' : 'debug');

    // dmn eval library should log on warn level in production, on info level in dev mode
    log.getLogger('dmn-eval-js').setLevel(environment.production ? 'warn' : 'info');
  }

  public serverException(fieldMessage: any, traceId?: string): void {
    StackTrace.get().then(stackFrames => {
      this.setCurrentToken();
      stackFrames = stackFrames.slice(3, stackFrames.length);
      traceId = fieldMessage.uuid || traceId;
      this.logger.error(
        `Exception from Server ${fieldMessage.fieldPath} ${fieldMessage.msgKey}${
          traceId ? `   (Trace-Id: ${traceId})` : ''
        }\n    ${stackFrames.join('\n    ')}`,
      );
    });
  }

  public error(error: any, traceId?: string): void {
    const traceIdInfo = traceId ? `(Trace-Id: ${traceId})` : '';
    if (error instanceof Error) {
      StackTrace.fromError(error).then(stackFrames => {
        this.setCurrentToken();
        this.logger.error(`${error.message + traceIdInfo}\n    ${stackFrames.join('\n    ')}`);
      });
    } else if (error instanceof HttpErrorResponse) {
      const { url } = error;
      while (error.error) error = error.error;

      StackTrace.fromError(error).then(stackFrames => {
        this.setCurrentToken();
        this.logger.error(`${url}: ${error.message} ${traceIdInfo}\n    ${stackFrames.join('\n    ')}`);
      });
    } else {
      StackTrace.get().then(stackFrames => {
        this.setCurrentToken();
        stackFrames = stackFrames.slice(3, stackFrames.length);
        this.logger.error(`${error + traceIdInfo}\n    ${stackFrames.join('\n    ')}`);
      });
    }
  }

  public warn(message: any): void {
    this.setCurrentToken();
    this.logger.warn(message);
  }

  public info(message: any): void {
    this.setCurrentToken();
    this.logger.info(message);
  }

  public debug(message: any): void {
    this.setCurrentToken();
    this.logger.debug(message);
  }

  public trace(message: any): void {
    this.setCurrentToken();
    this.logger.trace(message);
  }

  private setCurrentToken(): void {
    if (this.useRemoteLogging()) {
      remoteLog.setToken(window.localStorage.getItem(AbstractCeisService.STORAGE_KEY_JWT_TOKEN));
    }
  }

  private useRemoteLogging(): boolean {
    return environment.production;
  }
}
