import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { ThirdPartyType } from './third-party-type.model';
import { ThirdParty } from './third-party.model';

@Entity('sample', 'SampleThirdPartyType', 'qc')
export class SampleThirdPartyType extends AbstractVersionedEntity {
  @Serialized reference: string;
  @Serialized thirdPartyType: ThirdPartyType;
  @Serialized thirdParty: ThirdParty;
  @Serialized ourSalesReference: string;
  @Serialized bags: number;
}
