import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { StandardDefinitionOption } from './standard-definition-option.model';
import { StandardDefinitionOptionScoreDescription } from './standard-definition-option-score-description.model';
import { CuppingProcessMetricDefect } from './cupping-process-metric-defect.model';
import { CuppingProcessMetricOption } from './cupping-process-metric-option.model';

@Entity('cupping', 'CuppingProcessMetric', 'qc')
export class CuppingProcessMetric extends AbstractVersionedEntity {
  @Serialized standardDefinitionOption: StandardDefinitionOption;
  @Serialized value: number; // score value
  @Serialized valueResult: number; //  general standard option
  @Serialized comment?: string;
  @Serialized cupsTotal?: number;
  @Serialized cupsCheck?: number;
  @Serialized cupsValue?: number;
  @Serialized percentSampleWeight?: number;
  @Serialized percentValue?: number;
  @Serialized position: number;
  @Serialized minValue: number;
  @Serialized maxValue: number;
  @Serialized step: number;
  @Internal scoreDescriptions?: StandardDefinitionOptionScoreDescription[] = [];
  @Serialized({
    elementType: CuppingProcessMetricDefect,
    composite: true,
  })
  cuppingProcessMetricDefects?: CuppingProcessMetricDefect[] = [];
  @Serialized({
    elementType: CuppingProcessMetricOption,
    composite: true,
  })
  cuppingProcessMetricOptions?: CuppingProcessMetricOption[] = [];
  // Used for all unsaved options
  @Internal
  additionalCuppingProcessMetricOptions?: CuppingProcessMetricOption[] = [];
}
