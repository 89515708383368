import { createAction, props } from '@ngrx/store';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { StandardDefinition } from '../../domain/standard-definition.model';
import { StandardDefinitionOption } from '../../domain/standard-definition-option.model';

export enum StandardDefinitionAction {
  FETCH_STANDARD_DEF_OPTIONS = '[Standard Definition] Request Standard Definitions Options',
  FETCH_STANDARD_DEF_OPTIONS_SUCCESS = '[Standard Definition] Request Standard Definitions Options success',
  FETCH_STANDARD_DEFINITION = '[Standard Definition] Request Standard Definition',
  FETCH_STANDARD_DEFINITION_SUCCESS = '[Standard Definition] Request Standard Definition success',
}

export const fetchStandardDefOpt = createAction(StandardDefinitionAction.FETCH_STANDARD_DEF_OPTIONS);

export const fetchStandardDefOptSuccess = createAction(
  StandardDefinitionAction.FETCH_STANDARD_DEF_OPTIONS_SUCCESS,
  props<{ standardDefOptions: StandardDefinitionOption[] }>(),
);

export const fetchStandardDefinition = createAction(
  StandardDefinitionAction.FETCH_STANDARD_DEFINITION,
  props<{ userDefaultTenantId: number; paginationRequestParams?: PaginationRequestParams }>(),
);

export const fetchStandardDefinitionSuccess = createAction(
  StandardDefinitionAction.FETCH_STANDARD_DEFINITION_SUCCESS,
  props<{ standardDefinition: StandardDefinition[] }>(),
);
