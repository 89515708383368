import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthSelector } from '../selectors';
import * as fromRoot from '../reducers';
import { fetchCurrentUser, getAppMenu } from '../actions';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { NkgMenuItem } from '../../models/nkg-menu-item.model';
import { isArrayEmpty } from '../../../modules/utils/array-utils';
import { User } from '../../domain/user.model';

@Injectable()
export class AuthStoreService {
  private readonly store: Store<fromRoot.State>;
  private readonly authSelector: AuthSelector;
  private isFetching: boolean = false;

  public constructor(store: Store<fromRoot.State>, selector: AuthSelector) {
    this.store = store;
    this.authSelector = selector;
  }

  public selectCurrentUser(): Observable<User> {
    return this.store.select(this.authSelector.currentUser).pipe(
      map(user => {
        this.isFetching = isNullOrUndefined(user);
        return user;
      }),
    );
  }

  public requestCurrentUser(): Promise<boolean> {
    if (!this.isFetching) {
      this.isFetching = true;
      this.store.dispatch(fetchCurrentUser());
    }
    return Promise.resolve(true);
  }

  public requestMenu(): Promise<boolean> {
    let menu: NkgMenuItem[];
    this.awaitAppMenu().subscribe(appMenu => (menu = appMenu));
    if (isArrayEmpty(menu)) {
      this.store.dispatch(getAppMenu());
    }
    return Promise.resolve(true);
  }

  private awaitAppMenu(): Observable<NkgMenuItem[]> {
    return this.store.select(this.authSelector.appMenu).pipe(
      map(appMenu => {
        return appMenu;
      }),
    );
  }
}
