import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Message, MessageService } from 'primeng/api';
import * as log from 'loglevel';
import { AppStoreService } from '../ngrx/store/app.store';
import * as fromRoot from '../ngrx/reducers';
import { logout, setResponsePending } from '../ngrx/actions';
import { TimeService } from '../ngrx/services/time-service';
import { isNullOrUndefined } from '../../modules/utils/object-utils';
import { AuthorizationService } from '../ngrx/services/authorization.service';
import { setToastNotification, ToastSeverityEnum } from '../ngrx/utils/notification-utils';
import { AppNotification } from '../domain/app-notification.model';
import { isEmpty } from '../../modules/utils/string-utils';

const uuidv4 = require('uuid').v4;

@Injectable()
export class BackendPendingInterceptor implements HttpInterceptor {
  private readonly authorizationService: AuthorizationService;
  private refreshingToken: boolean = false;
  private readonly tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly injector: Injector,
    protected store: Store<fromRoot.State>,
    authorizationService: AuthorizationService,
    private readonly messageService: MessageService,
  ) {
    this.authorizationService = authorizationService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(async (error: HttpErrorResponse) => {
        this.store.dispatch(setResponsePending({ isResponsePending: false }));
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            const tokenRecover = await this.onRecoverToken(req, next, error);
            if (!isNullOrUndefined(tokenRecover)) {
              return tokenRecover;
            }
            if (req.url === this.authorizationService.loginUrl) {
              return this.authorizationService.logout().then(() => Promise.resolve(true));
            }
          }

          if (req.url === this.authorizationService.getLogoutUrl()) {
            this.authorizationService.clearWebStorage();
            return Promise.resolve(true);
          }
          if (error.status != 500) {
            this.handleGeneralError(error);
          }
        }
        return throwError(() => error);
      }) as any,
    );
  }

  private async onRecoverToken(req: HttpRequest<any>, next: HttpHandler, error: any): Promise<any> {
    if (req.url !== this.authorizationService.loginUrl && req.url !== this.authorizationService.getLogoutUrl()) {
      // it means that request failed due to the authorization token. But, sometimes backend returns a 401
      // - when a request has no authorization to the endpoint, it does not means that the token has been
      // -expired, so we check that and if, the token has expired, then we refresh it
      const response: { token: string; message: string; status: number } = error.error;

      switch (response.message) {
        case 'TOKEN_EXPIRED':
          if (this.refreshingToken) {
            return this.tokenSubject.pipe(
              filter(x => !isNullOrUndefined(x)),
              take(1),
              switchMap(token => next.handle(this.cloneAuthorizedRequest(req, token))),
            );
          }
          this.refreshingToken = true;
          this.tokenSubject.next(null);

          return from(this.authorizationService.refreshToken(this.authorizationService.getRefreshToken())).pipe(
            tap((token: string) => this.tokenSubject.next(token)),
            switchMap((token: string) => {
              this.refreshingToken = false;
              return next.handle(this.cloneAuthorizedRequest(req, token));
            }),
            catchError(() => {
              // we've failed trying to refresh the token. we leave
              // this.tokenSubject.unsubscribe();
              // this.store.dispatch(new LogOut());
              log.warn('Refreshing the token has failed. we logout');
              this.refreshingToken = false;
              this.tokenSubject.unsubscribe();
              this.store.dispatch(logout());
              return Promise.resolve(true);
            }),
          );

        case 'TOKEN_INVALID':
          // then we need to logout to close this session and get a valid token
          this.handleGeneralError(error);
          this.store.dispatch(logout());
          return Promise.resolve(true);

        case 'MISSING_PRIVILEGES':
          this.handleGeneralError(error);
          break;
        default:
          break;
      }
    }
  }

  protected cloneAuthorizedRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (isNullOrUndefined(token)) {
      return null;
    }
    return request.clone({
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    });
  }

  protected handleGeneralError(error: HttpErrorResponse): void {
    const traceId = uuidv4();
    try {
      if (error?.error) {
        try {
          const fieldMessages = error.error;
          if (
            fieldMessages.length > 0 &&
            fieldMessages[0] && // e.g. no attribute length in Object
            (fieldMessages[0]?.msgKey || fieldMessages[0]?.fieldPath)
          ) {
            // show notifications for all messages
            Object.values(fieldMessages).forEach((fieldMessage: any) => this.handleErrorFieldMessage(fieldMessage));
          }
        } catch (e) {
          this.handleErrorResponse(error, traceId);
        }
        // server error without fieldmessages? -> just show error from HTTP response directly
      } else {
        // not a server error -> send log event back to server and show notification
        // this.injector.get(LogService).error(error, traceId);
        this.handleUnknownError(traceId);
      }
    } catch (e) {
      // something went wrong -> send log event back to server and show notification
      // this.injector.get(LogService).error(error, traceId); // send error back to server
      this.handleUnknownError(traceId);
    }
  }

  /**
   * Translate and set the message field of a notification object:
   * 'message' if isoCode for 'notification.<message>' exists,
   * isoCode of 'message' if isoCode for '<message>' exists
   * or '<notificationTypeTranslationMissing>' with 'message' as first parameter
   * if 'message' cannot be translated.
   *
   * @param {AppNotification} notification
   * @param {string} message message text
   * @param {string} notificationTypeTranslationMissing notification text if no isoCode for message exists
   * @returns {string}
   */
  translateErrorMessage(
    notification: AppNotification,
    message: string,
    notificationTypeTranslationMissing: string,
  ): boolean {
    if (this.hasTranslation(`notification.${message}`)) {
      notification.message = message;
      return true;
    }
    if (this.hasTranslation(message)) {
      notification.param0 = this.injector.get(TranslateService).instant(message, {
        param0: notification.param0,
        param1: notification.param1,
        param2: notification.param2,
        param3: notification.param3,
        param4: notification.param4,
        param5: notification.param5,
        uuid: notification.uuid,
      });
      notification.message = 'unknown'; // e.g. use 'notification.unknown', parameter 0 is the actual message text
      return true;
    }
    notification.param0 = message;
    notification.message = notificationTypeTranslationMissing;
    return false;
  }

  /**
   * Handle a field message from a HTTP error JSON response.
   * @param errorFieldMessage field
   */
  handleErrorFieldMessage(errorFieldMessage: any): void {
    const notification: AppNotification = new AppNotification();
    notification.level = this.getNotificationLevel(errorFieldMessage.level);
    notification.uuid = errorFieldMessage.uuid || '';
    notification.param0 = this.translateParam(errorFieldMessage.msgParam1);
    notification.param1 = this.translateParam(errorFieldMessage.msgParam2);
    notification.param2 = this.translateParam(errorFieldMessage.msgParam3);
    notification.param3 = this.translateParam(errorFieldMessage.msgParam4);
    notification.param4 = this.translateParam(errorFieldMessage.msgParam5);
    // exception on server side -> check if there is a isoCode just for this
    // exception type (gridViewName of exception inside of fieldPath)
    if (errorFieldMessage.level == 'EXCEPTION') {
      if (
        isNullOrUndefined(errorFieldMessage.fieldPath) ||
        !this.translateErrorMessage(notification, errorFieldMessage.fieldPath.toLowerCase(), 'unknown_exception')
      ) {
        this.translateErrorMessage(
          notification,
          errorFieldMessage.msgKey || errorFieldMessage.fieldPath,
          'unknown_exception',
        );
      }
    } else {
      this.translateErrorMessage(notification, errorFieldMessage.msgKey || errorFieldMessage.fieldPath, 'unknown');
    }
    this.showNotification(notification);
  }

  /**
   * Handle HTTP error response without JSON field messages.
   *
   * @param errorResponse
   * @param traceId unique error id
   */
  handleErrorResponse(errorResponse: any, traceId: any): void {
    const notification: AppNotification = new AppNotification();
    notification.level = 'ERROR';
    notification.uuid = traceId;
    notification.param0 = traceId;
    notification.param1 = errorResponse.status;
    notification.param2 = errorResponse.error;
    if (errorResponse.status == 504 || errorResponse.status == 403) {
      this.translateErrorMessage(notification, errorResponse.error, 'server_error_timeout');
    } else {
      this.translateErrorMessage(notification, errorResponse.error, 'server_error_response');
    }
    this.showNotification(notification);
  }

  /**
   * Handle unknown errors.
   *
   * @param traceId unique error id
   */
  handleUnknownError(traceId: any): void {
    const notification: AppNotification = new AppNotification();
    notification.level = 'ERROR';
    notification.uuid = traceId;
    notification.param0 = traceId;
    notification.message = 'technical_error_occurred';
    this.showNotification(notification);
  }

  /**
   * Shows a frontend notification.
   *
   * @param {AppNotification} notification
   */
  private showNotification(notification: AppNotification): void {
    notification.createdAt = this.injector.get(TimeService).obtainNow();
    notification.type = 'FRONTEND_MESSAGE';
    log.info(notification);
    const toastMessage: Message = setToastNotification({
      summary: notification.param0.replaceAll('<br>', '\n'),
      severity: ToastSeverityEnum.WARNING,
      translateService: this.injector.get(TranslateService),
      sticky: true,
    });
    this.messageService.add(toastMessage);
    this.injector.get(AppStoreService).addNotification(notification, true);
  }

  /**
   * Check if an isoCode for a message exists.
   *
   * @param {string} message the message text (e.g. plain text or text identifier like 'notification.unknown')
   * @returns {boolean} true if message can be translated
   */
  private hasTranslation(message: string): boolean {
    if (message && message.indexOf(' ') <= 0 && !isEmpty(message)) {
      const translation = this.injector.get(TranslateService).instant(message);
      if (translation.match(message) === null) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get notification level: error, warn, info
   * @param level
   * @returns {string}
   */
  private getNotificationLevel(level: any): string {
    if (level == 'WARN') {
      return 'WARN';
    }
    if (level == 'INFO') {
      return 'INFO';
    }
    return 'ERROR';
  }

  private translateParam(param: string): string {
    return isNullOrUndefined(param) ? '' : this.injector.get(TranslateService).instant(param);
  }
}
