import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UrlSafePipe } from './url-safe.pipe';
import { SelectItemPipePipe } from './select-item-pipe.pipe';
import { DatePipeProxy } from './date-pipe';
import { ResourceSafePipe } from './resource-safe.pipe';

@NgModule({
  imports: [TranslateModule, CommonModule],
  declarations: [DatePipeProxy, SelectItemPipePipe, UrlSafePipe, ResourceSafePipe],
  exports: [DatePipeProxy, SelectItemPipePipe, UrlSafePipe, ResourceSafePipe],
  providers: [],
})
export class PipesSharedModule {}
