import { AbstractEntity } from '../../../core/domain/abstract-entity.model';
import { Language } from '../../../core/domain/language.model';

export class NcsLanguageTranslation extends AbstractEntity {
  language: Language;
  translation: string;

  constructor(language: Language, translation: string) {
    super();
    this.translation = translation;
    this.language = language;
  }
}
