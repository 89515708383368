import { PaginationRequestParams } from '../../../core/domain/pagination-request-params.model';

const paginationSize: number = 10;
export function coffeeVarietyRequestParams(): PaginationRequestParams {
  return {
    columns: 'coffeeVariety',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'coffeeVariety,ASC',
  };
}

export function countryRequestParams(): PaginationRequestParams {
  return {
    columns: 'country,alphaCode2,alphaCode3',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'country,ASC',
  };
}

export function cupEvaluatorRequestParams(): PaginationRequestParams {
  return {
    columns: 'cupEvaluator,codeCupEvaluator',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'cupEvaluator,ASC',
  };
}

export function dryingProcessRequestParams(): PaginationRequestParams {
  return {
    columns: 'dryingProcess',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'dryingProcess,ASC',
  };
}

export function harvestRequestMethod(): PaginationRequestParams {
  return {
    columns: 'harvestMethod',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'harvestMethod,ASC',
  };
}

export function laboratoryRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export function metricDefinitionRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code',
    _include: '*,metricsDefinition(metricsDefinitionOptions)',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export function metricOptionRequestParams(): PaginationRequestParams {
  return {
    columns: 'optionName,optionCode',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'optionName,ASC',
  };
}
export function millingProcessRequestParams(): PaginationRequestParams {
  return {
    columns: 'millingProcess,codeMillingProcess',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'millingProcess,ASC',
  };
}

export function coffeeSpecieRequestParams(): PaginationRequestParams {
  return {
    columns: 'coffeeSpecie',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'coffeeSpecie,ASC',
  };
}

export function qualityRequestParams(): PaginationRequestParams {
  return {
    _s: '',
    q: '',
    sort: 'name,ASC',
    size: paginationSize,
    page: 0,
    columns:
      'name,code,coffeeSpecie.coffeeSpecie,stageProcessing.stageProcess,' +
      'millingProcess.millingProcess,qualityType.name,country',
    _include: '*,qualityStageProcessing(stageProcessing)',
    deleted: false,
  };
}

export function sampleRequestParams(): PaginationRequestParams {
  return {
    columns: 'id,sample,sampleReference,ourReference',
    _include: '*,quality(stageProcessing)',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'date,ASC',
  };
}

export function sampleTypeRequestParams(): PaginationRequestParams {
  return {
    columns: 'sampleType,referenceType',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'sampleType,ASC',
  };
}

export function soilTypeRequestParams(): PaginationRequestParams {
  return {
    columns: 'soilType',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'soilType,ASC',
  };
}

export function standardRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: 0,
    sort: 'name,ASC',
  };
}

export function storageTypeRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code',
    _include: '*',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}
export function tenantRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,abbreviation,codeGroupCompany',
    _include: 'agencies.agency,country.country',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export function thirdPartyRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export function userRequestParams(): PaginationRequestParams {
  return {
    columns: 'username,displayName,firstName',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'username,ASC',
  };
}

export function qualityItemRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code,stageProcessing.stageProcess,coffeeSpecie.coffeeSpecie',
    _include: '*,stageProcessing,coffeeSpecie',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export function qualityMainRequestParams(): PaginationRequestParams {
  return {
    columns: 'name,code',
    _include: '',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export function qualityBrandingRequestParams(): PaginationRequestParams {
  return {
    _include: '',
    columns: 'name,code',
    _s: '',
    deleted: false,
    page: 0,
    q: '',
    size: paginationSize,
    sort: 'name,ASC',
  };
}

export interface LookupPaginationRequestParams {
  coffeeVariety?: PaginationRequestParams;
  country?: PaginationRequestParams;
  cupEvaluator?: PaginationRequestParams;
  dryingProcess?: PaginationRequestParams;
  harvest?: PaginationRequestParams;
  laboratory?: PaginationRequestParams;
  metricDefinition?: PaginationRequestParams;
  millingProcess?: PaginationRequestParams;
  coffeeSpecie?: PaginationRequestParams;
  quality?: PaginationRequestParams;
  sample?: PaginationRequestParams;
  sampleType?: PaginationRequestParams;
  soilType?: PaginationRequestParams;
  standardDefinition?: PaginationRequestParams;
  storageType?: PaginationRequestParams;
  tenant?: PaginationRequestParams;
  thirdParty?: PaginationRequestParams;
  qualityItem?: PaginationRequestParams;
  qualityMain?: PaginationRequestParams;
  qualityBranding?: PaginationRequestParams;
  user?: PaginationRequestParams;
}
