import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { CoffeeSpecie } from './coffee-specie.model';
import { MillingProcess } from './milling-process.model';
import { QualityType } from './quality-type.model';
import { QualityItem } from './quality-item.model';
import { QualityMain } from './quality-main.model';
import { QualityAdditionalBranding } from './quality-additional-branding.model';
import { StageProcessing } from './stage-processing.model';

@Entity('masterdata', 'Quality', 'qc')
export class Quality extends AbstractVersionedEntity {
  @Serialized name: string;
  @Serialized code: string;
  @Serialized countryId: number;
  @Serialized country: string;
  @Serialized tenant?: string;
  @Serialized coffeeSpecie: CoffeeSpecie;
  @Serialized millingProcess: MillingProcess;
  @Serialized qualityType: QualityType;
  @Serialized qualityItem: QualityItem;
  @Serialized qualityMain: QualityMain;
  @Serialized qualityAdditionalBranding: QualityAdditionalBranding;
  @Serialized stageProcessing: StageProcessing;
  @Serialized comtrasReference: string;
  @Serialized dycoReference: string;
}
