import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '../../../../../modules/utils/object-utils';

import { PredefinedOptionsStyle } from '../../../models/predefined-options-style.model';

/**
 * Component showing a dialog and two buttons, a primary and a secondary.
 * Primary and secondary use primary and secondary color css classes.
 * There is an event for each button clicked.
 * Per default the buttons show 'save' and 'discard'
 */
@Component({
  selector: 'ncs-dialog-basic',
  templateUrl: './ncs-dialog-basic.component.html',
})
export class NcsDialogBasicComponent implements OnInit {
  @Input() appendTo: any = 'body';
  @Input() visible: boolean; // two ways data bound visible - set from outside, set to false if any discard option chosen
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>(); // visibility changed event

  @Input() headerText: string; // text in the header row (caption) of the dialog
  // @Input() questionText: string; // the text in the dialog
  @Output() onHide: EventEmitter<any> = new EventEmitter(); // the dialog is going to hide. XXX USE this to set parent variable of visible to false!
  @Input() focusIsOnPrimary = true;

  /* primary button */
  @Input() primaryButtonText: string; // the text on the primary button
  @Output() onPrimaryClicked: EventEmitter<any> = new EventEmitter(); // the primary button (the right one, pre-selected) has been clicked
  @ViewChild('primaryButton') primaryButtonElementRef: ElementRef;

  /* secondary button */
  @Input() secondaryButtonText: string; // the text on the secondary button
  @Output() onSecondaryClicked: EventEmitter<any> = new EventEmitter(); // the secondary button (the left one) has been clicked
  @ViewChild('secondaryButton') secondaryButtonElementRef: ElementRef;

  /* Set predefined styling options (that can be overwritten)? */
  @Input() stylingOption: PredefinedOptionsStyle;
  /* Style options that overwrite defaults set with stylingOption */
  @Input() footerStyle: { [key: string]: string }; // value only taken for stylingOption=PredefinedOptionsStyle.NONE
  @Input() primaryButtonStyle: { [key: string]: string }; // value only taken for stylingOption=PredefinedOptionsStyle.NONE
  @Input() secondaryButtonStyle: { [key: string]: string }; // value only taken for stylingOption=PredefinedOptionsStyle.NONE

  @Input() primaryButtonShow: boolean = true;
  @Input() secondaryButtonShow: boolean = true;
  @Input() styleDialog: any = {
    minWidth: '630px',
    maxHeight: '660px',
    width: '820px',
    overflow: 'auto',
  };
  @Input() showOnModal: boolean = true;
  @Input() closable: boolean = false;
  @Input() closeOnEscape: boolean = false;

  /* predefined styles. key is style enum, array in css propterty is 'footerStyle', 'primaryButtonStyle', 'secondaryButtonStyle' */
  predefinedStyles: Map<
    PredefinedOptionsStyle,
    {
      headerText: string;
      primaryButtonText: string;
      secondaryButtonText: string;
      css: { [key: string]: string }[];
    }
  > = new Map([
    // for PredefinedOptionsStyle.NONE evaluate styling @Input()s
    [
      PredefinedOptionsStyle.SAVE,
      {
        headerText: 'common.buttons.save',
        primaryButtonText: 'common.buttons.save',
        secondaryButtonText: 'common.buttons.discard',
        css: [
          {
            display: 'flex',
            'justify-content': 'space-between',
          },
          { 'margin-left': '20px' },
          { 'margin-left': '20px' },
        ],
      },
    ],
    [
      PredefinedOptionsStyle.DELETE,
      {
        headerText: 'common.buttons.delete',
        primaryButtonText: 'common.buttons.delete',
        secondaryButtonText: 'common.buttons.cancel',
        css: [
          {
            display: 'flex',
            'justify-content': 'flex-end',
          },
          { 'margin-left': '20px' },
          { 'margin-left': '20px' },
        ],
      },
    ],
  ]);

  constructor(@Inject('wndw') protected wndw: Window) {}

  ngOnInit(): void {
    const myStylingOption = isNullOrUndefined(this.stylingOption) ? PredefinedOptionsStyle.SAVE : this.stylingOption;
    const style = this.predefinedStyles.get(myStylingOption);
    if (isNullOrUndefined(this.footerStyle)) {
      this.footerStyle = style.css[0];
    }
    if (isNullOrUndefined(this.primaryButtonStyle)) {
      this.primaryButtonStyle = style.css[1];
    }
    if (isNullOrUndefined(this.secondaryButtonStyle)) {
      this.secondaryButtonStyle = style.css[2];
    }
    if (isNullOrUndefined(this.headerText)) {
      this.headerText = style.headerText;
    }
    if (isNullOrUndefined(this.primaryButtonText)) {
      this.primaryButtonText = style.primaryButtonText;
    }
    if (isNullOrUndefined(this.secondaryButtonText)) {
      this.secondaryButtonText = style.secondaryButtonText;
    }
  }

  public onDialogShow(): void {
    // set the focus on the primary button
    this.wndw.setTimeout(() => {
      if (this.focusIsOnPrimary && this.primaryButtonShow) {
        this.primaryButtonElementRef.nativeElement.focus();
      } else if (this.secondaryButtonShow) {
        this.secondaryButtonElementRef.nativeElement.focus();
      }
    });
  }

  public onDialogHide(): void {
    this.visibleChange.emit(false);
    if (this.visible) {
      this.onHide.emit();
    }
  }

  public primaryClicked(): void {
    this.visibleChange.emit(false);
    this.onPrimaryClicked.emit();
  }

  public secondaryClicked(): void {
    this.visibleChange.emit(false);
    this.onSecondaryClicked.emit();
  }
}
