export const CLIENT_ID = '3a07bccd-214b-49ad-8ed1-0cf14b7d81cf';
export const TENANT_ID = '321a398d-e261-4d65-adec-c9cc85f02498';
export const REDIRECT_PATH = '/#/login/msal';
export const AUTHORITY_URL = 'https://login.microsoftonline.com/';
export const END_SESSION_URL = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/logout`;

export abstract class MsalEnvironment {
  abstract getClientId(): string;
  abstract chooseThis(origin: string): boolean;

  getRedirectUrl(): string {
    let origin: string = window.location.origin || '';
    origin = origin.endsWith('/') ? origin.slice(0, origin.length - 1) : origin;
    return origin + REDIRECT_PATH;
  }
}

export class LocalEnvironment extends MsalEnvironment {
  chooseThis(origin: string): boolean {
    return origin.startsWith('https://localhost') || origin.startsWith('http://localhost'); // this only will work to do not return a null or undefined environment when is running into DEV-09
  }

  getClientId(): string {
    return CLIENT_ID;
  }

  getApiEndpoint(): string {
    return 'http://localhost:4200/#/auth/msal';
  }
}

export class ProductionEnvironment extends MsalEnvironment {
  getClientId(): string {
    return CLIENT_ID;
  }

  chooseThis(origin: string): boolean {
    return origin === 'https://ecc.com.mx:4280';
  }
}

export class TestEnvironment extends MsalEnvironment {
  getClientId(): string {
    return CLIENT_ID;
  }

  chooseThis(origin: string): boolean {
    return origin === 'https://ecc.com.mx:8788';
  }
}
