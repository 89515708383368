import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { NcsDateInputComponent } from './components/ncs-date-input.component';
import { NcsTooltipSharedModule } from '../tooltip/tooltip-shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonModule, CalendarModule, NcsTooltipSharedModule, FormsModule],
  declarations: [NcsDateInputComponent],
  exports: [NcsDateInputComponent],
})
export class NcsDateInputSharedModule {}
