import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { CupEvaluatorUser } from './cup-evaluator-user.model';
import { ThirdParty } from './third-party.model';
import { CupEvaluatorTenant } from './cup-evaluator-tenant.model';

@Entity('cupping', 'CupEvaluator', 'qc')
export class CupEvaluator extends AbstractVersionedEntity {
  @Serialized cupEvaluator: string;
  @Serialized codeCupEvaluator: string;
  @Serialized certified?: boolean;
  @Serialized tenant: string;
  @Serialized guest: boolean;
  @Serialized assistant: boolean;
  @Serialized companyInfo: string;
  @Serialized cupEvaluatorUser: CupEvaluatorUser;
  @Serialized thirdParty: ThirdParty;
  @Serialized({ elementType: CupEvaluatorTenant, composite: true })
  cupEvaluatorTenants?: CupEvaluatorTenant[] = [];
}
