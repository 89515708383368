import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { NcsButtonComponent } from './components/button.component';

@NgModule({
  imports: [CommonModule, TranslateModule, TooltipModule, ButtonModule],
  declarations: [NcsButtonComponent],
  exports: [NcsButtonComponent],
})
export class NcsButtonSharedModule {}
