import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { Sample } from './sample.model';

@Entity('cupping', 'CuppingSessionSample', 'qc')
export class CuppingSessionSample extends AbstractEntity {
  @Serialized position: number;
  @Serialized sample: Sample;
  @Internal isAvailableToAverage: boolean = false;
}
