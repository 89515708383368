import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';

@Entity('masterdata', 'MetricsDefinitionTypeResult', 'qc')
export class MetricsDefinitionTypeResult extends AbstractVersionedEntity {
  @Serialized typeResultName: string;
  @Serialized typeResultCode: string;
  @Serialized typeResultScore: boolean;
  @Serialized typeResultNumeric: boolean;
  @Serialized typeResultShowList: boolean;
}
