import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { User } from './user.model';

@Entity('user', 'GridView', 'auth')
export class GridView extends AbstractEntity {
  @Serialized tableId: string;
  @Serialized value: string;
  @Serialized title: string;
  @Serialized isDefault: boolean;
  @Serialized user?: User;
  @Internal isSaved: boolean = true;
}
