<ng-content select="[base-form=form]"></ng-content>
<div *ngIf="showToolbar && !editMode">
  <nkg-toolbar *restrictTo="permissions">
    <ng-content select="[base-form=button-left]" class="left"></ng-content>
    <span class="right">
      <ng-content select="[base-form=button-right]"></ng-content>
    </span>
  </nkg-toolbar>
</div>
<div *ngIf="showToolbar && editMode">
  <nkg-toolbar *restrictTo="permissions">
    <ncs-button
      [label]="'common.buttons.discard'"
      title="ESC"
      inputClass="nkg-secondary-btn"
      (onClicked)="onEsc()"></ncs-button>
    <ng-content select="[base-form=button-left]" class="left"></ng-content>
    <span class="right">
      <ncs-button
        [disabled]="disabled"
        [label]="'common.buttons.save'"
        title="Ctrl+S"
        [class]="'nkg-primary-btn'"
        (onClicked)="onCtrlS()"></ncs-button>
    </span>
  </nkg-toolbar>
</div>
