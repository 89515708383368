import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { StandardsDefinitionEquivalences } from './standards-definition-equivalences.model';

@Entity('cupping', 'CuppingProcessMetricOption', 'qc')
export class CuppingProcessMetricOption extends AbstractVersionedEntity {
  // @Serialized cuppingProcessMetrics: CuppingProcessMetric;
  @Serialized standardsDefinitionEquivalences: StandardsDefinitionEquivalences;
  @Serialized value: number; // {valueResult} / {equivalence} (Defects)
  @Serialized equivalence?: number; // physical type
  @Serialized valueResult: number; //  general standard option
  @Serialized dateResult?: Date;
  @Serialized stringResult?: string;
  @Serialized comment: string;
  @Internal step?: number;
  @Internal index?: number;
  @Internal selected?: boolean;
}
