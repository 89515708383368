import { FieldResults, ValidationResult } from './master-validation';
import { isNullOrUndefined, mergeImmutable, Path } from '../../utils/object-utils';

export abstract class ValidationService {
  /**
   * Removes a possibly existing FieldValidationResult at the given path from the given validationResult
   *
   * @param {ValidationResult} validationResult
   * @param {Path | string} path
   * @returns {ValidationResult}
   */
  public clearFieldResults(validationResult: ValidationResult, path: Path): ValidationResult {
    if (!isNullOrUndefined(validationResult?.fieldResults) && !isNullOrUndefined(path)) {
      const newFieldResults: FieldResults = mergeImmutable(validationResult.fieldResults, { path: null });
      return mergeImmutable(validationResult, { fieldResults: newFieldResults });
    }
    return validationResult;
  }
}
