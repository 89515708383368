import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/ngrx/reducers';
import { User } from '../../../core/domain/user.model';
import { Permission } from '../../../core/domain/permission.model';
import { ProtectionDomain } from '../../../core/domain/protection-domain.model';
/**
 * Add the template content to the DOM unless the condition is true.
 */
@Directive({ selector: '[restrictTo]' })
export class RestrictAccessDirective implements OnInit {
  @Input() private readonly restrictTo: ProtectionDomain | Permission[] = [];
  private authorized: boolean;

  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.store.select(fromRoot.getCurrentUser).subscribe(user => {
      this.checkPermissions(user);
    });
  }

  private checkPermissions(user: User): void {
    const newAuthorized: boolean =
      user &&
      (this.restrictTo instanceof Array
        ? user.hasPermissions(this.restrictTo)
        : user.canAccessProtectedDomain(this.restrictTo));
    if (newAuthorized !== this.authorized) {
      if (newAuthorized) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (!newAuthorized) {
        this.viewContainer.clear();
      }
    }
    this.authorized = newAuthorized;
  }
}
