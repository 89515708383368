/**
 * Possible values of PropertyInfo.access.
 */
export enum JsonPropertyAccess {
  /**
   * The property will only be deserialized from JSON into the JavaScript object when receiving a response from the server.
   */
  WRITE_ONLY,
  /**
   * The property will only be serialized from the JavaScript object into JSON when sending a request to the server.
   */
  READ_ONLY,
}
