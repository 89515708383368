import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TooltipAttribute } from '../models/enums';

@Component({
  selector: 'text-tooltip-cell-renderer',
  templateUrl: './TextTooltipCellRendererComponent.html',
})
export class TextTooltipCellRendererComponent implements AgRendererComponent {
  value: string;
  tooltip: TooltipAttribute;
  tooltipFunction: (params: ICellRendererParams) => TooltipAttribute;

  agInit(params: ICellRendererParams): void {
    if (params.colDef.cellRendererParams) {
      if (params.colDef.cellRendererParams.tooltipFunction)
        this.tooltipFunction = params.colDef.cellRendererParams.tooltipFunction;
    }
    this.formatValue(params);
  }

  refresh(params: any): boolean {
    this.formatValue(params);
    return true;
  }

  formatValue(params: ICellRendererParams): void {
    this.value = params.getValue();
    if (this.tooltipFunction) {
      this.tooltip = this.tooltipFunction(params);
    }
  }
}
