import { Component } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { KeyboardKeys } from '../models/enums';

@Component({
  templateUrl: './text-inline-cell-editor.component.html',
})
export class TextCellEditorComponent implements AgEditorComponent {
  value: string;
  params: any;
  cellEditorStyle: any;
  ngStyle: any;

  getValue(): any {
    return this.value;
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.cellEditorStyle = params.cellEditorStyle;
    this.ngStyle = params.ngStyle;
  }

  onKeyDown(event): void {
    /**
         *
         37(left arrow)
         38(up arrow)
         39(right arrow)
         40(down arrow)
         */

    /**
         Allow navigation inside the input text & lock the navigation in the table while user is editing...
         */
    if (
      event.keyCode === KeyboardKeys.END ||
      event.keyCode === KeyboardKeys.HOME ||
      event.keyCode === KeyboardKeys.ARROW_RIGHT ||
      event.keyCode === KeyboardKeys.ARROW_LEFT
    ) {
      event.stopPropagation();
    }
  }
}
