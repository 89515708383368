import { ModuleWithProviders, NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CommonModule, DecimalPipe, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PickListModule } from 'primeng/picklist';
import { NumberInlineCellEditorComponent } from './number-inline-cell-editor/number-inline-cell-editor.component';
import { NkgGridFactory } from './services/grid.factory';
import { DateRendererComponent } from './renderer/date-renderer.component';
import { ActionRendererComponent } from './renderer/action-renderer.component';
import { DateCellEditorComponent } from './date-inline-cell-editor/date-inline-cell-editor.component';
import { TextCellEditorComponent } from './text-inline-cell-editor/text-inline-cell-editor.component';
import { TextTooltipCellRendererComponent } from './tooltip/TextTooltipCellRendererComponent';
import { BooleanInlineCellEditorComponent } from './boolean-inline-cell-editor/boolean-inline-cell-editor.component';
import { RemoveBtnStandardEquivalenceComponent } from './remove-btn-inline-cell-editor/remove-btn-standard-equivalence.component';
import { RemoveBtnStandardDefinitionOptComponent } from './remove-btn-inline-cell-editor/remove-btn-standard-definition-opt.component';
import { AddScoreDescriptionComponent } from './add-score-description-cell-editor/add-score-description.component';
import { CustomDateComponent } from './filter/custom-date-filter/custom-date.component';
import { CustomTextFilterComponent } from './filter/custom-text-filter/custom-text-filter.component';
import { CustomNumberFilterComponent } from './filter/custom-number-filter/custom-number-filter.component';
import { CustomCountryFilterComponent } from './filter/custom-country-filter.component';
import { NumberRendererComponent } from './renderer/number-renderer.component';
import { PipesSharedModule } from '../pipes/pipes-shared.module';
import { NcsCheckboxBasicSharedModule } from '../basic-shared-module/components/checkbox-basic/checkbox-basic-shared.module';
import { NcsDateInputSharedModule } from '../basic-shared-module/components/date-input/date-input-shared.module';
import { NcsDateSharedModule } from '../basic-shared-module/components/date/date-shared.module';
import { NcsSelectBoxSharedModule } from '../basic-shared-module/components/select-box/select-box-shared.module';
import { NcsNumberInputSharedModule } from '../basic-shared-module/components/number-input/number-input-shared.module';
import { NcsTextSharedModule } from '../basic-shared-module/components/text/text-shared.module';
import { NcsTooltipSharedModule } from '../basic-shared-module/components/tooltip/tooltip-shared.module';

/**
 * This module provides common grid functionality.
 *
 * The module exports the GridComponent, which is a wrapper for ag-grid components and provides common functionality
 * most grids of the application will need on top of what ag-grid provides. Therefore, every ag-grid in the application
 * should be wrapped into a GridComponent.
 *
 * This module also re-exports AgGridModule itself. This way only the GridModule has to be imported when a grid needs to
 * be implemented in a component.
 *
 * Furthermore the module defines several common Renderer- and CellEditorComponents that can be used in grids throughout
 * the application. They are not exported but defined as entry components, so that they can be instantiated when needed
 * by ag-grid.
 */
@NgModule({
  imports: [
    AgGridModule.withComponents([
      BooleanInlineCellEditorComponent,
      CustomTextFilterComponent,
      CustomNumberFilterComponent,
      CustomCountryFilterComponent,
    ]),
    ScrollPanelModule,
    NgStyle,
    DecimalPipe,
    CommonModule,
    TranslateModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    FormsModule,
    AutoCompleteModule,
    PickListModule,
    PipesSharedModule,
    NcsCheckboxBasicSharedModule,
    NcsDateInputSharedModule,
    NcsDateSharedModule,
    NcsSelectBoxSharedModule,
    NcsNumberInputSharedModule,
    NcsTextSharedModule,
    NcsTooltipSharedModule,
  ],
  declarations: [
    // renderer and inline editor components
    ActionRendererComponent,
    DateRendererComponent,
    NumberRendererComponent,
    NumberInlineCellEditorComponent,
    DateCellEditorComponent,
    BooleanInlineCellEditorComponent,
    TextCellEditorComponent,
    // PartialTextFloatingFilter,
    TextTooltipCellRendererComponent,
    RemoveBtnStandardEquivalenceComponent,
    RemoveBtnStandardDefinitionOptComponent,
    AddScoreDescriptionComponent,
    CustomDateComponent,
    CustomTextFilterComponent,
    CustomNumberFilterComponent,
    CustomCountryFilterComponent,
  ],
  exports: [AgGridModule],
})
export class GridModule {
  static forRoot(): ModuleWithProviders<GridModule> {
    return {
      ngModule: GridModule,
      providers: [NkgGridFactory],
    };
  }
}
