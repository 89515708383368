import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { AddressType } from './address-type.model';

@Entity('masterdata', 'ThirdPartyAddress', 'qc')
export class ThirdPartyAddress extends AbstractEntity {
  @Serialized fullName: string;
  @Serialized countryId: number;
  @Serialized city: string;
  @Serialized state: string;
  @Serialized zipCode: string;
  @Serialized streetAddress: string;
  @Serialized phoneNumber?: string;
  @Serialized addressType: AddressType;
}
