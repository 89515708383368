import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FieldValidationResult } from '../../../../../modules/master/services/master-validation';
import { isEmpty, isNotEmpty } from '../../../../../modules/utils/string-utils';
import { isNullOrUndefined, nullsafe } from '../../../../../modules/utils/object-utils';
import { isNumber } from '../../../../../modules/utils/number-utils';
import { NcsBaseBasicComponent } from '../../base-basic/base-basic.component';
import { TooltipMessage } from '../../tooltip/models/tooltip-message.model';

const UP_KEY: number = 38;
const DOWN_KEY: number = 40;

@Component({
  selector: 'ncs-number-input',
  templateUrl: 'ncs-number-input.component.html',
})
export class NcsNumberInputComponent extends NcsBaseBasicComponent implements OnChanges, OnInit {
  @Input() value: number;
  @Input() maxValue: number = Number.POSITIVE_INFINITY;
  @Input() minValue: number = Number.NEGATIVE_INFINITY;
  @Input() maxDecimals: number = 2; // maximal number of digits. If not set, it defaults to toLocaleString(this.locale)
  @Input() maxInteger: number = 8;
  @Input() showEnterNumberOnSingleSign: boolean = true;
  @Input() locale: string = 'en';
  @Input() showPlusSign: boolean;
  @Input() validationResult: FieldValidationResult; // avoid two tooltips - use inner one for all
  @Input() infoMessage: TooltipMessage;
  @Input() step: number = 1;

  @Input() focusable: boolean = true;
  @Input() tabIndex: number;
  /** If set to true, then two digits at the end, otherwise zero digits. */
  @Input() decimalMode: boolean = false;
  /** String at the end of the value. */
  // WARN: Separate it from inputNumber because there's an issue when put it in the ngFor directive
  @Input() suffix: string = '';
  @Input() showControls: boolean = false;

  @Input() tooltip: string;
  @Output() focusTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputChange') set content(content: ElementRef) {
    if (content) this.inputChange = content;
  }
  private inputChange: ElementRef;
  internalValue: string;
  stringValue: string;
  lastValue: string;
  errorMessage: string;
  errorParam: Object;
  hasFocus: boolean = true;
  isWrongInput: boolean = false;

  onArrowDown: boolean = false;
  internalValidationResult: FieldValidationResult;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setValue(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationResult || changes.value) {
      this.internalValidationResult = this.getValidationResult();
    }
    const { value } = changes;
    if (value) {
      this.setValue(value.currentValue);
      if (typeof value.currentValue === 'number') {
        this.setWrongInput(value.currentValue.toString());
      }
      this.cd.markForCheck();
    }
    if (changes.disabled) {
      this.isWrongInput = false;
    }
  }

  onChange(event: any): void {
    let { value } = event.target;
    if (isNotEmpty(value)) {
      value = this.onGetDecimalValue(value);
      const saneInputRegex = /^[-+]?\d+(?:[.,]\d+)?$/;
      if (!value.match(saneInputRegex)) {
        value = undefined;
      }
      event.target.value = value;
      // input not a valid number
      this.setWrongInput(value);
      this.cd.markForCheck();
    } else {
      const defaultValue = this.minValue < Number.NEGATIVE_INFINITY ? this.minValue : undefined;
      this.setValue(defaultValue);
    }
    this.emitChange(this.value);
  }

  private onGetDecimalValue(value: string): string {
    const reg = /[^\d,.+-]/g;
    value = value.replace(reg, '');

    if (value.includes(',') && value.includes('.')) {
      if (this.lastValue.includes(',')) {
        value = value.replace('.', '');
      } else {
        value = value.replace(',', '');
      }
    } else if (value.includes(',')) {
      value = this.noSecondSign(value, ',');
    } else if (value.includes('.')) {
      value = this.noSecondSign(value, '.');
    }
    if (value.startsWith('.') || value.startsWith(',')) {
      value = `0${value}`;
    }
    return value;
  }

  onFocus(focusEvent: any): void {
    focusEvent?.target?.select();
    this.hasFocus = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.focusTrigger.emit(null);
    }, 0);
    // grouping target values for any time and send via output
    const term$ = fromEvent<any>(this.inputChange.nativeElement, 'keyup').pipe(
      map(event => event),
      startWith(''),
      debounceTime(600),
      distinctUntilChanged(),
    );
    term$.subscribe(query => {
      if (query) this.onChange(query);
    });
  }

  onBlur(): void {
    if (!this.onArrowDown) {
      this.hasFocus = false;
      this.internalValue = this.correctDecimalSeparator(this.internalValue || '');
      this.lastValue = this.internalValue;
      this.cd.markForCheck();
      this.onblur.emit(this.value);
    }
  }

  onKeyDown(event: any): void {
    if (UP_KEY === event.keyCode) {
      this.incDecNumber('inc');
      event.preventDefault();
    }
    if (DOWN_KEY === event.keyCode) {
      this.incDecNumber('dec');
      event.preventDefault();
    }
  }

  incDecNumber(incDec: string): void {
    if (this.disabled) {
      return;
    }

    /* a single minus is a first, initial valid input for CR-775
     * define how inc and dec on '-' works */
    if (this.onMatchWithSingleMinus(this.internalValue)) {
      this.internalValue = incDec == 'inc' ? '-1.0' : '0.0';
    }
    this.onArrowDown = false;

    const arr: string[] = this.internalValue.split('.');
    let currDecimals = 1;
    if (arr.length > 1) {
      currDecimals = 10 ** arr[1].length;
    }

    let nbr = +this.internalValue.replace(',', '.');
    nbr = Math.round(currDecimals * (incDec == 'inc' ? nbr + 1 : nbr - 1)) / currDecimals;
    this.setWrongInput(nbr.toString());
    this.emitChange(this.value);
    this.cd.markForCheck();
  }

  private emitChange(currentValue: number): void {
    this.onchange.emit(currentValue);
  }

  // is allowed as first (initial) input, threat it as no input to initialValue
  private handleMinusValues(value: number): string {
    if (this.onMatchWithSingleMinus(value?.toString())) {
      return '';
    }
    return value?.toString() || '';
  }

  private setValue(value: number): void {
    this.internalValue = this.handleMinusValues(value);
    this.stringValue = this.formatStringAsNumber(this.internalValue);
    this.lastValue = this.internalValue;
    this.value = isEmpty(this.internalValue) ? undefined : Number(this.internalValue);
  }

  private setWrongInput(inputValue: string): void {
    let error = false;
    if (isNumber(this.maxDecimals) || isNumber(this.maxInteger)) {
      const arr = inputValue.includes('.') ? inputValue.split('.') : inputValue.split(',');
      let safeInteger: string = nullsafe(arr)[0];
      let safeDecimal: string = nullsafe(arr)[1];
      if (isNumber(this.maxDecimals) && arr[1]?.length > this.maxDecimals) {
        safeDecimal = arr[1].substring(0, this.maxDecimals);
        error = true;
        this.errorMessage = 'numberinput.validation.too_many_decimal_digits';
        this.errorParam = undefined;
      }
      if (isNumber(this.maxInteger) && arr[0]?.length > this.maxInteger) {
        safeInteger = `${this.maxValue}`;
        error = true;
        this.errorMessage = 'numberinput.validation.too_many_integer_digits';
        this.errorParam = undefined;
      }
      if (isNotEmpty(safeDecimal)) {
        safeInteger = `${safeInteger}.${safeDecimal}`;
      }
      inputValue = safeInteger;
    }

    error = this.onValidateValueChanged(inputValue, error);

    this.isWrongInput = error;
    this.internalValidationResult = this.getValidationResult();
    if (error) this.tooltipSeverity = 'error';
  }

  private onValidateValueChanged(inputValue: string, error: boolean): boolean {
    if (isNumber(this.minValue) || isNumber(this.maxValue)) {
      const minVal = Math.min(this.minValue, this.maxValue);
      const maxVal = Math.max(this.minValue, this.maxValue);
      let nbr = +inputValue.replace(',', '.');
      if (nbr < minVal) {
        error = true;
        this.errorMessage = 'numberinput.validation.less_than_not_permitted';
        this.errorParam = { value: this.minValue };
        nbr = minVal;
      } else if (nbr > maxVal) {
        error = true;
        this.errorMessage = 'numberinput.validation.maximum_not_permitted';
        this.errorParam = { value: this.maxValue };
        nbr = maxVal;
      } else if (Number.isNaN(nbr)) {
        error = this.onSetEmptyMessage(error, inputValue);
      }
      this.setValue(nbr);
    }
    return error;
  }

  private onMatchWithSingleMinus(matchValue: string): boolean {
    if (isEmpty(matchValue)) return false;
    const regex = /^-$/;
    return !isNullOrUndefined(regex.exec(matchValue));
  }

  private onSetEmptyMessage(error: boolean, inputValue: string): boolean {
    if (this.showEnterNumberOnSingleSign) {
      error = true;
      this.errorMessage = 'numberinput.validation.please_enter_a_number';
      this.errorParam = undefined;
    } else {
      if (!this.onMatchWithSingleMinus(inputValue)) {
        error = true;
        this.errorMessage = 'numberinput.validation.please_enter_a_number';
        this.errorParam = undefined;
      }
      if (this.onMatchWithSingleMinus(inputValue) && this.minValue > 0) {
        error = true;
        this.errorMessage = 'numberinput.validation.please_enter_a_positive_number';
        this.errorParam = undefined;
      }
    }
    return error;
  }

  private correctDecimalSeparator(matchValue: string): string {
    if (this.locale === 'de') {
      return matchValue.replace('.', ',');
    }
    return matchValue.replace(',', '.');
  }

  private formatStringAsNumber(str: string): string {
    let res: string;
    res = str.replace(',', '.');
    if (res !== '') {
      const nbr: number = +res;
      if (Number.isNaN(nbr)) {
        return str;
      }
      res = nbr.toLocaleString(this.locale, {
        maximumFractionDigits: this.maxDecimals,
      });
      if (this.showPlusSign && nbr > 0) {
        res = `+${res}`;
      }
    }
    return res;
  }

  private noSecondSign(str: string, separator: string): string {
    if (this.lastValue !== undefined) {
      const signPos = this.lastValue.indexOf(separator);
      if (signPos >= 0 && str.includes(separator)) {
        const first = str.indexOf(separator);
        const last = str.lastIndexOf(separator);
        if (first !== last) {
          if (first === signPos) {
            str = str.slice(0, last) + str.slice(last + 1);
          } else {
            str = str.slice(0, first) + str.slice(first + 1);
          }
        }
      }
    }
    return str;
  }

  // as tooltips become even more difficult - and we want to pass external values: use getters for it
  private translateErrorMessage(): string {
    return this.translate.instant(this.errorMessage, this.errorParam); // safe 2 use instant, we have translation loaded now
  }

  getValidationResult(): FieldValidationResult {
    let vld: FieldValidationResult = this.validationResult;
    // QC-683: When there's an external validation, avoid an internal validation message
    if (this.isWrongInput && isNullOrUndefined(this.validationResult)) {
      // we have our own - and need to translate inline, even though this is not nice
      vld = {
        invalid: true,
        warnings: [{ message: this.translateErrorMessage(), messageParams: undefined }],
        errors: [],
      };
    }
    return vld;
  }
}
