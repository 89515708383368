<div class="nkg-body layout-wrapper layout-compact">
  <div class="layout-container menu-layout-static">
    <app-topbar *ngIf="loggedIn && !isOnlyContent" (messageItemClicked)="onMessageItemClicked()"></app-topbar>
    <div *ngIf="loggedIn && !isOnlyContent">
      <div class="layout-menu" [ngClass]="{ 'nkg-closed': compact }">
        <div class="nano">
          <div class="nano-content menu-scroll-content">
            <span class="nkg-logo"><img src="/assets/nkg.png" alt="NKG logo" /></span>
            <a class="ripplelink nkg-burger" [href]="'#'" (click)="onCompactClick($event)">
              <i class="material-icons nkg-chevron">chevron_left</i>
            </a>
            <ul
              app-submenu
              [menuItems]="appMenu"
              class="ultima-menu ultima-main-menu clearfix ripplelink"
              visible="true"></ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="nkg-content"
      [ngClass]="{
        'nkg-closed': compact,
        'layout-main': loggedIn,
        'layout-only-content': isOnlyContent
      }">
      <router-outlet></router-outlet>
      <!-- Selector for additional bootstrapped component (MSAL) -->
      <app-redirect></app-redirect>
      <div class="loading-indicator" *ngIf="isResponsePending">
        <p-progressSpinner></p-progressSpinner>
        <span>{{ 'grid.labels.loadingOoo' | translate }}</span>
      </div>
    </div>
    <p-confirmDialog
      key="simpleConfirmationDialog"
      icon="fa fa-question-circle"
      [closable]="false"
      [closeOnEscape]="false">
    </p-confirmDialog>
    <p-confirmDialog
      key="sendEmailConfirmation"
      header="{{ 'common.labels.email' | translate }}"
      [closable]="false"
      [closeOnEscape]="false"
      icon="fa fa-question-circle"></p-confirmDialog>
    <app-notification-panel
      *ngIf="loggedIn"
      [rightPanelVisible]="rightPanelVisible"
      [latestReadDate]="latestNotificationReadDate()"
      (actionExecuted)="onNotificationActionExecuted()">
    </app-notification-panel>
    <div class="layout-mask"></div>
  </div>
</div>
<!-- p-dialog has the highest z-index with a value of 9999. -->
<p-toast key="app-notification-toast" [baseZIndex]="10001"></p-toast>
