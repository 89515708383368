import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { PaginationMetadata } from '../../../core/domain/pagination-metadata.model';

@Component({
  selector: 'ncs-pagination',
  templateUrl: './pagination.component.html',
})
export class NcsPaginationComponent implements OnChanges {
  lastPage: number = 1;
  page: number = 1;
  totalItems: number;
  numberItems: number;

  @Input('pagination') paginationProperty: PaginationMetadata;
  @Output() onChangePage: EventEmitter<number> = new EventEmitter();

  // CAUTION: If pagination is used on master only, change ngOnChanges to NgRx storage
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paginationProperty) {
      if (!isNullOrUndefined(this.paginationProperty)) {
        this.numberItems = this.paginationProperty.numberItems;
        this.totalItems = this.paginationProperty.totalItems;
        this.lastPage = this.paginationProperty.totalPages;
        this.page = this.paginationProperty.page + 1;
      }
    }
  }

  /**
   * Param structured as {page: number, item: number}
   * this param is sent from template
   * @param event
   */
  public changePage(event: any): void {
    const { page, item } = event;
    const currentPage = this.page + page;
    this.page = item || currentPage;
    this.onChangePage.emit(this.page - 1);
  }
}
