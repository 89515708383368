import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Message, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../services/tenant.service';
import { RoleService } from '../services/role.service';
import { QueryService } from '../services/query.service';
import { AppMetaDataService } from '../services/app-meta-data.service';
import { NotificationService } from '../services/notification.service';
import {
  fetchAllStates,
  fetchAllTenants,
  fetchCOMTRASInstance,
  fetchCountries,
  fetchLanguages,
  fetchNotification,
  fetchRoles,
  fetchSearchGenericItems,
  fetchTimezone,
  getTenantByCOMTRASPrefix,
  setAllCountries,
  setAllLanguages,
  setAllStates,
  setAllTenants,
  setAppNotifications,
  setCOMTRASInstance,
  setResponsePending,
  setRoles,
  setSearchGenericItems,
  setTenantByCOMTRASPrefix,
  setTimezone,
} from '../actions';
import * as fromRoot from '../reducers';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { isArrayEmpty } from '../../../modules/utils/array-utils';
import { setToastNotification, ToastSeverityEnum } from '../utils/notification-utils';
import { Country } from '../../domain/country.model';
import { ComtrasInstance } from '../../domain/comtras-instance.model';
import { Tenant } from '../../domain/tenant.model';
import { Role } from '../../domain/role.model';
import { Language } from '../../domain/language.model';
import { State } from '../../domain/state.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { AppTimezoneInfo } from '../../domain/app-timezone-info.model';

@Injectable()
export class AppEffects {
  public constructor(
    private readonly actions$: Actions,
    private readonly tenantService: TenantService,
    private readonly roleService: RoleService,
    private readonly queryService: QueryService,
    private readonly appMetaDataService: AppMetaDataService,
    private readonly notificationService: NotificationService,
    private readonly store: Store<fromRoot.State>,
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService,
  ) {}

  public readonly requestTenants$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllTenants),
      switchMap(() => this.tenantService.loadActiveTenants()),
      map((tenants: Tenant[]) => setAllTenants({ tenants: tenants })),
    ),
  );

  public readonly requestRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRoles),
      switchMap(() => this.roleService.loadRoles()),
      map((roles: Role[]) => setRoles({ roles: roles })),
    ),
  );

  public readonly requestSearchGenericItems$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSearchGenericItems),
      switchMap(action =>
        this.queryService.querySearch(action.typeFunction, action.paginationRequestParams, action.serviceUrl),
      ),
      switchMap((res: PaginatedResponse) => {
        if (isArrayEmpty(res.items)) {
          const toastMessage: Message = setToastNotification({
            summary: 'common.labels.no_results_found',
            severity: ToastSeverityEnum.INFO,
            translateService: this.translateService,
            life: 3000,
          });
          this.messageService.add(toastMessage);
        }
        return [setSearchGenericItems({ paginationResponse: res }), setResponsePending({ isResponsePending: false })];
      }),
    ),
  );

  public readonly getLanguages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLanguages),
      switchMap(() => this.queryService.querySearch(Language)),
      map((languages: Language[]) => setAllLanguages({ languages: languages })),
    ),
  );

  public readonly getCountries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCountries),
      map(action => {
        if (isNullOrUndefined(action?.paginationRequestParams)) {
          const newPagination = new PaginationRequestParams();
          newPagination._include = '*';
          newPagination.size = 10000;
          newPagination.sort = 'country,ASC';
          return newPagination;
        }
        return action.paginationRequestParams;
      }),
      switchMap(pagination => this.queryService.querySearch(Country, pagination)),
      map((paginationResponse: PaginatedResponse) => setAllCountries({ countries: paginationResponse?.items })),
    ),
  );

  public readonly getStates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllStates),
      switchMap(() => this.queryService.querySearch(State)),
      map((states: State[]) => setAllStates({ states: states })),
    ),
  );

  public readonly getTimezone$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTimezone),
      switchMap(() => this.appMetaDataService.loadTimezone()),
      map((timezone: AppTimezoneInfo) => setTimezone({ timezone: timezone })),
    ),
  );

  public readonly fetchCOMTRASInstances$: Observable<Action> = createEffect(() =>
    //  move to master section
    this.actions$.pipe(
      ofType(fetchCOMTRASInstance),
      switchMap(() => this.tenantService.fetchComtrasInstances()),
      map((response: ComtrasInstance[]) => setCOMTRASInstance({ comtrasInstances: response })),
    ),
  );

  public readonly getTenantByCOMTRASPrefix: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getTenantByCOMTRASPrefix),
      switchMap(action => this.tenantService.getTenantByComtrasPrefix(action.prefix, action.tenantAbbreviation)),
      map((tenant: Tenant) => setTenantByCOMTRASPrefix({ tenant: tenant })),
    ),
  );

  public readonly getNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNotification),
      map(action => action.triggerGrowl),
      switchMap(trigger =>
        of(trigger).pipe(withLatestFrom(this.store.select(fromRoot.getNotifications)), notification => notification),
      ),
      switchMap(() =>
        this.notificationService.getNotifications().then(() => setAppNotifications({ notifications: [] })),
      ),
    ),
  );
}
