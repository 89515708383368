<div class="topbar clearfirx">
  <div class="topbar-left" style="padding: 2px 2px 2px 10px">
    <img
      ngSrc="/assets/qcs_logo_white.png"
      alt="NCS logo"
      width="202"
      height="57"
      [priority]="true"
      (click)="displayVersionInfo = true" />
  </div>
  <div class="topbar-right">
    <a tabindex="-1" *ngIf="navigateBackLink" [href]="navigateBackLink">
      <span id="menu-button"><i></i></span>
      <span id="back-to-label">{{ 'topbar.labels.back_to' | translate }} {{ navigateBackText | translate }}</span>
    </a>
    <span style="position: fixed; left: 45%; font-size: larger; color: white; padding-top: 4px">
      {{ view?.title | translate }}
    </span>
    <div class="topbar-items">
      <span
        style="
          display: inline-block;
          padding-top: 4px;
          color: #fff;
          font-size: large;
          padding-right: 8px;
          border-right: 1px solid #fff;
        "
        >{{ user?.defaultTenant?.abbreviation }}</span
      >
      <ul class="topbar-items animated fadeInDown">
        <li>
          <a (click)="userMenu.toggle($event)" pTooltip="{{ user?.displayName }}" tooltipPosition="left">
            <i class="material-icons nkg-alert-icon">person</i>
          </a>
        </li>
        <li>
          <a (click)="onMessageItemClick()">
            <i class="topbar-icon material-icons animated swing" style="font-size: 2.4em">message</i>
            <span *ngIf="hasNewNotifications()" class="topbar-badge animated rubberBand">{{
              numberOfNewNotifications()
            }}</span>
            <span class="topbar-item-name">Messages</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<p-menu #userMenu popup="popup" [model]="userMenuEntries" [baseZIndex]="1500" appendTo="body"></p-menu>
<app-ncs-version-info
  [visible]="displayVersionInfo"
  (versionDialogClose)="displayVersionInfo = false"></app-ncs-version-info>

<p-toast position="center" key="session_to_expire" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="col-12">
      <div style="text-align: center" class="col-12">
        <span class="pi pi-exclamation-triangle" style="font-size: 3em; font-weight: bold"></span>
        <p style="color: #0a2617; font-weight: bold">{{ message.summary }}</p>
        <p style="color: #0a2617; font-weight: bold">{{ message.detail }}</p>
      </div>
      <div class="grid col-12 flex justify-content-between">
        <button
          pButton
          [label]="'notification.confirm' | translate"
          (click)="onReject('session_to_expire')"
          class="'p-button-success'"></button>
        <button
          pButton
          [label]="'notification.cancel' | translate"
          (click)="onConfirm('session_to_expire')"
          class="p-button-danger"></button>
      </div>
    </div>
  </ng-template>
</p-toast>
