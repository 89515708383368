import { TypeInfo } from './type-info.model';
import { PropertyTypeInfo } from './property-type-info.model';
import {
  getConstructorByTypeName,
  getNameToConstructorEntries,
  isKnownType,
  isKnownTypeByName,
  schemaForPrototypeChain,
} from './decorators';
import { PropertyInfo } from './property-info.model';

let schemas: Map<string, TypeInfo>;

/**
 * Will be called to build the map from type name to TypeInfo.
 *
 * @returns {Map<string, TypeInfo>} the map from type name to TypeInfo
 */
function buildSchema(): Map<string, TypeInfo> {
  const map: Map<string, TypeInfo> = new Map();
  const ctorToTypeInfo: Map<Function, TypeInfo> = new Map();
  const unresolvedTypeInfos: PropertyTypeInfo[] = [];
  Array.from(getNameToConstructorEntries()).forEach(entry => {
    const key = entry[0];
    const value = entry[1];

    const typeInfo: TypeInfo = buildTypeInfo(value, ctorToTypeInfo, unresolvedTypeInfos);

    map.set(key, typeInfo);
    ctorToTypeInfo.set(value, typeInfo);
  });
  unresolvedTypeInfos.forEach((pt: PropertyTypeInfo) => {
    const type = ctorToTypeInfo.get(pt.type.ctor);
    if (pt.type !== type) {
      pt.type = type;
    } else {
      throw new Error(`Failed to resolve TypeInfo for ${pt.type.ctor}.`);
    }
  });

  return map;
}

/**
 * Build the PropertyTypeInfo for a property with the given 'type' constructor function and PropertyInfo information.
 *
 * @param {Function} type the constructor function of the property's type
 * @param {Map<Function, TypeInfo>} ctorToTypeInfo maps all already built TypeInfo objects by their constructor functions
 * @param {PropertyInfo} pi a possible PropertyInfo holding more information about the property
 * @returns {PropertyTypeInfo} the built PropertyTypeInfo
 */
function getPropertyTypeInfo(
  type: Function,
  ctorToTypeInfo: Map<Function, TypeInfo>,
  pi?: PropertyInfo,
): PropertyTypeInfo {
  const ti: TypeInfo = getTypeInfo(type, ctorToTypeInfo, pi);
  return {
    type: ti,
    isSerialized: pi?.serialized,
    composite: pi?.composite,
    default: pi?.default,
    access: pi?.access,
  };
}

/**
 * Return whether the given Function is a constructor for a primitive type.
 *
 * @param {Function} type the constructor function
 * @returns {boolean} true iff the type is a constructor for a primitive type; false otherwise
 */
function isPrimitiveType(type: Function): boolean {
  return type === String || type === Boolean || type === Number;
}

/**
 * Build the TypeInfo for the given 'type' constructor function.
 *
 * @param {Function} type the constructor function to build the TypeInfo object for
 * @param {Map<Function, TypeInfo>} ctorToTypeInfo maps all already built TypeInfo objects by their constructor functions
 * @param {PropertyInfo} pi a possible PropertyInfo used as a decorator argument
 * @returns {TypeInfo} the built TypeInfo
 */

function getTypeInfo(type: Function, ctorToTypeInfo: Map<Function, TypeInfo>, pi?: PropertyInfo): TypeInfo {
  if (ctorToTypeInfo.has(type)) {
    return ctorToTypeInfo.get(type);
  }
  const ti: TypeInfo = {};
  ti.ctor = type;
  ti.isPrimitive = isPrimitiveType(type);
  if (!ti.isPrimitive) {
    ti.isArray = type === Array;
    if (pi !== undefined) {
      if (ti.isArray) {
        /* Get the element type, which MUST have been declared and also processed before. */
        ti.elementType = getTypeInfo(pi.elementType, ctorToTypeInfo);
      }
    }
    ti.isKnownClass = isKnownType(type);
    ti.unresolved = ti.isKnownClass;
  }
  return ti;
}

function buildTypeInfo(
  value: Function,
  ctorToTypeInfo: Map<Function, TypeInfo>,
  unresolvedTypeInfos: PropertyTypeInfo[],
): TypeInfo {
  const properties: Map<PropertyKey, PropertyTypeInfo> = new Map();
  const typeInfo: TypeInfo = {
    properties,
    isKnownClass: true,
    ctor: value,
  };

  const schema = schemaForPrototypeChain(value.prototype);
  Array.from(Object.keys(schema)).forEach(prop => {
    const type = Reflect.getMetadata('design:type', value.prototype, prop);
    const ncsProperty: PropertyInfo = schema[prop];
    if (ncsProperty?.serialized) {
      /* This property is an internal property and not meant for serialization */
      const cp: PropertyTypeInfo = getPropertyTypeInfo(type, ctorToTypeInfo, ncsProperty);
      properties.set(prop, cp);
      if (cp.type.unresolved) {
        unresolvedTypeInfos.push(cp);
      }
    }
  });

  return typeInfo;
}

function updateSchema(type: string): void {
  if (isKnownTypeByName(type) && !schemas.has(type)) {
    const value: Function = getConstructorByTypeName(type);

    const typeInfo: TypeInfo = buildTypeInfo(value, new Map(), []);

    schemas.set(type, typeInfo);
  }
}

/**
 * Get the TypeInfo for the given type name.
 *
 * @param {string} type the type name
 * @returns {TypeInfo} the TypeInfo
 */
export function getSchemaOf(type: string): TypeInfo {
  if (schemas === undefined) {
    schemas = buildSchema();
  } else if (!schemas.has(type)) {
    // try to update existing schema after new type was evaluated at runtime
    updateSchema(type);
  }

  return schemas.get(type);
}
