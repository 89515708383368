import { createReducer, on } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Injector } from '@angular/core';
import { assign, cloneDeep, isEqual } from 'lodash';
import { AppActionTypes } from '../actions';
import * as AppActions from '../actions/app.action';
import { nullsafe } from '../../../modules/utils/object-utils';
import { Country } from '../../domain/country.model';
import { ComtrasInstance } from '../../domain/comtras-instance.model';
import { Tenant } from '../../domain/tenant.model';
import { Role } from '../../domain/role.model';
import { Language } from '../../domain/language.model';
import { State } from '../../domain/state.model';
import { View } from '../../domain/view.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';
import { AppNotification } from '../../domain/app-notification.model';
import { AppVersionInfo } from '../../domain/app-version-info.model';
import { AppTimezoneInfo } from '../../domain/app-timezone-info.model';
import { isEmpty } from '../../../modules/utils/string-utils';

export interface AppState {
  viewOpened: View;
  version: AppVersionInfo;
  timezone: AppTimezoneInfo;
  notifications: AppNotification[];
  tenants: Tenant[];
  roles: Role[];
  languages: Language[];
  countries: Country[];
  states: State[];
  backendErrorText: string[];
  backendSuccessText: string[];
  genericSearchItems: PaginatedResponse;
  isResponsePending: boolean;
  comtrasInstances: ComtrasInstance[];
  tenantByPrefix: Tenant;
}

export const initialState: AppState = {
  viewOpened: null,
  version: null,
  timezone: null,
  notifications: [],
  tenants: null,
  roles: null,
  languages: [],
  countries: null,
  states: null,
  backendErrorText: null,
  backendSuccessText: null,
  genericSearchItems: null,
  isResponsePending: false,
  comtrasInstances: [],
  tenantByPrefix: null,
};

function addNotification(state: AppState, action: any): AppNotification[] {
  const injector = Injector.create({
    providers: [{ provide: MessageService, deps: [] }],
  });
  const messageService: MessageService = injector.get(MessageService);
  if (action.type == AppActionTypes.ADD_NOTIFICATION) {
    const notificationsStored = state.notifications;
    const notificationAdd = action.notification;
    const notifications = [].concat(notificationAdd, nullsafe(notificationsStored));
    if (action.triggerGrowl && notifications?.length) {
      messageService.add({
        severity: notificationLevelToGrowlMessageSeverity(notificationAdd),
        summary: '',
        detail: buildMessage(notificationAdd),
      });
    }
    return notifications;
  }
  return state.notifications;
}

function notificationLevelToGrowlMessageSeverity(notification: AppNotification): string {
  switch (notification?.level) {
    case 'SUCCESS':
      return 'success';
    case 'INFO':
      return 'info';
    case 'WARN':
      return 'warn';
    case 'ERROR':
      return 'error';
    default:
      return 'info';
  }
}

function buildMessage(n: AppNotification): string {
  return `notification${n?.message}`;
}

export const appStateReducer = createReducer(
  initialState,
  on(
    AppActions.setAllLanguages,
    (state: AppState, { languages }): AppState => ({
      ...state,
      languages: languages,
    }),
  ),
  on(
    AppActions.setTimezone,
    (state: AppState, { timezone }): AppState => ({
      ...state,
      timezone: timezone,
    }),
  ),
  on(
    AppActions.setAppVersion,
    (state: AppState, { appVersion }): AppState => ({
      ...state,
      version: appVersion,
    }),
  ),
  on(AppActions.setBreadcrumbNavigation, (state: AppState, action): AppState => {
    let newView: View = state.viewOpened;
    if (!state.viewOpened) {
      // no view was yet opened, or the view history should be ignored
      newView = action.view;
    }
    // there is already a view open.
    // same view opened again, nothing to be done (can this ever happen?)
    else if (!isEmpty(newView?.previousView?.uri) && isEqual(newView?.previousView.uri, action.view.uri)) {
      // backward navigation, previous view was opened
      newView = newView.previousView;
    } else if (!isEmpty(action.basePath) && newView?.uri.startsWith(action.basePath)) {
      // 'stay' - replace it last one
      newView = assign(cloneDeep(action.view), { previousView: newView.previousView });
    } else {
      // forward navigation, push current view on stack
      newView = assign(cloneDeep(action.view), { previousView: newView });
    }

    return {
      ...state,
      viewOpened: newView,
    };
  }),
  on(
    AppActions.setAppNotifications,
    (state: AppState, { notifications }): AppState => ({
      ...state,
      notifications: notifications,
    }),
  ),
  on(AppActions.addAppNotification, (state: AppState, action): AppState => {
    const notificationsStored = addNotification(state, action);
    return {
      ...state,
      notifications: notificationsStored,
    };
  }),
  on(
    AppActions.setSearchGenericItems,
    (state: AppState, { paginationResponse }): AppState => ({
      ...state,
      genericSearchItems: paginationResponse,
      isResponsePending: false,
    }),
  ),
  on(
    AppActions.fetchSearchGenericItems,
    (state: AppState): AppState => ({
      ...state,
      genericSearchItems: null,
      isResponsePending: true,
    }),
  ),
  on(
    AppActions.setAppMessages,
    (state: AppState, { messages }): AppState => ({
      ...state,
      backendSuccessText: messages,
    }),
  ),
  on(AppActions.setAppErrorMessages, (state: AppState, { errorMessages }) => ({
    ...state,
    backendErrorMessages: errorMessages,
  })),
  on(AppActions.setAllCountries, (state: AppState, { countries }) => ({
    ...state,
    countries: countries,
  })),
  on(
    AppActions.setAllStates,
    (state: AppState, { states }): AppState => ({
      ...state,
      states: states,
    }),
  ),
  on(
    AppActions.setAllTenants,
    (state: AppState, { tenants }): AppState => ({
      ...state,
      tenants: tenants,
    }),
  ),
  on(
    AppActions.setResponsePending,
    (state: AppState, { isResponsePending }): AppState => ({
      ...state,
      isResponsePending: isResponsePending,
    }),
  ),
  on(
    AppActions.setTenantByCOMTRASPrefix,
    (state: AppState, { tenant }): AppState => ({
      ...state,
      tenantByPrefix: tenant,
    }),
  ),
  on(
    AppActions.setCOMTRASInstance,
    (state: AppState, { comtrasInstances }): AppState => ({
      ...state,
      comtrasInstances: comtrasInstances,
    }),
  ),
);

export const getCurrentViewNavigation = (state: AppState): View => state.viewOpened;
export const getTimezone = (state: AppState): AppTimezoneInfo => state.timezone;
export const getErrorText = (state: AppState): string[] => state.backendErrorText;
export const getSuccessText = (state: AppState): string[] => state.backendSuccessText;
export const getGenericSearchItems = (state: AppState): PaginatedResponse => state.genericSearchItems;
export const getLanguages = (state: AppState): Language[] => state.languages;
export const getCountries = (state: AppState): Country[] => state.countries;
export const getStates = (state: AppState): State[] => state.states;
export const getTenants = (state: AppState): Tenant[] => state.tenants;
export const getNotifications = (state: AppState): AppNotification[] => state.notifications;
export const getIsResponsePending = (state: AppState): boolean => state.isResponsePending;
export const getCOMTRASInstances = (state: AppState): ComtrasInstance[] => state.comtrasInstances;
export const getTenantByCOMTRASPrefix = (state: AppState): Tenant => state.tenantByPrefix;
