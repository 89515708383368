import { Entity, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { Country } from './country.model';

@Entity('administration', 'Language', 'auth')
export class Language extends AbstractVersionedEntity {
  @Serialized isoCode: string;
  @Serialized language: string;
  @Serialized country: Country;
}
