import { Entity, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';

import { CupIllustrationImage } from './cup-illustration-image.model';

@Entity('cupping', 'CupIllustration', 'qc')
export class CupIllustration extends AbstractEntity {
  @Serialized cupItem: number;
  @Serialized cupTenants: string;
  @Serialized usedByAll: boolean;
  @Serialized commentary: string;
  @Serialized({
    elementType: CupIllustrationImage,
    composite: true,
  })
  cupIllustrationImages: CupIllustrationImage[] = [];
}
