import { createReducer, on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as MasterDataActions from '../actions/master.action';
import { onGetGridFilters } from '../../../shared/grid/filter/master-grid-utils';
import { isNullOrUndefined, nullsafe } from '../../../modules/utils/object-utils';
import { CustomGridView } from '../../../shared/grid/models/enums';
import { GridView } from '../../domain/grid-view.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { PaginationMetadata } from '../../domain/pagination-metadata.model';
import { PaginatedResponse } from '../../domain/paginated-response.model';

export interface MasterState {
  data: PaginatedResponse;
  translations: any[];
  currentItemTranslated: any;
  paginationRequestParams: PaginationRequestParams;
  selectedItem: any;
  masterNameReference: string;
  isShowRetiredMastersChecked: boolean;
  isEditMasterModeActive: boolean;
  isNewMasterModeActive: boolean;
  isModifyMasterModeActive: boolean;
  isBackendError: boolean;
  isBackendSuccess: boolean;
  errorResponse: HttpErrorResponse;
  alertSent: boolean;
  isSave: boolean;
  isDiscard: boolean;
  currentGridView: CustomGridView;
  gridViewReference: string;
  gridViews: GridView[];
  paginationResponse: PaginationMetadata;
}

export const initialMasterState: MasterState = {
  data: null,
  translations: null,
  currentItemTranslated: null,
  paginationRequestParams: null,
  selectedItem: null,
  masterNameReference: '',
  isShowRetiredMastersChecked: false,
  isEditMasterModeActive: false,
  isNewMasterModeActive: false,
  isModifyMasterModeActive: false,
  isBackendError: false,
  isBackendSuccess: false,
  errorResponse: null,
  alertSent: false,
  isSave: false,
  isDiscard: false,
  currentGridView: null,
  gridViewReference: '',
  gridViews: [],
  paginationResponse: null,
};

export const masterDataReducer = createReducer(
  initialMasterState,
  on(
    MasterDataActions.fetchDataMaster,
    (state: MasterState): MasterState => ({
      ...state,
      // paginationRequestParams: paginationRequestParams,
      isBackendSuccess: false,
      isBackendError: false,
    }),
  ),
  on(
    MasterDataActions.fetchDataMasterSuccess,
    (state: MasterState, { paginationResponse }): MasterState => ({
      ...state,
      data: paginationResponse,
      paginationResponse: paginationResponse?.__pagination,
      isBackendSuccess: true,
      isBackendError: false,
    }),
  ),
  on(
    MasterDataActions.saveMasterDataSuccess,
    (state: MasterState, { data }): MasterState => ({
      ...state,
      selectedItem: data || state.selectedItem, // avoid undefined values when occur versioning error
      isBackendSuccess: true,
      isBackendError: false,
    }),
  ),
  on(
    MasterDataActions.fetchTranslations,
    (state: MasterState): MasterState => ({
      ...state,
      isBackendSuccess: false,
      isBackendError: false,
    }),
  ),
  on(
    MasterDataActions.fetchTranslationsSuccess,
    (state: MasterState, { translations }): MasterState => ({
      ...state,
      translations: translations,
      isBackendSuccess: true,
      isBackendError: false,
    }),
  ),
  on(
    MasterDataActions.saveMasterDataTranslationSuccess,
    (state: MasterState, { data }): MasterState => ({
      ...state,
      currentItemTranslated: data,
      isBackendSuccess: true,
      isBackendError: false,
    }),
  ),
  on(
    MasterDataActions.errorResponse,
    (state: MasterState, { _httpErrorResponse }): MasterState => ({
      ...state,
      errorResponse: _httpErrorResponse,
      isBackendError: true,
      isBackendSuccess: false,
    }),
  ),
  on(
    MasterDataActions.setMasterDataSelectedItem,
    (state: MasterState, { selectedItem }): MasterState => ({
      ...state,
      translations: null,
      currentItemTranslated: null,
      selectedItem: selectedItem,
    }),
  ),
  on(
    MasterDataActions.setMasterDataPagination,
    (state: MasterState, { paginationRequestParams }): MasterState => ({
      ...state,
      paginationRequestParams: paginationRequestParams,
    }),
  ),
  on(
    MasterDataActions.setMasterDataReference,
    (state: MasterState, { masterNameReference }): MasterState => ({
      ...state,
      selectedItem: state.masterNameReference !== masterNameReference ? null : state.selectedItem,
      masterNameReference: masterNameReference,
    }),
  ),
  on(
    MasterDataActions.setMasterDataRetiredMode,
    (state: MasterState, { showRetiredItems }): MasterState => ({
      ...state,
      isShowRetiredMastersChecked: showRetiredItems,
    }),
  ),
  on(
    MasterDataActions.setMasterDataNewMode,
    (state: MasterState, { isNewMode }): MasterState => ({
      ...state,
      isNewMasterModeActive: isNewMode,
    }),
  ),
  on(
    MasterDataActions.setMasterDataSaveMode,
    (state: MasterState, { isSaved }): MasterState => ({
      ...state,
      isSave: isSaved,
      isDiscard: false,
    }),
  ),
  on(
    MasterDataActions.setMasterDataDiscardMode,
    (state: MasterState, { isDiscard }): MasterState => ({
      ...state,
      isDiscard: isDiscard,
      isSave: false,
    }),
  ),
  on(
    MasterDataActions.setMasterEditMode,
    (state: MasterState, { isEditMode }): MasterState => ({
      ...state,
      isEditMasterModeActive: isEditMode,
      isDiscard: false,
      isSave: false,
    }),
  ),
  on(
    MasterDataActions.setMasterModifyMode,
    (state: MasterState, { isModifyMode }): MasterState => ({
      ...state,
      isModifyMasterModeActive: isModifyMode,
    }),
  ),
  on(MasterDataActions.resetMasterData, () => ({
    ...initialMasterState,
  })),
  on(
    MasterDataActions.emailAlertSuccess,
    (state: MasterState, { data }): MasterState => ({
      ...state,
      alertSent: data.successful,
    }),
  ),
  on(MasterDataActions.setGridView, (state: MasterState, action): MasterState => {
    const { paginationRequestParams } = state;
    const { gridView, yesTranslated } = action;
    let newPagination: PaginationRequestParams;
    // Assume that the sort and filters properties of the first pagination are the same.
    // And do not change the current paginationRequest.
    if (!isNullOrUndefined(gridView) && !isNullOrUndefined(paginationRequestParams)) {
      newPagination = { ...paginationRequestParams };
      const { filter: filterModel, sorting: sortingModel } = gridView;
      const sort = !isNullOrUndefined(nullsafe(sortingModel)[0])
        ? `${sortingModel[0].colId},${sortingModel[0].sort}`
        : paginationRequestParams.sort;
      newPagination._s = onGetGridFilters(filterModel, yesTranslated).slice(0, -1);
      newPagination.sort = sort;
    }

    if (!isNullOrUndefined(action.id)) gridView.id = action?.id;
    return {
      ...state,
      paginationRequestParams: newPagination,
      currentGridView: gridView,
    };
  }),
  on(MasterDataActions.setGridViewSuccess, (state: MasterState, { gridView }): MasterState => {
    const gridReference = isNullOrUndefined(gridView) ? '' : state.gridViewReference;
    const gridViews = isNullOrUndefined(gridView) ? null : state.gridViews;
    const currentGridView = isNullOrUndefined(gridView) ? null : { ...gridView };
    return {
      ...state,
      gridViewReference: gridReference,
      currentGridView: currentGridView,
      gridViews: gridViews,
    };
  }),
  on(
    MasterDataActions.fetchGridViewsSuccess,
    (state: MasterState, { gridViews }): MasterState => ({
      ...state,
      gridViews: gridViews,
    }),
  ),
  on(
    MasterDataActions.fetchGridViews,
    (state, { gridViewReference }): MasterState => ({
      ...state,
      gridViewReference: gridViewReference,
    }),
  ),
);

export const getMasterPaginationRequest = (state: MasterState): PaginationRequestParams =>
  state.paginationRequestParams;
export const getMasterDataItems = (state: MasterState): PaginatedResponse => state.data;
export const getMasterTranslations = (state: MasterState): any[] => state.translations;
export const getMasterSelectedItem = (state: MasterState): any => state.selectedItem;
export const getCurrentItemTranslated = (state: MasterState): any => state.currentItemTranslated;
export const getEditMasterModeActive = (state: MasterState): boolean => state.isEditMasterModeActive;
export const getIsDiscard = (state: MasterState): boolean => state.isDiscard;
export const getModifyMasterModeActive = (state: MasterState): boolean => state.isModifyMasterModeActive;
export const getMasterNameReference = (state: MasterState): string => state.masterNameReference;
export const getCurrentGridView = (state: MasterState): CustomGridView => state.currentGridView;
export const getAllGridViews = (state: MasterState): GridView[] => state.gridViews;
export const getGridViewReference = (state: MasterState): string => state.gridViewReference;
